import React, { useEffect, useState } from "react";
import {
  useOutletContext,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { ICONS } from "../../assets/svgs/ICONS";
import TextInput from "../../components/inputs/TextInput";
import FormButton from "../../components/buttons/FormButton";
import SelectInput from "../../components/inputs/SelectInput";
import { bookFlight } from "../../services/requests/travel/Travel";
import toast from "react-hot-toast";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { styled } from "@mui/material/styles";

const BookFlight = () => {
  const [setPageName] = useOutletContext<any>();
  const [loading, setLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [newPassengerType, setNewPassengerType] = useState<any>([]);
  const [newPassenger, setNewPassenger] = useState<any>([]);
  const [airport, setAirport] = useState<any>({});

  const AntTabs = styled(TabList)({
    color: "#D6AA1B",
    "& .MuiTabs-indicator": {
      backgroundColor: "#D6AA1B",
    },
  });

  const AntTab = styled(Tab)({
    color: "#7D8FB2",
    textTransform: "none",
    "&.Mui-selected": {
      color: "#D6AA1B",
    },
  });

  useEffect(() => {
    if (location.state) {
      const { adults, children, infants, portName } = location.state;
      setAirport(portName);

      const updatedPassengerTypes = [
        {
          type: "adult",
          number: adults,
        },
        {
          type: "child",
          number: children,
        },
        {
          type: "infant",
          number: infants,
        },
      ];

      const repeatedPassengerTypes = updatedPassengerTypes.flatMap(
        ({ type, number }) => Array.from({ length: number }, () => ({ type }))
      );

      setNewPassengerType(repeatedPassengerTypes);

      setNewPassenger(
        repeatedPassengerTypes.map((passenger) => ({
          passenger_type: passenger.type,
          first_name: "",
          last_name: "",
          dob: "",
          gender: "",
          email: "",
          phone_number: "",
          title: "",
          documents: {
            number: "",
            issuing_date: "",
            expiry_date: "",
            nationality_country: "",
            document_type: "",
            issuing_country: "NG",
            holder: true,
          },
        }))
      );
    }
  }, [location]);

  const [value, setValue] = React.useState("adult");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const options = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];

  const docOpt = [
    { value: "passport", label: "Passport" },
    // { value: "national_id", label: "National ID" },
    // { value: "drivers_license", label: "Drivers License" },
  ];

  React.useEffect(() => {
    setPageName("Passanger Information");
  }, [setPageName]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);
    try {
      bookFlight(id, { passengers: newPassenger }).then((res) => {
        setLoading(false);
        if (res.status === "success") {
          navigate(`/dashboard/flight-summary/${id}`, {
            state: { data: res.data, portName: airport },
          });
          toast.success(res?.message || "Flight booked successfully");
        } else {
          setLoading(false);
          toast.error(res.response.data.message);
          console.log(res);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (index: number, e: any) => {
    const { name, value } = e.target;
    setNewPassenger((prevPassengers: any) => {
      return prevPassengers.map((passenger: any, i: any) => {
        if (index === i) {
          if (name in passenger.documents) {
            return {
              ...passenger,
              documents: {
                ...passenger.documents,
                [name]: value,
              },
            };
          } else {
            return {
              ...passenger,
              [name]: value,
            };
          }
        } else {
          return passenger;
        }
      });
    });
  };

  return (
    <>
      <div
        onClick={() => navigate(-1)}
        className="w-[70px] flex items-center mb-6 cursor-pointer">
        <ICONS.CaretArrowLeftIcon />
        <p>Back</p>
      </div>

      <Box sx={{ width: "100%" }}>
        <TabContext value={value}>
          <AntTabs onChange={handleTabChange}>
            <AntTab label="Adult" value="adult" />
            <AntTab label="Child" value="child" />
            <AntTab label="Infant" value="infant" />
          </AntTabs>
          <hr />

          <form action="post" onSubmit={handleSubmit}>
            {newPassengerType.map((passenger: any, index: number) => (
              <TabPanel key={index} value={passenger.type}>
                <p className="text-primary text-sm font-medium">
                  Passenger {index + 1}
                </p>
                <div className="flex items-center justify-between gap-8">
                  <TextInput
                    placeHolder="Enter first name"
                    label="First Name"
                    value={newPassenger[index]?.first_name}
                    name="first_name"
                    onChange={(e) => handleChange(index, e)}
                  />
                  <TextInput
                    placeHolder="Enter your name"
                    label="Last Name"
                    value={newPassenger[index]?.last_name}
                    name="last_name"
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
                <div className="flex items-center justify-between gap-8">
                  <SelectInput
                    label="Gender"
                    value={newPassenger[index]?.gender}
                    name="gender"
                    options={options}
                    onChange={(e) => handleChange(index, e)}
                  />

                  <SelectInput
                    label="Title"
                    value={newPassenger[index]?.title}
                    name="title"
                    options={[
                      { value: "mr", label: "Mr" },
                      { value: "mrs", label: "Mrs" },
                      { value: "miss", label: "Miss" },
                    ]}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
                <div className="flex items-center justify-between gap-8">
                  <div className="my-4 w-[100%]">
                    <label className="font-semibold text-sm py-2" htmlFor="DOB">
                      DOB
                    </label>

                    <input
                      type="date"
                      value={newPassenger[index]?.dob}
                      name="dob"
                      onChange={(e) => handleChange(index, e)}
                      placeholder="Enter DOB"
                      className="h-[64px] w-full input-radius pl-8 outline-none border border-[#D9D9D9] invalid:border-[#D9D9D9] valid:border-primary focus:border-primary bg-transparent focus:bg-[#FAFAFA] disabled:bg-[#FAFAFA] duration-300"
                    />
                  </div>

                  <TextInput
                    placeHolder="Nationality"
                    label="Nationality"
                    value={newPassenger[index]?.documents.nationality_country}
                    name="nationality_country"
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
                <div className="flex items-center justify-between gap-8">
                  <TextInput
                    placeHolder="Enter email"
                    label="Email"
                    value={newPassenger[index]?.email}
                    name="email"
                    onChange={(e) => handleChange(index, e)}
                  />

                  <TextInput
                    placeHolder="Enter your number"
                    label="Phone number"
                    value={newPassenger[index]?.phone_number}
                    name="phone_number"
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
                <div className="flex items-center justify-between gap-8">
                  <TextInput
                    placeHolder="Passport number"
                    label="Passport number"
                    value={newPassenger[index]?.documents.number}
                    name="number"
                    onChange={(e) => handleChange(index, e)}
                  />

                  <SelectInput
                    label="Document type"
                    options={docOpt}
                    name="document_type"
                    value={newPassenger[index]?.documents.document_type}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
                <div className="flex items-center justify-between gap-8">
                  <div className="my-4 w-[100%]">
                    <label
                      className="font-semibold text-sm py-2"
                      htmlFor="issuing_date">
                      Issuing Date
                    </label>

                    <input
                      type="date"
                      value={newPassenger[index]?.documents.issuing_date}
                      name="issuing_date"
                      onChange={(e) => handleChange(index, e)}
                      placeholder="Issuing Date"
                      className="h-[64px] w-full input-radius pl-8 outline-none border border-[#D9D9D9] invalid:border-[#D9D9D9] valid:border-primary focus:border-primary bg-transparent focus:bg-[#FAFAFA] disabled:bg-[#FAFAFA] duration-300"
                    />
                  </div>
                  <div className="my-4 w-[100%]">
                    <label
                      className="font-semibold text-sm py-2"
                      htmlFor="expiry_date">
                      Expiry Date
                    </label>

                    <input
                      type="date"
                      value={newPassenger[index]?.documents.expiry_date}
                      name="expiry_date"
                      onChange={(e) => handleChange(index, e)}
                      placeholder="Enter DOB"
                      className="h-[64px] w-full input-radius pl-8 outline-none border border-[#D9D9D9] invalid:border-[#D9D9D9] valid:border-primary focus:border-primary bg-transparent focus:bg-[#FAFAFA] disabled:bg-[#FAFAFA] duration-300"
                    />
                  </div>
                </div>
              </TabPanel>
            ))}

            <FormButton text="Book Flight" isLoading={loading} />
          </form>
        </TabContext>
      </Box>
    </>
  );
};

export default BookFlight;
