import { DeleteHttp } from "../../https/DeleteHttp";
import { GetHttp } from "../../https/GetHttp";
import { PostHttp } from "../../https/PostHttp";
import { PatchHttp } from "../../https/PatchHttp";

type PayloadData = {
  [key: string]: any;
};

export const getPayroll = async () => {
  try {
    const res = await GetHttp({
      pathname: `/payroll/get-payroll`,
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const createPayroll = async (payload: PayloadData) => {
  try {
    const res = await PostHttp({
      pathname: `/payroll/create?bankNipCode=${payload.bankNipCode}&bankId=${payload.bankId}`,
      payload: {
        salary: payload.salary,
        accountName: payload.accountName,
        accountNumber: payload.accountNumber,
        bankName: payload.bankName,
        nickName: payload.nickName,
        title: payload.title,
      },
      formType: "json",
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getTransactionHistory = async () => {
  try {
    const res = await GetHttp({
      pathname: `/payroll/get-payroll-payments`,
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const payrollPayment = async (payload: PayloadData, accId: string) => {
  try {
    const res = await PostHttp({
      pathname: `/payroll/payment?virtualAccountId=${accId}
`,
      payload: payload,
      formType: "json",
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const deletePayrollBeneficiary = async (id: string) => {
  try {
    const res = await DeleteHttp({
      pathname: `/payroll/delete-payroll/${id}`,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const editPayroll = async (payload: PayloadData, id: string) => {
  delete payload.bankId;
  delete payload.bankNipCode;

  console.log(payload);

  try {
    const res = await PatchHttp({
      pathname: `/payroll/update-payroll/${id}`,
      payload: payload,
      formType: "json",
    });

    return res;
  } catch (error) {
    console.error(error);
  }
};
