import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ICONS } from "../../assets/svgs/ICONS";
import TextInput from "../../components/inputs/TextInput";
import Spinner from "../../components/spinner/Spinner";
import { verifyBVN } from "../../services/requests/createAccount/BusinessVerificationRequest";

const AddBVNDetails = () => {
  const [value, setValue] = useState({
    bvn: "",
    phone: "",
    dob: "",
  });
  const [businessId, setBusinessId] = useState<string>("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userData = JSON.parse(window.sessionStorage.getItem("data")!);

    if (userData) {
      setValue((prevState) => ({
        ...prevState,
        phone: userData.phone_number,
        dob: userData.email,
      }));

      setBusinessId(userData.business_id);
    }
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setValue((prevState) => ({
      ...prevState,
      [name]: value.trim(),
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);
    await verifyBVN(value, businessId);
    setLoading(false);
  };
  return (
    <div className="w-[703px] px-20 py-12 _h-[812px] login-box-radius bg-white rounded-2.5xl rounded-tl-none flex-center flex-col">
      <Link to="/" className="top-section flex-center">
        <ICONS.BrandLogo />
      </Link>

      <div className="title mt-6 flex-center flex-col">
        <h2 className="text-secondary font-bold text-xl">Add BVN Details</h2>
        <p className="text-secondary2 font-thin">
          Enter your information to get started
        </p>
      </div>

      <form action="" onSubmit={handleSubmit} className="w-full">
        <TextInput
          label="BVN"
          name="bvn"
          placeHolder="BVN"
          value={value.bvn}
          onChange={handleInputChange}
        />
        {/* <TextInput
          label="Phone Number"
          name="phone"
          placeHolder="Phone Number"
          value={value.phone}
          onChange={handleInputChange}
        />
        <TextInput
          label="Email"
          name="dob"
          placeHolder="Email"
          value={value.dob}
          onChange={handleInputChange}
        /> */}

        <div className="button-wrapper mt-8">
          <button
            disabled={loading === true}
            className="button-radius w-full h-16 bg-primary text-white font-me dium text-md flex-center gap-x-2 disabled:opacity-50">
            {loading && <Spinner color="#fff" />}
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddBVNDetails;
