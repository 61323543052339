import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import DashboardOutlet from "./layout/outlet/DashboardOutlet";
import BudgetPage from "./pages/budget/BudgetPage";
import ExpensesPage from "./pages/expenses/ExpensesPage";
import AccountPage from "./pages/account/AccountPage";
import LoginPage from "./pages/login/LoginPage";
import ForgetPwdPage from "./pages/forget-password/ForgetPwdPage";
import MemberLoginPage from "./pages/login/MemberLoginPage";
import MemberForgetPwdPage from "./pages/forget-password/MemberForgetPwdPage";
import CardPage from "./pages/cards/CardPage";
import CardDetailsPage from "./pages/cards/CardDetailsPage";
import ChooseAccount from "./pages/authentication/chooseAccount/ChooseAccount";
import BusinessAccount from "./pages/CreateAccount/BusinessAccount/BusinessAccount";
import BusinessDetails from "./pages/CreateAccount/BusinessAccount/BusinessDetails";
import BusinessRegistration from "./pages/CreateAccount/BusinessAccount/BusinessRegistration";
import BudgetDetails from "./pages/budget/BudgetDetails";
import VerifyNumber from "./pages/authentication/VerifyNumber/VerifyNumber";
import OtpVerification from "./pages/authentication/otpVerification/OtpVerification";
import BusinessVerification from "./pages/BusinessVerification/BusinessVerification";
import BusinessVerificationOutlet from "./layout/outlet/BusinessVerificationOutlet";
import EmailVeriification from "./pages/BusinessVerification/EmailVeriification";
import AddBizVerification from "./pages/BusinessVerification/AddBizVerification";
import BVNVerification from "./pages/BusinessVerification/BVNVerification";
import AddBizVerificationDetails from "./pages/BusinessVerification/AddBizVerificationDetails";
import AddBVNDetails from "./pages/BusinessVerification/AddBVNDetails";
import ManageSystem from "./pages/ManageSystem/ManageSystem";
import SendEmailVerification from "./pages/BusinessVerification/SendEmailVerification";
import Completed from "./pages/BusinessVerification/Completed";
import Payroll from "./pages/payroll/Payroll";
import CreatePayroll from "./pages/payroll/CreatePayroll";
import LoginVerification from "./pages/login/LoginVerification";
import TransactionHistory from "./pages/payroll/TransactionHistory";
import PayrollPayment from "./pages/payroll/PayrollPayment";
import Success from "./pages/payroll/Success";
import EditPayroll from "./pages/payroll/EditPayroll";
import Travels from "./pages/travels/Travels";
import AvailableFlight from "./pages/travels/AvailableFlight";
import FlightDetailPage from "./pages/travels/FlightDetailPage";
import FlightSummary from "./pages/travels/FlightSummary";
import BookedFlightSuccess from "./pages/travels/BookedFlightSuccess";
import BookFlight from "./pages/travels/BookFlight";
// import HomePage from "./pages/home/HomePage";

function App() {
  const [isAuthorized, setIsAuthorized] = React.useState<boolean | string>("");
  const [PRIVATE_ROUTES] = React.useState([
    { path: "", element: <AccountPage /> },
    { path: "budget", element: <BudgetPage /> },
    { path: "cards", element: <CardPage /> },
    { path: "card/:id", element: <CardDetailsPage /> },
    { path: "budget/:id", element: <BudgetDetails /> },
    { path: "expenses", element: <ExpensesPage /> },
    { path: "account", element: <AccountPage /> },
    { path: "payroll", element: <Payroll /> },
    { path: "create-payroll", element: <CreatePayroll /> },
    { path: "payroll-history", element: <TransactionHistory /> },
    { path: "payroll-payment", element: <PayrollPayment /> },
    { path: "success", element: <Success /> },
    { path: "edit-payroll/:id", element: <EditPayroll /> },
    { path: "travel", element: <Travels /> },
    { path: "available-flight", element: <AvailableFlight /> },
    { path: "flight-detail/:id", element: <FlightDetailPage /> },
    { path: "flight-summary/:id", element: <FlightSummary /> },
    { path: "booking-success", element: <BookedFlightSuccess /> },
    { path: "book-flight/:id", element: <BookFlight /> },
  ]);

  const [BUSINESS_VERIFICATION_ROUTES] = React.useState([
    { path: "business-verification", element: <BusinessVerification /> },
    { path: "email-verification", element: <EmailVeriification /> },
    { path: "add-biz-verification", element: <AddBizVerification /> },
    { path: "bvn-verification", element: <BVNVerification /> },
    {
      path: "add-biz-verification-details",
      element: <AddBizVerificationDetails />,
    },
    { path: "add-bvn-details", element: <AddBVNDetails /> },
    { path: "send-email-verification", element: <SendEmailVerification /> },
  ]);

  const location = useLocation();
  React.useEffect(() => {
    const token = window.sessionStorage.getItem("token");
    if (token) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
      const currentRoute = location.pathname;
      const isRoutePrivate = PRIVATE_ROUTES.find((route: { path: string }) =>
        currentRoute.includes(`/dashboard/${route.path}`)
      );
      if (isRoutePrivate) {
        toast.error("User not authorized");
        window.location.href = "/";
      }
    }
  }, [location.pathname, PRIVATE_ROUTES]);

  useEffect(() => {
    let activityTimeout: any;

    function resetTimeout() {
      clearTimeout(activityTimeout);
      activityTimeout = setTimeout(handleLogOut, 600000);
    }

    if (isAuthorized) {
      activityTimeout = setTimeout(handleLogOut, 1800000);
      document.addEventListener("mousemove", resetTimeout);
      document.addEventListener("keypress", resetTimeout);
      return () => {
        clearTimeout(activityTimeout);
        document.removeEventListener("mousemove", resetTimeout);
        document.removeEventListener("keypress", resetTimeout);
      };
    }

    async function handleLogOut() {
      window.sessionStorage.clear();
      window.location.href = "/";
    }
  }, [isAuthorized]);

  return (
    <React.Fragment>
      <Toaster />
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login-verification" element={<LoginVerification />} />
        <Route path="/forget-password" element={<ForgetPwdPage />} />
        <Route path="/choose-account" element={<ChooseAccount />} />
        <Route path="/business-account" element={<BusinessAccount />} />
        <Route path="/add-details" element={<BusinessDetails />} />
        <Route path="/manage-system" element={<ManageSystem />} />
        <Route path="/verify-number" element={<VerifyNumber />} />
        <Route path="/completed" element={<Completed />} />
        <Route path="/success" element={<Success />} />
        <Route
          path="/business-details-registration"
          element={<BusinessRegistration />}
        />
        <Route
          path="/member-forget-password"
          element={<MemberForgetPwdPage />}
        />
        <Route path="/member-login" element={<MemberLoginPage />} />
        <Route path="/verify-otp" element={<OtpVerification />} />
        {/* Business verification route  */}
        <Route path="/business/*" element={<BusinessVerificationOutlet />}>
          {BUSINESS_VERIFICATION_ROUTES.map(
            (route: { path: string; element: any }, id) => (
              <Route key={id} path={route.path} element={route.element} />
            )
          )}
        </Route>
        {/* Private Routes */}
        <Route
          path="/dashboard/*"
          element={<DashboardOutlet isAuthorized={isAuthorized} />}>
          {PRIVATE_ROUTES.map((route: { path: string; element: any }, id) => (
            <Route key={id} path={route.path} element={route.element} />
          ))}
        </Route>
        {/* Fallback page */}
        <Route path="*" element={<LoginPage />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
