export function formatDate(inputDate: string) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();

  const formattedDate = `${month} ${day}, ${year}`;
  return formattedDate;
}

export function convertToInputDateFormat(dateTimeString: string) {
  const date = new Date(dateTimeString);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(date.getUTCDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
