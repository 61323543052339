import React from "react";
import { ICONS } from "../../assets/svgs/ICONS";
import { Link } from "react-router-dom";

const Completed = () => {
  return (
    <div className="w-full h-screen bg-gray-100 p-5">
      <Link to="/" className="flex-left">
        <ICONS.BrandLogo />
      </Link>

      <div className="w-[100%] h-[100%] flex items-center justify-center">
        <div className="bg-secondary w-[600px] px-20 py-12 _h-[812px] rounded-2xl flex-center flex-col">
          <div className="bg-primary rounded-tl-none rounded-[50px] w-[100px] h-[100px] flex items-center justify-center m-auto">
            <ICONS.CheckIcon />
          </div>
          <div className="mt-[2rem] text-center">
            <h1 className="text-white font-bold text-3xl">Completed</h1>
            <p className="text-white">
              Your business account has been verified successfully
            </p>
          </div>

          <Link className="block mt-9 text-primary" to='/dashboard'>Dashboard</Link>
        </div>
      </div>
    </div>
  );
};

export default Completed;
