import toast from "react-hot-toast";
import { PatchHttp } from "../../https/PatchHttp";
import { PostHttp } from "../../https/PostHttp";

export const resetMemberPassword = async (payload: any) => {
  const response = await PatchHttp({
    pathname: `/teams/reset-password`,
    payload: payload,
    formType: "json",
  });

  if (response?.response?.status < 200 || response?.response?.status > 204) {
    toast.error(response?.response?.data?.message);
  }
  return response;
};

export const sendResetCode = async (payload: any) => {
  const response = await PostHttp({
    pathname: `/reset/vemail`,
    payload: payload,
    formType: "json",
  });

  if (response?.response?.status < 200 || response?.response?.status > 204) {
    toast.error(response?.response?.data?.message);
  }
  return response;
};

export const resetPassword = async (payload: any) => {
  const response = await PostHttp({
    pathname: `/reset/vcode`,
    payload: payload,
  });

  if (response?.response?.status < 200 || response?.response?.status > 204) {
    toast.error(response?.response?.data?.message);
  }
  return response;
};
