import ClipLoader from "react-spinners/ClipLoader";
import Modal from "@mui/material/Modal";

const Loader = () => {
  return (
    <Modal open={true}>
      <div className="flex items-center justify-center h-[100vh] ">
        <ClipLoader color={"#D6AA1B"} loading={true} size={150} />
      </div>
    </Modal>
  );
};

export default Loader;
