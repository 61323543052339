import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import VirtualCard, { IVirtualCard } from "./VirtualCard";
import PhysicalCard, { IPhysical } from "./PhysicalCard";
import {
  cardHolderEligibility,
  cardResponse,
  getCards,
} from "../../services/requests/card/CardRequest";
import Spinner from "../../components/spinner/Spinner";
import CreateCard from "./CardContent/CreateCard";
import BasicModal from "../../components/modal/BasicModal";
import CardHolder from "./CardContent/CardHolder";
import Loader from "../../components/Loader/Loader";

function CardPage() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [setPageName] = useOutletContext<any>();
  const [cards, setCards] = React.useState<IVirtualCard[]>([]);
  const [physicalCards, setPhysicalCards] = React.useState<IPhysical[]>([]);
  const [open, setOpen] = useState(false);
  const [createCardHolder, setCreateCardHolder] = useState(false);
  const [elLoading, setElLoading] = useState(false);

  React.useEffect(() => {
    const fetchAllCards = async () => {
      setIsLoading(true);
      const response = await getCards();
      const responseVirutal: IVirtualCard[] = response?.cards?.filter(
        ({ card_type }: { card_type: string }) => card_type === "virtual"
      );
      const responsePhysical: IPhysical[] = response?.cards?.filter(
        ({ card_type }: { card_type: string }) => card_type !== "virtual"
      );
      setCards(responseVirutal);
      setPhysicalCards(responsePhysical);
      setIsLoading(false);
    };
    fetchAllCards();
  }, []);

  React.useEffect(() => {
    setPageName("Cards");
  }, [setPageName]);

  const handleCreateCard = async () => {
    setElLoading(true);
    await cardHolderEligibility();
    setElLoading(false);

    if (cardResponse === "success") {
      setOpen(true);
    } else if (cardResponse === "fail") {
      setCreateCardHolder(true);
    }
  };

  return (
    <div>
      {elLoading && <Loader />}
      {createCardHolder && (
        <BasicModal
          open={createCardHolder}
          setOpen={setCreateCardHolder}
          content={<CardHolder />}
        />
      )}
      {open && (
        <BasicModal setOpen={setOpen} open={open} content={<CreateCard />} />
      )}
      <div className="flex items-center justify-between">
        <h5 className="text-2xl font-semibold mb-5">Virtual Cards</h5>
        {!cards?.length && (
          <button
            onClick={handleCreateCard}
            className="h-14 bg-primary text-white text-md px-6 border-2 border-primary flex items-center gap-x-2 font-semibold button-radius">
            Create new card
          </button>
        )}
      </div>

      {isLoading ? (
        <Spinner color="#D6AA1B" />
      ) : cards && !cards?.length ? (
        <p className="text-center font-semibold w-full text-md mt-3 text-secondary">
          No virtual cards
        </p>
      ) : (
        <div className="w-full grid grid-cols-3 gap-5">
          {cards?.map((card, id) => (
            <VirtualCard key={id} type={id} {...card} title={card?.title} />
          ))}
        </div>
      )}

      <h5 className="text-2xl font-semibold my-5">Physical Cards</h5>
      {isLoading ? (
        <Spinner color="#D6AA1B" />
      ) : physicalCards && !physicalCards?.length ? (
        <p className="text-center font-semibold text-md mt-3 text-secondary">
          No physical cards
        </p>
      ) : (
        physicalCards?.map((card, id) => (
          <PhysicalCard
            key={id}
            {...card}
            paymentBalance="10230"
            savingBalance="267923"
          />
        ))
      )}
    </div>
  );
}

export default CardPage;
