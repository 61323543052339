import axios, { AxiosRequestConfig } from "axios";
import { CONFIG } from "../../config/Config";

interface Delete {
  pathname: string;
  payload?: any;
  isProtected?: boolean;
}

export const DeleteHttp = async ({
  pathname,
  payload,
  isProtected = true,
}: Delete) => {
  try {
    const token = window.sessionStorage.getItem("token");
    const headers: AxiosRequestConfig["headers"] = isProtected
      ? {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        }
      : { "Content-Type": "multipart/form-data" };

    const header = { headers, "Content-Type": "application/json" };
    // Converting the payload to queryString
    let data = "";
    if (payload) {
      Object.keys(payload).forEach((key, id) => {
        const value = payload[key];
        if (id === 0) {
          data = data + `${key}=${value}`;
        } else {
          data = data + `&${key}=${value}`;
        }
      });
    }
    const response = await axios.delete(
      data
        ? `${CONFIG.ROOT_ADDRESS}${pathname}?${data}`
        : `${CONFIG.ROOT_ADDRESS}${pathname}`,
      header
    );

    return response.data;
  } catch (error) {
    return error;
  }
};
