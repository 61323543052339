import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";

interface FlightDetailsProps {
  flightList: any[];
  id: string;
}

const FlightDetails: React.FC<FlightDetailsProps> = ({ flightList, id }) => {
  const [flight, setFlight] = useState<any>({});
  useEffect(() => {
    if (id) {
      const flight = flightList.find((flight) => flight.id === id);
      console.log(flight);
      setFlight(flight);
    }
  }, [flightList, id]);

  return (
    <div>
      <div>
        <p className="text-primary text-sm font-semibold mb-6">
          Flight Details
        </p>
        <hr />
      </div>

      <div className="mt-4">
        <p className="text-secondary font-semibold ">
          {flight && flight.flight?.originName} To{" "}
          {flight && flight?.flight.destinationName}{" "}
        </p>
        <p className="mt-3 text-secondary font-semibold">
          Airline:{" "}
          <span className="text-secondary2 font-medium">
            {flight.outbound?.[0].airline_details.name}
          </span>
        </p>
        <p className="mt-3 text-secondary font-semibold">
          Flight Number:{" "}
          <span className="text-secondary2 font-medium">
            {flight.outbound?.[0].flight_number}
          </span>
        </p>
        <p className="mt-3 text-secondary font-semibold">
          Duration:{" "}
          <span className="text-secondary2 font-medium">
            {flight.outbound?.[0].duration} min(s)
          </span>
        </p>
        <p className="mt-3 text-secondary font-semibold">
          Arrival Time :{" "}
          <span className="text-secondary2 font-medium">
            {flight.outbound?.[0].arrival_time &&
              DateTime.fromISO(
                flight.outbound?.[0].arrival_time
              ).toLocaleString(DateTime.DATETIME_FULL)}
          </span>
        </p>
        <p className="mt-3 text-secondary font-semibold">
          Depature Time:{" "}
          <span className="text-secondary2 font-medium">
            {flight.outbound?.[0].departure_time &&
              DateTime.fromISO(
                flight.outbound?.[0].departure_time
              ).toLocaleString(DateTime.DATETIME_FULL)}
          </span>
        </p>
        <p className="mt-3 text-secondary font-semibold">
          Cabin:{" "}
          <span className="text-secondary2 font-medium">
            {flight.outbound?.[0].cabin_type}
          </span>
        </p>
      </div>
    </div>
  );
};

export default FlightDetails;
