import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import { ICONS } from "../../assets/svgs/ICONS";

export interface IExpenseData {
  date: string;
  recipientName: string;
  recipientAccountNumber: string;
  status: string;
  amount: string;
  budgetId?: {
    _id?: string;
  };
  _id?: string;
}

const Row = ({ row }: { row: IExpenseData }) => {
  return (
    <TableRow>
      <TableCell>{row.date}</TableCell>
      <TableCell align="left">
        <div className="flex items-center gap-x-3">
          <span className="flex-center w-10 h-10 rounded-full border-gray-200 font-bold text-green-600 bg-green-100">
            {row.recipientName?.split(" ")[0]?.slice(0, 1)}
            {row.recipientName?.split(" ")[1]?.slice(0, 1)}
          </span>
          {row.recipientName}
        </div>
      </TableCell>
      <TableCell align="left">{row.recipientAccountNumber}</TableCell>
      <TableCell align="left">
        {/* <span
          className={`h-10 
          ${row.status === "failed" && "bg-red-600 text-white"} 
          ${row.status === "successful" && "bg-green-600 text-white"} 
          ${
            row.status === "processing" && "bg-blue-100 text-blue-900"
          } w-[100px] font-semibold px-4 card-radius flex-center`}> */}
          {row.status}
        {/* </span> */}
      </TableCell>
      <TableCell align="left">₦{row.amount}</TableCell>
      <TableCell align="left">
        <Link to={""}>
          <ICONS.CaretRightIcon />
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default function ExpensesTable({
  expensesData = [],
}: {
  expensesData?: IExpenseData[];
}) {
  const [rows, setRows] = React.useState<IExpenseData[]>(expensesData);
  React.useEffect(() => {
    setRows(expensesData);
  }, [expensesData]);
  return (
    <TableContainer className="h-[62vh] overflow-y-scroll">
      <Table aria-label="collapsible table" className=" rounded-xl">
        <TableHead>
          <TableRow>
            <TableCell>
              <span className="text-gray-500 font-semibold">Date</span>
            </TableCell>
            <TableCell align="left">
              <span className="text-gray-500 font-semibold">
                Recipient Name
              </span>
            </TableCell>
            <TableCell align="left">
              <span className="text-gray-500 font-semibold">
                Recipient Number
              </span>
            </TableCell>
            <TableCell align="left">
              <span className="text-gray-500 font-semibold">Status</span>
            </TableCell>
            <TableCell align="left">
              <span className="text-gray-500 font-semibold">Amount</span>
            </TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: IExpenseData, index: number) => (
            <Row key={index} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
