import React, { useState } from "react";
import { ICONS } from "../../assets/svgs/ICONS";
import { Link } from "react-router-dom";
import TextInput from "../../components/inputs/TextInput";
import Spinner from "../../components/spinner/Spinner";
import { saveSystemEmail } from "../../services/requests/system/System";

const ManageSystem = () => {
  const [value, setValue] = useState({
    company_name: "",
    contact_person: "",
    email: "",
    phone_number: "",
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setValue((prevState) => ({
      ...prevState,
      [name]: value.trim(),
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    console.log(value);
    setLoading(true);
    await saveSystemEmail(value);
    setLoading(false);
  };
  return (
    <div className="w-full h-screen bg-gray-100 flex">
      <div className="bg-white w-[50%] p-10">
        <Link to="/" className="flex-left">
          <ICONS.BrandLogo />
        </Link>

        <div className="my-5">
          <h2 className="text-secondary font-bold text-xl">
            Inventory Manage System.
          </h2>
          <p className="text-secondary2 font-thin">
            Enter your information to get started
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          <TextInput
            label="Company Name"
            name="company_name"
            placeHolder="Company Name"
            value={value.company_name}
            onChange={handleInputChange}
          />

          <TextInput
            label="Contact Person"
            name="contact_person"
            placeHolder="Name of the Person in charge"
            value={value.contact_person}
            onChange={handleInputChange}
          />
          <p className="text-secondary2 font-thin">
            The name of the person who will be responsible for managing the
            inventory management system.
          </p>

          <TextInput
            label=" Email"
            name="email"
            placeHolder=" Email"
            value={value.email}
            onChange={handleInputChange}
          />

          <TextInput
            label="Phone"
            name="phone_number"
            placeHolder=" Phone Number of the conteact person"
            value={value.phone_number}
            onChange={handleInputChange}
          />

          <button
            disabled={loading === true}
            className="button-radius w-full h-16 bg-primary text-white font-me dium text-md flex-center gap-x-2 disabled:opacity-50">
            {loading && <Spinner color="#fff" />}
            Get Started
          </button>
        </form>
      </div>

      <div className=" w-[50%] p-10">
        <h2 className="text-secondary font-bold mb-2 text-2xl">
          Inventory Management System.
        </h2>
        <p className="text-secondary2 font-thin ">
          By collecting this information, we will be able to better understand
          the needs of the company and provide them with the information they
          need to make a decision as regards using Finosell's inventory
          management system.
        </p>
      </div>
    </div>
  );
};

export default ManageSystem;
