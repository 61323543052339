import React from "react";
import { useOutletContext } from "react-router-dom";

const BookedFlightSuccess = () => {
  const [setPageName] = useOutletContext<any>();

  React.useEffect(() => {
    setPageName("Flight Summary");
  }, [setPageName]);
  return <div>BookedFlightSuccess</div>;
};

export default BookedFlightSuccess;
