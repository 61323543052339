import React from "react";
import formatCurrency from "../../utils/formatCurrency";

export interface IPhysical {
  savingBalance: string;
  paymentBalance: string;
}
function PhysicalCard({ savingBalance, paymentBalance }: IPhysical) {
  const [tab, setTab] = React.useState(0);

  return (
    <div className="w-[340px] h-[205px] card-radius bg-secondary p-5">
      <div className="flex items-center justify-center">
        <button
          onClick={() => setTab(0)}
          className={`px-3 py-1.5 text-sm ${
            tab === 0 ? "bg-primary text-white" : "text-gray-100"
          } button-radius`}
        >
          Savings
        </button>
        <button
          onClick={() => setTab(1)}
          className={`px-3 py-1.5 text-sm ${
            tab === 1 ? "bg-primary text-white" : "text-gray-100"
          } button-radius`}
        >
          Payments
        </button>
      </div>
      <div className="w-full mt-7">
        {tab === 0 && (
          <h4 className="text-5xl text-white text-center">
            ₦{formatCurrency(savingBalance)}
          </h4>
        )}
        {tab === 1 && (
          <h4 className="text-5xl text-white text-center">
            ₦{formatCurrency(paymentBalance)}
          </h4>
        )}
      </div>
    </div>
  );
}

export default PhysicalCard;
