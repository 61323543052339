import React, { useEffect, useState } from "react";
import { ICONS } from "../../assets/svgs/ICONS";
import { useOutletContext, useNavigate } from "react-router-dom";
import PayrollTable, { PayrollData } from "../../components/table/PayrollTable";
import {
  getPayroll,
  payrollPayment,
} from "../../services/requests/payroll/Payroll";
import sortByDate from "../../utils/sortByDate";
import Loader from "../../components/Loader/Loader";
import toast from "react-hot-toast";
import { fetchSubAccount } from "../../services/requests/account/AccountRequest";
import formatCurrency from "../../utils/formatCurrency";
import SelectInput from "../../components/inputs/SelectInput";

const PayrollPayment = () => {
  const [setPageName] = useOutletContext<any>();
  const [beneficiary, setBeneficiary] = useState<PayrollData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [virtualAccountId, setVirtualAccountId] = useState<string>("");
  const [subAccOption, setSubAccOption] = React.useState<any>([
    { value: "", label: "" },
  ]);
  const [accBalance, setAccBalance] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getPayroll().then((res) => {
      if (res && res?.paymentDetails) {
        const sorted = sortByDate(res.paymentDetails);
        setBeneficiary(sorted);
        setLoading(false);
      } else {
        setLoading(false);
        return toast.error("Failed to fetch beneficiaries");
      }
      return;
    });

    fetchSubAccount().then((res) => {
      let subAccOption: { value: string; label: string }[] = [];
      let accBalance: any[] = [];
      if (res) {
        res?.accounts?.forEach((item: any) => {
          subAccOption.push({
            label: `${item.accountType} (${formatCurrency(
              item.anchor_Balance
            )})`,
            value: item.virtualAccountId,
          });
          accBalance.push(item?.anchor_Balance);
        });
        setAccBalance(accBalance);
        setSubAccOption(subAccOption);
      }
    });
  }, []);

  const handlePayment = async () => {
    setSubmitting(true);
    const balance = accBalance.some((item) => item < total);
   /* if (balance) {
      toast.error("Insufficient Balance");
      setSubmitting(false);
      return;
    }*/
    if (!virtualAccountId) {
      setSubmitting(false);
      toast.error("Please select an account to make payment");
      return;
    }
    if (!beneficiary.length) {
      setSubmitting(false);
      toast.error("No beneficiary found!");
      return;
    } else {
      payrollPayment({ paymentDetails: beneficiary }, virtualAccountId).then(
        (res) => {
          console.log(res);
          if (res.status === "success") {
            toast.success(res.message);
            navigate("/dashboard/success", {
              state: {
                title: "Processing",
                message: " Your Payroll is been proccessed",
              },
            });
            setSubmitting(false);
          } else if (res.response.data.status !== "success") {
            toast.error(res.response.data.message || "An error occured");
            setSubmitting(false);
          }
        }
      );
    }
  };

  useEffect(() => {
    const total = beneficiary.reduce((acc: any, curr: any) => {
      return acc + curr.salary;
    }, 0);
    setTotal(total);
  }, [beneficiary]);

  React.useEffect(() => {
    setPageName("Initiate Payment");
  }, [setPageName]);

  const removeBeneficiary = (id: string) => {
    const filtered = beneficiary.filter((item: any) => item._id !== id);
    setBeneficiary(filtered);
  };

  return (
    <div>
      {submitting && <Loader />}
      <div
        onClick={() => navigate(-1)}
        className="w-[70px] flex items-center mb-6 cursor-pointer">
        <ICONS.CaretArrowLeftIcon />
        <p>Back</p>
      </div>

      <div>
        <h2 className="text-primary text-1xl font-semibold mb-6">
          Beneficiary
        </h2>
        <hr />
      </div>

      {loading ? (
        <div className="w-full h-32 flex items-center justify-center">
          <div className="p-10 flex items-center gap-x-2 justify-center">
            <div className="w-6 h-6 rounded-full border-2 border-primary border-t-transparent animate-spin duration-1000"></div>
            <p className="text-sm animate-pulse">
              Fetching all beneficiaries...
            </p>
          </div>
        </div>
      ) : !beneficiary.length ? (
        <div className="w-full h-32 flex items-center justify-center">
          <p className="text-xl font-semibold text-center">
            No beneficiaries found!
          </p>
        </div>
      ) : (
        <>
          <div className="w-full mt-5 border border-gray-300 card-radius overflow-hidden">
            <PayrollTable
              data={beneficiary}
              tableType="payrollPayment"
              removeBeneficiary={removeBeneficiary}
            />
          </div>

          <div>
            <h3 className="text-secondary mt-5 font-bold text-2xl">
              Choose Account To Make Payment
            </h3>
            <SelectInput
              options={subAccOption}
              name="paymentMethod"
              value={virtualAccountId}
              label="Sub Account"
              onChange={(e: any) => setVirtualAccountId(e.target.value)}
              required
            />
          </div>

          <h4 className="text-secondary mt-5 font-bold text-2xl">
            Total: NGN{total?.toLocaleString()}
          </h4>

          <button
            onClick={handlePayment}
            className="h-14 bg-primary text-white text-md px-6 border-2 border-transparent flex items-center gap-x-2 font-semibold button-radius mt-6">
            Proceed
          </button>
        </>
      )}
    </div>
  );
};

export default PayrollPayment;
