import React from "react";
import { ICONS } from "../../../assets/svgs/ICONS";
import TextInput from "../../../components/inputs/TextInput";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import SelectInput from "../../../components/inputs/SelectInput";

type FormValues = {
  [key: string]: string;
};

const BusinessDetails = () => {
  const random = Math.floor(Math.random() * 1000000000);
  const regId = random.toString();

  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormValues>({
    industry: "",
    address: "",
    account_type: "business",
    regid: regId,
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const savedData = JSON.parse(localStorage.getItem("formData")!);

    if (savedData) {
      setFormData((prevState) => ({
        ...prevState,
        ...savedData,
      }));
    }
    localStorage.setItem("formData", JSON.stringify(formData));

    navigate("/business-details-registration");
  };

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("formData")!);
    if (savedData) {
      setFormData((prevState) => ({
        ...prevState,
        ...savedData,
      }));
    }
  }, []);

  const options = [
    { value: "Automotive", label: "Automotive" },
    { value: "Computers & Electronics", label: "Computers & Electronics" },
    {
      value: "Construction & Contractors",
      label: "Construction & Contractors",
    },
    { value: "Education", label: "Education" },
    { value: "Food", label: "Food" },
    { value: "Health & Medicine", label: "Health & Medicine" },
    { value: "Merchants", label: "Merchants" },
    { value: "Real Estate", label: "Real Estate" },
    { value: "Travel & Transportation", label: "Travel & Transportation" },
    { value: "Study Abroad", label: "Study Abroad" },
    { value: "Fintech", label: "Fintech" },
    { value: "Agriculture", label: "Agriculture" },
    { value: "internet companies", label: "internet companies" },
    { value: "Marketing firm", label: "Marketing firm" },
    { value: "Importation", label: "Importation" },
  ];

  return (
    <div className="w-full h-screen bg-gray-100  flex-center">
      <div className="w-[703px] px-20 py-12 _h-[812px] login-box-radius bg-white rounded-2.5xl rounded-tl-none flex-center flex-col">
        <div className="top-section flex-center">
          <ICONS.BrandLogo />
        </div>

        <div className="title mt-6 flex-center flex-col">
          <h3 className="text-4xl text-secondary font-bold">
            Add Business Details
          </h3>
          <p className="mt-1 text-md text-[#8C8C8C] font-medium">
            Use correct information about your business
          </p>
        </div>

        <form
          className="input-container w-full mt-[3rem]"
          onSubmit={handleSubmit}>
          <div className="flex justify-between gap-3">
            <div className="input-wrapper w-[47%]">
              <SelectInput
                options={options}
                label="Category"
                name="industry"
                onChange={handleInputChange}
              />
            </div>

            <div className="input-wrapper w-[47%]">
              <TextInput
                value={"Business"}
                label="Account Type"
                name="account_type"
                placeHolder="Account Type"
                readonly
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="input-wrapper my-2">
            <TextInput
              value={formData.address}
              label="Address"
              name="address"
              type="text"
              placeHolder="Address"
              onChange={handleInputChange}
            />
          </div>

          <div className="button-wrapper mt-12">
            <button className="button-radius w-full h-16 bg-primary text-white font-medium text-md flex-center gap-x-2">
              Continue
            </button>

            <Link
              to=""
              className="text-primary font-medium text-center mt-2 block">
              {" "}
              Skip for now
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BusinessDetails;
