import React from "react";
import { ICONS } from "../../assets/svgs/ICONS";
import TabOne from "./TabOne";
import TabTwo from "./TabTwo";
import {
  resetPassword,
  sendResetCode,
} from "../../services/requests/onboard-user/ForgetPwdRequest";
import toast from "react-hot-toast";
interface Payload {
  email: string;
  otp: string;
  password: string;
}
function ForgetPwdPage() {
  const [activeTab, setActiveTab] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [forgetPwdPayload, setForgetPwdPayload] = React.useState<Payload>({
    email: "",
    otp: "",
    password: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForgetPwdPayload({
      ...forgetPwdPayload,
      [name]: value,
    });
  };

  const handleOtpChange = (value: string) => {
    setForgetPwdPayload({
      ...forgetPwdPayload,
      otp: value,
    });
  };

  const handleSubmit = async () => {
    if (!forgetPwdPayload.email) {
      toast.error("Email is required");
    } else if (!forgetPwdPayload.otp) {
      toast.error("OTP is required");
    } else if (!forgetPwdPayload.password) {
      toast.error("Password is required");
    } else {
      const formattedPayload = {
        password: forgetPwdPayload.password,
        retype_password: forgetPwdPayload.password,
        auth_code: forgetPwdPayload.otp,
        email: forgetPwdPayload.email,
      };
      setIsLoading(true);
      const response = await resetPassword(formattedPayload);
      console.log(response);
      if (response.status === "success") {
        toast.success(response.message);
        window.location.reload();
      }
      setIsLoading(false);
    }
  };

  const handleNextTab = async () => {
    setIsLoading(true);
    if (!forgetPwdPayload.email) {
      toast.error("Email address is required");
    } else if (!forgetPwdPayload.password) {
      toast.error("Password is required");
    } else {
      const response = await sendResetCode({
        email: forgetPwdPayload.email,
      });
      if (response.status === "success" || response.success) {
        setActiveTab((prevTabId) => prevTabId + 1);
        toast.success(response.message);
      }
    }
    setIsLoading(false);
  };
  return (
    <div className="w-full h-screen bg-[#FAFAFA] flex-center">
      <div className="w-[703px] px-20 py-12 _h-[812px] login-box-radius bg-white border border-gray-100 rounded-2.5xl rounded-tl-none flex-center flex-col">
        <div className="top-section flex-center">
          <ICONS.BrandLogo />
        </div>
        {activeTab === 0 && (
          <TabOne
            forgetPwdPayload={forgetPwdPayload}
            handleInputChange={handleInputChange}
            handleNextTab={handleNextTab}
            isLoading={isLoading}
          />
        )}
        {activeTab === 1 && (
          <TabTwo
            forgetPwdPayload={forgetPwdPayload}
            handleOtpChange={handleOtpChange}
            handleNextTab={handleSubmit}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
}

export default ForgetPwdPage;
