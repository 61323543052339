import React from "react";
import { ICONS } from "../../../assets/svgs/ICONS";
import TextInput from "../../../components/inputs/TextInput";
import { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { createBusinessAccountRequest } from "../../../services/requests/createAccount/BusinessRequest";
import PasswordInput from "../../../components/inputs/PasswordInput";

const BusinessRegistration = () => {
  // const navigate = useNavigate();

  const [formData, setFormData] = useState({
    state: "",
    city: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  // const [status, setStatus] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const savedData = JSON.parse(localStorage.getItem("formData")!);

    if (savedData) {
      setFormData((prevState) => ({
        ...prevState,
        ...savedData,
      }));
    }
    localStorage.setItem("formData", JSON.stringify(formData));
    if (formData) createBusinessAccountRequest(formData, setLoading);
  };

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("formData")!);
    if (savedData) {
      setFormData((prevState) => ({
        ...prevState,
        ...savedData,
      }));
    }
  }, []);

  // useEffect(() => {
  //   if (status && !loading) {
  //     navigate("/verify-number");
  //   }
  // }, [status, navigate, loading]);

  return (
    <div className="w-full h-screen bg-gray-100  flex-center">
      <div className="w-[703px] px-20 py-12 _h-[812px] login-box-radius bg-white rounded-2.5xl rounded-tl-none flex-center flex-col">
        <div className="top-section flex-center">
          <ICONS.BrandLogo />
        </div>

        <div className="title mt-6 flex-center flex-col">
          <h3 className="text-4xl text-secondary font-bold">
            Add business details
          </h3>
          <p className="mt-1 text-md text-[#8C8C8C] font-medium">
            Use your personal business details
          </p>
        </div>

        <form
          autoComplete="off"
          className="input-container w-full mt-[3rem]"
          onSubmit={handleSubmit}>
          <div className="flex justify-between gap-3">
            <div className="input-wrapper w-[47%]">
              <TextInput
                value={formData.city}
                label="City"
                name="city"
                placeHolder="City"
                onChange={handleInputChange}
              />
            </div>

            <div className="input-wrapper w-[47%]">
              <TextInput
                value={formData.state}
                label="State"
                name="state"
                type="text"
                placeHolder="State"
                autocomplete="off"
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="input-wrapper">
            <PasswordInput
              onChange={handleInputChange}
              value={formData.password}
            />
          </div>

          <div className="button-wrapper mt-12">
            <button className="button-radius w-full h-16 bg-primary text-white font-medium text-md flex-center gap-x-2">
              {loading && (
                <span className="block w-6 h-6 rounded-full border-2 border-t-transparent border-white animate-spin duration-1000"></span>
              )}
              Register
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BusinessRegistration;
