import toast from "react-hot-toast";
import { GetHttp, GetHttpQuery } from "../../https/GetHttp";
import { PostHttp } from "../../https/PostHttp";
import { PatchHttp } from "../../https/PatchHttp";
import { DeleteHttp } from "../../https/DeleteHttp";

export const fetchBudget = () => {
  const response: any = GetHttpQuery({
    pathname: "/budget/budgets",
    queryName: "budgetData",
  });
  if (response) {
    if (response?.status < 200 || response?.status > 204) {
      toast.error(response?.response?.data?.message);
    }
    return response.data;
  }
};

export const getBudget = async () => {
  const response: any = await GetHttp({
    pathname: "/budget/budgets",
  });
  if (response) {
    if (response?.status < 200 || response?.status > 204) {
      toast.error(response?.response?.data?.message);
    }
    return response.data;
  }
};

export const fetchSingleBudget = async (budgetId: string) => {
  const response: any = await GetHttp({
    pathname: `/budget/${budgetId}`,
  });
  if (response) {
    if (response?.status < 200 || response?.status > 204) {
      toast.error(response?.response?.data?.message);
    }
    return response.data;
  }
};

export const createBudget = async (payload: any) => {
  const response = await PostHttp({
    pathname: "/budget/create",
    payload: payload,
    formType: "json",
  });

  if (response?.response?.status < 200 || response?.response?.status > 204) {
    toast.error(response?.response?.data?.message);
  }
  return response;
};

export const editBudget = async (payload: any) => {
  const response = await PatchHttp({
    pathname: `/budget/${payload.id}`,
    payload: payload,
  });

  if (response?.response?.status < 200 || response?.response?.status > 204) {
    toast.error(response?.response?.data?.message);
  }
  return response;
};

export const deleteBudget = async (payload: any) => {
  const response = await DeleteHttp({
    pathname: `/budget/${payload.id}`,
  });

  if (response?.response?.status < 200 || response?.response?.status > 204) {
    toast.error(response?.response?.data?.message);
  }
  return response;
};

