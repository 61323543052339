import React, { useState, useEffect } from "react";
import TextareaInput from "../../../components/inputs/TextareaInput";
import TextInput from "../../../components/inputs/TextInput";
import DateInput from "../../../components/inputs/DateInput";
import LimitInput from "../../../components/inputs/LimitInput";
import FormButton from "../../../components/buttons/FormButton";
import { BUDGET_TYPES } from "../AddBudgetPage";
import SelectInput from "../../../components/inputs/SelectInput";
import { getTeamMembers } from "../../../services/requests/member/MemberRequest";

function TabTwo({
  payload,
  handleInputChange,
  onSubmit,
}: {
  payload: any;
  handleInputChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => void;
  onSubmit: () => void;
}) {
  const [members, setMembers] = React.useState<any>([]);
  const [selectedTypeText, setSelectedTypeText] = useState<{
    name: string;
    title: string;
    description: string;
  }>();
  useEffect(() => {
    const getTeamMembersHandler = async () => {
      const response = await getTeamMembers();
      let teamMembers: { value: string; label: string }[] = [];
      response?.teamMembers?.forEach(
        ({
          _id,
          firstName,
          lastName,
        }: {
          _id: string;
          firstName: string;
          lastName: string;
        }) => {
          teamMembers.push({
            value: _id,
            label: `${firstName} ${lastName}`,
          });
        }
      );
      setMembers(teamMembers);
    };
    getTeamMembersHandler();
  }, []);

  useEffect(() => {
    BUDGET_TYPES.forEach((eachType: any) => {
      if (eachType.name === payload.type) {
        setSelectedTypeText(eachType);
      }
    });
  }, [payload.type]);

  return (
    <div>
      <label className="flex items-center gap-x-5 px-5 w-[501px] h-[106px] border border-gray-200 card-radius">
        <input
          onChange={handleInputChange}
          type="radio"
          name="type"
          value="vendor"
          className="hidden"
        />
        <span
          className={`indicator w-10 h-10 input-radius bg-primary duration-300`}
        ></span>
        <div>
          <h5 className="text-sm text-gray-500 font-medium">
            {selectedTypeText?.title}
          </h5>
          <p className="text-sm text-gray-500 font-medium">
            {selectedTypeText?.description}
          </p>
        </div>
      </label>

      <div className="w-full pr-28">
        <TextInput
          label="Title"
          name="title"
          onChange={handleInputChange}
          placeHolder="Enter budget title"
          value={payload.title}
        />
        <LimitInput
          label="Limit"
          name="limit"
          onChange={handleInputChange}
          placeHolder="Enter budget limit"
          value={payload.limit}
          type="number"
        />
        <SelectInput
          options={members}
          label="Authorized Member"
          name="authorizedMember"
          onChange={handleInputChange}
          value={payload.authorizedMember[0]}
        />
        <DateInput
          label="Start Date"
          name="startDate"
          onChange={handleInputChange}
          placeHolder="Enter budget start date"
          value={payload.startDate}
          type="date"
        />
        <DateInput
          label="Expiry Date"
          name="expiryDate"
          onChange={handleInputChange}
          placeHolder="Enter budget expiry date"
          value={payload.expiryDate}
          type="date"
        />
        <TextareaInput
          label="Description"
          name="description"
          placeHolder="Description"
          value={payload.description}
          onChange={handleInputChange}
        />

        <div className="w-[140px]">
          <FormButton text="Continue" isLoading={false} onClick={onSubmit} />
        </div>
      </div>
    </div>
  );
}

export default TabTwo;
