import React, { useState, useEffect } from "react";
import { ICONS } from "../../../assets/svgs/ICONS";
import TextInput from "../../../components/inputs/TextInput";
import { sendSmsVerification } from "../../../services/requests/createAccount/BusinessRequest";
import Spinner from "../../../components/spinner/Spinner";

const VerifyNumber = () => {
  const [verificaton_type, setVerificationType] = useState(0);
  const [phone_number, setPhoneNumber] = useState({
    user: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("formData")!);
    if (savedData) {
      const number = savedData.phone_number;
      setPhoneNumber({
        user: number,
      });
    }
  }, []);

  const handleCheck = (index: number) => {
    setVerificationType(index);
  };

  const handleConitnue = () => {
    sendSmsVerification(phone_number, setLoading);
  };

  return (
    <div className="w-full h-screen bg-gray-100  flex-center">
      <div className="w-[703px] px-20 py-12 _h-[812px] login-box-radius bg-white rounded-2.5xl rounded-tl-none flex-center flex-col">
        <div className="top-section flex-center">
          <ICONS.BrandLogo />
        </div>

        <div className="title mt-6 flex-center flex-col">
          <h3 className="text-4xl text-secondary font-bold">
            Verify Phone Number
          </h3>
          <p className="mt-1 text-md text-[#8C8C8C] font-medium text-center">
            We need to verify a phone number witht which you can recieve OTP for
            your card transactions.
          </p>
        </div>

        <div className="mt-[3rem] w-full">
          <TextInput
            type="text"
            value={phone_number.user}
            label="Phone Number"
            placeHolder="Phone Number"
            name="user"
            readonly={true}
          />

          <div className="">
            <h3 className="font-semibold text-secondary">
              Recieve OTP via sms
            </h3>

            <div className="mt-[1rem] flex items-center flex-col gap-6">
              <label
                htmlFor="sms"
                className={`${
                  verificaton_type === 0
                    ? "border-[#F5D776] bg-[#FDF9ED]"
                    : "border-[#D9D9D9]"
                } flex items-center justify-between  input-radius p-5 outline-none border w-full bg-[#FAFAFA] cursor-pointer peer-checked/sms:`}>
                <div className="flex gap-2 items-center">
                  <ICONS.EmailIcon />
                  <p className="font-semibold text-secondary">Via SMS</p>
                </div>

                <input
                  type="radio"
                  name="sms"
                  id="sms"
                  onChange={() => handleCheck(0)}
                  checked={verificaton_type === 0}
                  className="peer/sms"
                />
              </label>

              <button
                onClick={handleConitnue}
                className="button-radius w-full h-16 bg-primary text-white font-medium text-md flex-center gap-x-2">
                {loading && <Spinner color={'#fff'}/>}
                Continue
              </button>

              {/* <label
                htmlFor="mail"
                className={`${
                  verificaton_type === 1
                    ? "border-[#F5D776] bg-[#FDF9ED]"
                    : "border-[#D9D9D9]"
                } flex items-center justify-between  input-radius p-5 outline-none border w-full bg-[#FAFAFA] cursor-pointer`}>
                <div className="flex gap-2 items-center">
                  <ICONS.EmailIcon />
                  <p className="font-semibold text-secondary">Via Email</p>
                </div>

                <input
                  type="radio"
                  name="mail"
                  id="mail"
                  onChange={() => handleCheck(1)}
                  checked={verificaton_type === 1}
                  className="peer/mail"
                />
              </label> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyNumber;
