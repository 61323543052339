import React, { useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { ICONS } from "../../assets/svgs/ICONS";
import TabOne from "./add-budget-tab/TabOne";
import TabThree from "./add-budget-tab/TabThree";
import TabTwo from "./add-budget-tab/TabTwo";
import toast from "react-hot-toast";
import { createBudget } from "../../services/requests/budget/BudgetRequest";
import TabSuccess from "./add-budget-tab/TabSuccess";

export const BUDGET_TYPES = [
  {
    name: "department",
    title: "Departments",
    description: "This is Depatemental budget Type",
  },
  {
    name: "hr",
    title: "Human Resources",
    description: "This is HR budget Type",
  },
  {
    name: "operations",
    title: "Operations",
    description: "This is Operational budget Type",
  },
  {
    name: "event",
    title: "Events",
    description: "This is Event budget Type",
  },
];

function AddBudgetPage() {
  const [setPageName] = useOutletContext<any>();
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  const [createBudgetPayload, setCreateBudgetPayload] = React.useState({
    title: "",
    limit: "",
    type: "",
    startDate: "",
    expiryDate: "",
    authorizedMember: [""],
    description: "",
  });

  const gotoTabOne = () => setActiveTabIndex(0);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    if (name === "authorizedMember") {
      setCreateBudgetPayload({
        ...createBudgetPayload,
        [name]: [value],
      });
    } else if (name === "type") {
      setCreateBudgetPayload({
        ...createBudgetPayload,
        [name]: value,
      });
      setActiveTabIndex(1);
    } else {
      setCreateBudgetPayload({
        ...createBudgetPayload,
        [name]: value,
      });
    }
  };

  const continueToPreviewTab = () => {
    if (!createBudgetPayload.type) {
      toast.error("Budget type is required");
    } else if (!createBudgetPayload.title) {
      toast.error("Budget title is required");
    } else if (!createBudgetPayload.limit) {
      toast.error("Budget limit is required");
    } else if (!createBudgetPayload.startDate) {
      toast.error("Budget start date is required");
    } else if (!createBudgetPayload.expiryDate) {
      toast.error("Budget expiry date is required");
    } else {
      setActiveTabIndex(2);
    }
  };

  const continueToDetailsTab = () => {
    if (!createBudgetPayload.type) {
      toast.error("Budget type is required");
    } else {
      setActiveTabIndex(1);
    }
  };

  const createBudgetHandler = async () => {
    if (!createBudgetPayload.type) {
      toast.error("Budget type is required");
    } else if (!createBudgetPayload.title) {
      toast.error("Budget title is required");
    } else if (!createBudgetPayload.limit) {
      toast.error("Budget limit is required");
    } else if (!createBudgetPayload.startDate) {
      toast.error("Budget start date is required");
    } else if (!createBudgetPayload.authorizedMember) {
      toast.error("Authorized member is required");
    } else if (!createBudgetPayload.expiryDate) {
      toast.error("Budget expiry date is required");
    } else {
      const responseData = await createBudget(createBudgetPayload);
      if (
        (responseData?.code >= 200 && responseData?.code <= 204) ||
        responseData?.status === "success"
      ) {
        toast.success("Budget Successfully Created");
        setActiveTabIndex(3);
        // navigateTo("/dashboard/budget");
      } else {
        const { response } = responseData;
        toast.error(response.data.error);
      }
    }
  };

  useEffect(() => {
    setPageName("Create Budget");
  }, [setPageName]);

  const tabMenu = [
    { id: 0, onClick: gotoTabOne, text: "Select type of Spend" },
    { id: 1, onClick: continueToDetailsTab, text: "Budget Details" },
    { id: 2, onClick: continueToPreviewTab, text: "Review" },
  ];

  return (
    <div className="">
      <div className="top w-full flex flex-col">
        <Link to={"/dashboard/budget"} className="flex items-center gap-x-2">
          <ICONS.CaretArrowLeftIcon />
          <p className="font-semibold text-md text-gray-600">Back</p>
        </Link>
        <h5 className="font-bold text-gray-800 text-md my-4">
          What type of Budget Do you Need.
        </h5>
        <div className="tab-container flex items-center gap-x-2 mt-4">
          {activeTabIndex < tabMenu.length && (
            <div className="tab-container border-b-2 border-gray-200 flex items-center gap-x-2 mt-4">
              {tabMenu.map(({ id, onClick, text }) => (
                <button
                  key={id}
                  onClick={onClick}
                  className={`h-9 text-sm px-2 font-semibold flex items-start border-b-2 -mb-0.5 ${
                    activeTabIndex === id
                      ? "border-primary text-primary"
                      : "text-gray-600 border-transparent"
                  } `}
                >
                  {text}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="tab-container py-5">
        {activeTabIndex === 0 && (
          <TabOne
            value={createBudgetPayload.type}
            handleInputChange={handleInputChange}
          />
        )}
        {activeTabIndex === 1 && (
          <TabTwo
            payload={createBudgetPayload}
            handleInputChange={handleInputChange}
            onSubmit={continueToPreviewTab}
          />
        )}
        {activeTabIndex === 2 && (
          <TabThree
            payload={createBudgetPayload}
            onSubmit={createBudgetHandler}
          />
        )}
        {activeTabIndex === 3 && (
          <TabSuccess
            data={{
              title: "Completed",
              description: "Your Budget has been created successfully",
            }}
          />
        )}
      </div>
    </div>
  );
}

export default AddBudgetPage;
