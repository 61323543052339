import React from "react";
import BudgetTypeCard from "./BudgetTypeCard";
import { BUDGET_TYPES } from "../AddBudgetPage";

function TabOne({
  value,
  handleInputChange,
}: {
  value: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <div className="flex flex-col gap-y-5">
      {BUDGET_TYPES.map(({ title, name, description }, id) => (
        <BudgetTypeCard
          key={id}
          name={name}
          value={value}
          title={title}
          description={description}
          handleInputChange={handleInputChange}
        />
      ))}
    </div>
  );
}

export default TabOne;
