import { ICONS } from "../../assets/svgs/ICONS";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import ActionButton from "../../components/buttons/ActionButton";

const Success = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [info, setInfo] = useState<any>({
    title: "",
    message: "",
  });

  useEffect(() => {
    if (location.state) {
      setInfo(location.state);
    }
  }, [location]);

  return (
    <div className="w-full">
      <div
        onClick={() => navigate(-1)}
        className="w-[70px] flex items-center mb-6 cursor-pointer">
        <ICONS.CaretArrowLeftIcon />
        <p>Back</p>
      </div>

      <div className="bg-secondary w-[70%] px-20 py-12 h-[500px] m-auto rounded-2xl flex-center flex-col items-center">
        <div className="bg-primary rounded-tl-none rounded-[50px] w-[100px] h-[100px] flex items-center justify-center">
          <ICONS.CheckIcon />
        </div>
        <div className="mt-[2rem] text-center">
          <h1 className="text-white font-bold text-3xl">{info.title}</h1>
          <p className="text-white mt-3 text-xs">{info.message}</p>
          <ActionButton
            onClick={() => navigate("/dashboard")}
            className="mt-4 button-radius w-full border h-16 border-primary  bg-transparent text-white font-medium text-md flex-center gap-x-2"
            text="Dashboard"
          />
        </div>
      </div>
    </div>
  );
};

export default Success;
