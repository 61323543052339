import React, { useState } from "react";
import SearchInput from "../../components/inputs/SearchInput";
// import { ICONS } from "../../assets/svgs/ICONS";
import BudgetTable, { IcreateData } from "../../components/table/BudgetTable";
import { Link, useOutletContext } from "react-router-dom";
import AddMoneyModal from "../../components/modal/AddMoneyModal";
import FormButton from "../../components/buttons/FormButton";
import { deleteBudget } from "../../services/requests/budget/BudgetRequest";
import toast from "react-hot-toast";

function ViewBudgetPage({
  searchInput,
  handleSearchInput,
  budgetData,
}: {
  searchInput: string;
  handleSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  budgetData: IcreateData[];
}) {
  const [setPageName] = useOutletContext<any>();
  const [loading, setLoading] = useState({
    continueBtnLoading: false,
  });
  const [modal, setModal] = useState({
    show: false,
    type: "delete-confirm",
  });
  const [selectedBudgetId, setSelectedBudgetId] = useState("");
  React.useEffect(() => {
    setPageName("Budget");
  }, [setPageName]);

  const showEditConfirmModal = (budgetId: string) => {
    setModal({
      type: "edit-confirm",
      show: true,
    });
    setSelectedBudgetId(budgetId);
  };

  const showDeleteConfirmModal = (budgetId: string) => {
    setModal({
      type: "delete-confirm",
      show: true,
    });
    setSelectedBudgetId(budgetId);
  };

  const deleteBudgetHandler = async () => {
    setLoading((previousState) => ({
      ...previousState,
      continueBtnLoading: true,
    }));
    await deleteBudget({ id: selectedBudgetId });
    toast.success(`Budget ${selectedBudgetId} deleted successfully`);
    setLoading((previousState) => ({
      ...previousState,
      continueBtnLoading: false,
    }));
    setModal((previousState) => ({
      ...previousState,
      show: false,
    }));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  return (
    <div>
      <div className="w-full flex items-center justify-between">
        <SearchInput
          searchInput={searchInput}
          handleSearchInput={handleSearchInput}
        />
        <div className="button-group flex items-center gap-x-4">
          {/* <button className="h-14 px-7 border-2 text-md border-gray-300 flex items-center gap-x-2 font-semibold button-radius">
            Filter <ICONS.CaretDownIcon />
          </button> */}
          <Link
            to={"#add-budget"}
            className="h-14 bg-primary text-white text-md px-6 border-2 border-primary flex items-center gap-x-2 font-semibold button-radius"
          >
            Add Budget
          </Link>
        </div>
      </div>
      <div className="container w-full py-6">
        <BudgetTable
          budgetData={budgetData}
          showDeleteConfirmModal={showDeleteConfirmModal}
          showEditConfirmModal={showEditConfirmModal}
        />
      </div>

      <AddMoneyModal
        title="Confirm Delete"
        open={modal.show && modal.type === "delete-confirm"}
        onDismiss={() => setModal({ show: false, type: "delete-confirm" })}
        className="flex items-center justify-center border-none outline-none"
      >
        <div className="flex flex-col items-center w-full">
          <h5 className="text-md text-center w-64 my-10">
            Are you sure you want to delete the selected budget?
          </h5>
          <div className="w-full">
            <FormButton
              isLoading={loading.continueBtnLoading}
              onClick={deleteBudgetHandler}
              text="Yes, Continue"
            />
          </div>
        </div>
      </AddMoneyModal>

      <AddMoneyModal
        title="Confirm Edit"
        open={modal.show && modal.type === "edit-confirm"}
        onDismiss={() => setModal({ show: false, type: "edit-confirm" })}
        className="flex items-center justify-center border-none outline-none"
      >
        <div className="flex flex-col items-center w-full">
          <h5 className="text-md text-center w-64 my-10">
            Are you sure you want to edit the selected budget?
          </h5>
          <div className="w-full grid grid-cols-2 gap-3">
            <button
              onClick={() => setModal({ show: false, type: "edit-confirm" })}
              className="w-full h-full card-radius bg-gray-200"
            >
              No, go back
            </button>
            <Link to={`/dashboard/budget/${selectedBudgetId}#edit-budget`}>
              <FormButton
                isLoading={false}
                onClick={() => {}}
                text="Yes, Continue"
              />
            </Link>
          </div>
        </div>
      </AddMoneyModal>
    </div>
  );
}

export default ViewBudgetPage;
