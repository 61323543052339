
function TabSuccess({
  data,
}: {
  data: {
    title: string;
    description: string;
  };
}) {
  return (
    <div className="w-full h-[75vh]">
      <div className="w-full h-full bg-secondary card-radius flex-col flex-center">
        <svg
          width="147"
          height="147"
          viewBox="0 0 147 147"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0H73.5C114.093 0 147 32.9071 147 73.5C147 114.093 114.093 147 73.5 147C32.9071 147 0 114.093 0 73.5V0Z"
            fill="#D6AA1B"
          />
          <path
            d="M47 70.4694L63.8893 87.2091C65.4485 88.7545 67.9618 88.7545 69.5209 87.2091L100 57"
            stroke="white"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <h4 className="title text-3xl font-semibold my-5 text-white">{data.title}</h4>
        <p className="description w-[300px] leading-6 text-center text-xl text-white">{data.description}</p>
      </div>
    </div>
  );
}

export default TabSuccess;
