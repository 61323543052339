import { toast } from "react-hot-toast";
import { PostHttp } from "../../https/PostHttp";

type RequestParams = {
  [key: string]: string;
};

export const createBusinessAccountRequest = async (
  data: RequestParams,
  setLoading: (value: boolean) => void
) => {
  setLoading(true);
  await PostHttp({
    pathname: "/auths/firststage",
    payload: data,
    formType: "json",
  }).then((res) => {
    console.log(res.success);

    if (res.success) {
      toast.success("Account created successfully");
      setLoading(false);
      window.location.href = "/verify-number";
    } else {
      setLoading(false);
      return toast.error(
        res?.response?.data?.message || "Error creating account"
      );
    }
  });
};

export const sendSmsVerification = async (
  data: RequestParams,
  setLoading: (value: boolean) => void
) => {
  setLoading(true);
  await PostHttp({
    pathname: "/sms/sendsms",
    payload: data,
    formType: "json",
  }).then((res) => {
    console.log(res);

    if (res?.status === "success") {
      toast.success("Verification code sent successfully");
      setLoading(false);
      window.location.href = "/verify-otp";
    } else {
      setLoading(false);
      return toast.error(
        res?.response?.data?.message || "Error sending verification code"
      );
    }
  });
};

export const verifyOtpCode = async (data: RequestParams) => {
  await PostHttp({
    pathname: "/sms/verifysms",
    payload: data,
    formType: "json",
  }).then((res) => {
    console.log(res);

    if (res.status === "success") {
      toast.success("Verification successful");
      window.sessionStorage.setItem("newUser", "true");
      window.location.href = "/";
    } else if (res?.response?.data?.data === "Expired") {
      toast.error("OTP expired");
    } else {
      return toast.error(
        res?.response?.data?.message || "Error verifying code"
      );
    }
    return res;
  });
};
