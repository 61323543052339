import React, { useState, useEffect } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import { ICONS } from "../../assets/svgs/ICONS";
import SearchInput from "../../components/inputs/SearchInput";
import { getTransactionHistory } from "../../services/requests/payroll/Payroll";
import PayrollTable, { PayrollData } from "../../components/table/PayrollTable";

const TransactionHistory = () => {
  const [setPageName] = useOutletContext<any>();
  const [searchInput, setSearchInput] = useState<string>("");
  const navigate = useNavigate();
  const [tableType] = useState("payrollHistory");
  const [historyData, setHistoryData] = useState<PayrollData[]>([]);
  const [loading, setLoading] = useState(false);
  const [backup, setBackup] = useState<PayrollData[]>([]);

  React.useEffect(() => {
    setPageName("Transaction History");
  }, [setPageName]);

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchInput(value);

    if (value.length >= 3) {
      //create a search
      const search = backup.filter(
        (data) =>
          data?.recipientAccountNumber
            ?.toLowerCase()
            ?.includes(value?.toLowerCase()) ||
          data?.recipientName?.toLowerCase()?.includes(value?.toLowerCase())
      );

      setHistoryData(search);
    } else {
      setHistoryData(backup);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTransactionHistory().then((res) => {
      if (res?.payments) {
        const sort = res.payments.sort((a: any, b: any) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        });
        setHistoryData(sort);
        setBackup(sort);
        setLoading(false);
      } else {
        setLoading(false);
        setHistoryData([]);
      }
    });
  }, []);

  return (
    <div>
      <div
        onClick={() => navigate(-1)}
        className="w-[70px] flex items-center mb-6 cursor-pointer">
        <ICONS.CaretArrowLeftIcon />
        <p>Back</p>
      </div>

      <div className="flex items-center justify-between mb-6">
        <h2 className="text-primary text-1xl font-semibold">
          Transaction History
        </h2>

        <SearchInput
          handleSearchInput={handleSearchInput}
          searchInput={searchInput}
        />
      </div>
      <hr />

      {loading ? (
        <div className="w-full h-32 flex items-center justify-center">
          <div className="p-10 flex items-center gap-x-2 justify-center">
            <div className="w-6 h-6 rounded-full border-2 border-primary border-t-transparent animate-spin duration-1000"></div>
            <p className="text-sm animate-pulse">Fetching all history...</p>
          </div>
        </div>
      ) : !historyData.length ? (
        <div className="w-full h-32 flex items-center justify-center">
          <p className="text-xl font-semibold text-center">No transactions found!</p>
        </div>
      ) : (
        <div className="w-full mt-5 border border-gray-300 card-radius overflow-hidden">
          <PayrollTable tableType={tableType} data={historyData} />
        </div>
      )}
    </div>
  );
};

export default TransactionHistory;
