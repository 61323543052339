// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import AccountInfo from "../../pages/account/components/AccountInfo";
import { ISubAccount } from "../../pages/account/AccountPage";

const SubAccountSwiperContainer = ({
  subAccounts,
  viewBalance
}: {
  subAccounts: ISubAccount[];
  viewBalance?: boolean
}) => {
  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={3}
      pagination={{ clickable: true }}
      breakpoints={{
        300: {
          slidesPerView: 1,
          spaceBetween: 24,
        },
        450: {
          slidesPerView: 1,
          spaceBetween: 24,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 24,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
        1184: {
          slidesPerView: 3,
          spaceBetween: 24,
        },
      }}
      modules={[Navigation]}
      navigation={true}
      loop={false}>
      {subAccounts.map(
        (
          { accountName, accountNumber, accountType, bankName, balance },
          id
        ) => (
          <SwiperSlide key={id}>
            <AccountInfo
              accountName={accountName}
              accountNumber={accountNumber}
              accountType={accountType}
              bankName={bankName}
              balance={viewBalance ? balance : "****"}
              viewBalance={viewBalance}
            />
            <br />
          </SwiperSlide>
        )
      )}
    </Swiper>
  );
};
export default SubAccountSwiperContainer;
