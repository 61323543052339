import React from "react";
import { ICONS } from "../../../assets/svgs/ICONS";
import TextInput from "../../../components/inputs/TextInput";
import { useState, useEffect } from "react";
import EmailInput from "../../../components/inputs/EmailInput";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

type FormValues = {
  [key: string]: string;
};

const BusinessAccount = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormValues>({
    name: "",
    email: "",
    phone_number: "",
  });

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("formData")!);
    if (savedData) {
      setFormData((prevState) => ({
        ...prevState,
        ...savedData,
      }));
    }
  }, []);

  const handleInputChange = (e: any) => {
    const { value, name } = e.target;

    switch (name) {
      case "phone_number":
        const mobilePattern = /^[\d]+$/;
        const isFirstDigitZero = value.slice(0, 1) === "0";
        const stripFirstZero = value.slice(1, value.length);
        const first3Digit = value.slice(0, 3);
        if (mobilePattern.test(value) || value === "") {
          if (isFirstDigitZero) {
            setFormData({
              ...formData,
              [name]: `234${stripFirstZero}`,
            });
          } else if ("234".includes(first3Digit)) {
            setFormData({
              ...formData,
              [name]: value,
            });
          } else {
            toast("Please enter a valid phone number starting with 234");
          }
        } else {
          toast("Input type not supported");
        }
        break;
      default:
        setFormData({
          ...formData,
          [name]: value,
        });
        break;
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    localStorage.setItem("formData", JSON.stringify(formData));
    navigate("/add-details");
  };
  return (
    <div className="w-full h-screen bg-gray-100  flex-center">
      <div className="w-[703px] px-20 py-12 _h-[812px] login-box-radius bg-white rounded-2.5xl rounded-tl-none flex-center flex-col">
        <div className="top-section flex-center">
          <ICONS.BrandLogo />
        </div>

        <div className="title mt-6 flex-center flex-col">
          <h3 className="text-4xl text-secondary font-bold">
            Create a business account
          </h3>
          <p className="mt-1 text-md text-[#8C8C8C] font-medium">
            Use your personal information
          </p>
        </div>

        <form
          className="input-container w-full mt-[3rem]"
          onSubmit={handleSubmit}>
          <div className="input-wrapper">
            <TextInput
              value={formData.name}
              label="Business Name"
              type="text"
              name="name"
              placeHolder="Business Name"
              onChange={handleInputChange}
            />
          </div>
          <div className="input-wrapper my-2">
            <EmailInput
              value={formData.email}
              name="email"
              onChange={handleInputChange}
            />
          </div>
          <div className="input-wrapper">
            <label htmlFor="">Phone Number</label>
            <div className="w-full relative">
              <input
                required
                value={formData.phone_number}
                placeholder="Enter your mobile number"
                type="text"
                name="phone_number"
                onChange={handleInputChange}
                className="h-[64px] w-full input-radius pl-14 outline-none border border-[#D9D9D9] invalid:border-[#D9D9D9] valid:border-primary focus:border-primary bg-transparent focus:bg-[#FAFAFA] disabled:bg-[#FAFAFA] duration-300"
              />
              <span className="absolute top-6 left-6">
                <ICONS.PhoneIcon />
              </span>
            </div>
          </div>
          <div className="button-wrapper mt-12">
            <button className="button-radius w-full h-16 bg-primary text-white font-medium text-md flex-center gap-x-2">
              Continue
            </button>
          </div>
        </form>
        <div className="label-wrapper mt-5 flex-center">
          <p className="text-md text-[#8C8C8C] font-medium">
            Already have an account?{" "}
            <Link to={"/"} className="text-primary font-semibold">
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BusinessAccount;
