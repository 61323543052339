import React, { useState, useEffect } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import {
  getAllAirrport,
  searchFlights,
} from "../../services/requests/travel/Travel";
import SelectInput from "../../components/inputs/SelectInput";
import toast from "react-hot-toast";
import Spinner from "../../components/spinner/Spinner";
import Select from "react-select";

interface detailsData {
  adults: string;
  cabin: string;
  departure_date: string;
  destination: string;
  origin: string;
  return_date: string;
  children: string;
  infants: string;
}

const Travels = () => {
  const [setPageName] = useOutletContext<any>();
  const navigate = useNavigate();
  const [details, setDetails] = useState<detailsData>({
    adults: "",
    cabin: "",
    departure_date: "",
    destination: "",
    origin: "",
    return_date: "",
    children: "",
    infants: "",
  });
  const [loading, setLoading] = useState(false);
  const [portList, setPortList] = useState([{ value: "", label: "" }]);
  const [data, setData] = useState<any>([]);

  React.useEffect(() => {
    setPageName("Travels");
  }, [setPageName]);

  const handleChange = (e: React.ChangeEvent<HTMLElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    setDetails((prevDetails) => ({
      ...prevDetails,
      [name]:
        (name === "adults" || name === "children" || name === "infants") &&
        value.length > 0
          ? parseInt(value, 10)
          : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    await searchFlights(details)
      .then((res) => {
        setLoading(false);
        console.log(res.data.flights);

        if (res.status === "success") {
          navigate("/dashboard/available-flight", {
            state: {
              data: res.data.flights,
              adults: Number(details.adults),
              children: Number(details.children),
              infants: Number(details.infants),
            },
          });
        } else {
          toast.error("Something went wrong" || res?.data?.message);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong" || err?.response.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllAirrport().then((res) => {
      if (res.status === "success") {
        setData(res.data);
      }
    });
  }, []);

  useEffect(() => {
    if (data) {
      const value = data.map((port: any) => ({
        value: port.city_code,
        label: `${port.city} (${port.name})`,
      }));
      setPortList(value);
    }
  }, [data]);

  const cabinOptions = [
    { value: "economy", label: "Economy" },
    { value: "premium_economy", label: "Premium Economy" },
    { value: "business", label: "Business" },
  ];

  const style = {
    control: (base: any) => ({
      ...base,
      border: "1px solid #D9D9D9",
      width: "100%",
      padding: "4px 10px",
      outline: "0",
      background: "transparent",
      borderTopLeftRadius: "0px",
      borderBottomLeftRadius: "10px",
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "0px",
      height: "64px",
      "&:focus": {
        border: "1px solid #D6AA1B",
        outline: "0",
      },
      '&[aria-expanded="true"]': {
        border: "1px solid #D6AA1B",
        outline: "0",
      },
    }),
  };

  return (
    <div>
      <div>
        <p className="text-primary text-sm font-semibold mb-6">
          Search for flight
        </p>
        <hr />
      </div>

      <div className="mt-4">
        <form onSubmit={handleSubmit}>
          <div className="flex items-center justify-between gap-8">
            <div className="w-[50%]">
              <label htmlFor="" className="font-semibold text-sm py-2">
                From
              </label>
              <Select
                options={portList}
                styles={style}
                placeholder="Select Origin"
                className="w-[100%]"
                onChange={(e: any) =>
                  setDetails((prevDetails) => ({
                    ...prevDetails,
                    origin: e.value,
                  }))
                }
              />
            </div>

            <div className="w-[50%]">
              <label htmlFor="" className="font-semibold text-sm py-2">
                To
              </label>

              <Select
                options={portList}
                styles={style}
                placeholder="Select Destination"
                className="w-[100%]"
                onChange={(e: any) =>
                  setDetails((prevDetails) => ({
                    ...prevDetails,
                    destination: e.value,
                  }))
                }
              />
            </div>
          </div>

          <div className="flex items-center justify-between gap-8">
            <div className="my-4 w-[100%]">
              <label
                className="font-semibold text-sm py-2"
                htmlFor="departure_date">
                Departure Date
              </label>

              <input
                type="date"
                value={details.departure_date}
                name="departure_date"
                onChange={handleChange}
                placeholder="Enter Depature Date"
                className="h-[64px] w-full input-radius pl-8 outline-none border border-[#D9D9D9] invalid:border-[#D9D9D9] valid:border-primary focus:border-primary bg-transparent focus:bg-[#FAFAFA] disabled:bg-[#FAFAFA] duration-300"
              />
            </div>

            <div className="my-4 w-[100%]">
              <label
                className="font-semibold text-sm py-2"
                htmlFor="return_date">
                Return Date
              </label>

              <input
                type="date"
                value={details.return_date}
                name="return_date"
                onChange={handleChange}
                placeholder="Enter Return Date"
                className="h-[64px] w-full input-radius pl-8 outline-none border border-[#D9D9D9] invalid:border-[#D9D9D9] valid:border-primary focus:border-primary bg-transparent focus:bg-[#FAFAFA] disabled:bg-[#FAFAFA] duration-300"
              />
            </div>
          </div>

          <div className="flex items-center justify-between gap-8">
            <SelectInput
              label="Select Flight Type"
              name="cabin"
              onChange={handleChange}
              options={cabinOptions}
            />

            <div className="w-full my4">
              <label className="font-semibold text-sm py-2" htmlFor="adult">
                Adults
              </label>
              <input
                type="text"
                name="adults"
                value={details.adults}
                placeholder="Enter Adult"
                onChange={handleChange}
                className="h-[64px] w-full input-radius pl-8 outline-none border border-[#D9D9D9] invalid:border-[#D9D9D9] valid:border-primary focus:border-primary bg-transparent focus:bg-[#FAFAFA] disabled:bg-[#FAFAFA] duration-300"
              />
            </div>
          </div>

          <div className="flex items-center justify-between gap-8">
            <div className="w-full my4">
              <label className="font-semibold text-sm py-2" htmlFor="children">
                Children
              </label>
              <input
                type="text"
                name="children"
                value={details.children}
                placeholder="Enter Children"
                onChange={handleChange}
                className="h-[64px] w-full input-radius pl-8 outline-none border border-[#D9D9D9] invalid:border-[#D9D9D9] valid:border-primary focus:border-primary bg-transparent focus:bg-[#FAFAFA] disabled:bg-[#FAFAFA] duration-300"
              />
            </div>

            <div className="w-full my4">
              <label className="font-semibold text-sm py-2" htmlFor="children">
                Infants
              </label>
              <input
                type="text"
                name="infants"
                value={details.infants}
                placeholder="Enter Infants"
                onChange={handleChange}
                className="h-[64px] w-full input-radius pl-8 outline-none border border-[#D9D9D9] invalid:border-[#D9D9D9] valid:border-primary focus:border-primary bg-transparent focus:bg-[#FAFAFA] disabled:bg-[#FAFAFA] duration-300"
              />
            </div>
          </div>
          <button className="button-radius w-[130px] mt-2 h-16 bg-primary text-white font-medium text-md flex-center gap-x-2">
            {loading && <Spinner color="#fff" />}
            Search
          </button>
        </form>
      </div>
    </div>
  );
};

export default Travels;
