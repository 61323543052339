export default function calculateSpendingPercentage(
  spentAmount: number,
  limitAmount: number
) {
  if (spentAmount <= 0 || limitAmount <= 0) {
    return 0; // Avoid division by zero or negative values
  }

  const percentage = (spentAmount / limitAmount) * 100;
  return Math.round(percentage > 100 ? 100 : percentage);
  // .toFixed(1);
}
