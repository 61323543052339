import React, { useState, useEffect } from "react";
import TextInput from "../../../components/inputs/TextInput";
import { CreateNewCard } from "../../../services/requests/card/CardRequest";
import Spinner from "../../../components/spinner/Spinner";
import { getRates } from "../../../services/requests/card/CardRequest";

const CreateCard = () => {
  const [formDetails, setFormDetails] = useState({
    card_type: "virtual",
    card_brand: "Visa",
    card_currency: "USD",
    title: "",
  });

  //eslint-disable-next-line
  const [rate, setRate] = useState<any>(null);
  const [dataLoading, setDataLoading] = useState({
    createCard: false,
  });

  useEffect(() => {
    const getRatesData = async () => {
      const data = await getRates();
      setRate(data.finosellRate);
    };
    getRatesData();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDataLoading((prevState: any) => ({ ...prevState, createCard: true }));
    await CreateNewCard(formDetails);
    setDataLoading((prevState: any) => ({ ...prevState, createCard: false }));
  };

  return (
    <div>
      <div className="title flex-center flex-col">
        <h3 className="text-4xl text-secondary font-bold">Add new card</h3>
      </div>

      <form
        className="input-container w-full mt-[3rem]"
        onSubmit={handleSubmit}>
        <div className="flex justify-between gap-3 items-center">
          <div className="input-wrapper w-[47%]" onChange={handleInputChange}>
            <label htmlFor="" className="font-semibold text-sm py-2">
              Card Type
            </label>
            <select
              name="card_type"
              className="h-[64px] w-full input-radius pl-8 outline-none border border-[#D9D9D9] invalid:border-[#D9D9D9] valid:border-primary focus:border-primary bg-transparent focus:bg-[#FAFAFA] disabled:bg-[#FAFAFA] duration-300">
              <option value="virtual">Virtual</option>
              {/* <option value="physical">Physical</option> */}
            </select>
          </div>

          <div className="input-wrapper w-[47%]">
            <TextInput
              value={formDetails.card_brand}
              label="Card Brand"
              name="card_brand"
              placeHolder="Card Brand"
              onChange={handleInputChange}
              readonly={true}
            />
          </div>
        </div>

        <div className="flex justify-between gap-3 items-center">
          <div className="input-wrapper w-[47%]">
            <label htmlFor="" className="font-semibold text-sm py-2">
              Card Currency
            </label>
            <select
              name="card_type"
              className="h-[64px] w-full input-radius pl-8 outline-none border border-[#D9D9D9] invalid:border-[#D9D9D9] valid:border-primary focus:border-primary bg-transparent focus:bg-[#FAFAFA] disabled:bg-[#FAFAFA] duration-300">
              <option value="USD">Dollar</option>
              {/* <option value="NGN">Naira</option> */}
            </select>
          </div>

          <div className="input-wrapper w-[47%]">
            <TextInput
              value={formDetails.title}
              label="Card Title"
              name="title"
              placeHolder="Card Title"
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* <p className="text-secondary font-medium text-sm">
          +2$ transaction charge
        </p> */}
        <p className="text-secondary font-medium text-sm">
          <ul className="flex flex-wrap list-disc gap-3">
            <li>Card creation fee: $2</li>
            <li>Monthly card maintenance fee: $1</li>
            <li>Transaction fee: 1% (min-$1 & max -$5)</li>
          </ul>
          {/* $2 on card creation * $1 monthly card maintenance * 1% transaction fee
          (min-$1 & max -$5) */}
        </p>

        <div className="button-wrapper mt-12">
          <button className="button-radius w-full h-16 bg-primary text-white font-medium text-md flex-center gap-x-2">
            {dataLoading.createCard && <Spinner color="#fff" />}
            Create Card
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateCard;
