import React from "react";
import { ICONS } from "../../assets/svgs/ICONS";
import Logo from "../../assets/svgs/dashboard-logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function DashboardSidebar() {
  let sessionStorage: any = window.sessionStorage.getItem("data");
  if (sessionStorage) {
    sessionStorage = JSON.parse(sessionStorage);
  }
  const [sideMenu] = React.useState([
    { Icon: ICONS.HomeIcon, text: "Account", href: "" },
    { Icon: ICONS.FinanceIcon, text: "Finance", href: "/expenses" },
    { Icon: ICONS.BudgetIcon, text: "Budget", href: "/budget" },
    { Icon: ICONS.CardIcon, text: "Cards", href: "/cards" },
    { Icon: ICONS.PayrollIcon, text: "Flex", href: "/payroll" },
    { Icon: ICONS.TravelIcon, text: "Travels", href: "/travel" },
    // { Icon: ICONS.ReferIcon, text: "Refer & Reward", href: "#/reward" },
    // { Icon: ICONS.HelpIcon, text: "Help", href: "#/help" },
    { Icon: ICONS.LogoutIcon, text: "Logout", href: "/" },
    // { Icon: ICONS.HomeIcon, text: "Home", href: "" },
  ]);
  const location = useLocation();
  const navigateTo = useNavigate();

  const handleLogout = () => {
    window.sessionStorage.clear();
    const t = toast.loading("Login out");
    toast.dismiss(t);
    toast.success("Logout Successfully");
    const data = window.sessionStorage.getItem("data");
    if (data) {
      const role = JSON.parse(data).role;
      if (role === "Manager") {
        navigateTo("/member-login");
        // window.location.href = "/member-login";
      } else {
        navigateTo("/login");
        // window.location.href = "/login";
      }
    }
  };

  return (
    <div className="w-[248px] sticky top-0 flex flex-col justify-between pl-[48px] pr-[36px] pt-[48px] pb-[56px] h-screen bg-white border-r border-gray-200">
      <div className="sidebar w-full">
        <nav className="h-20 flex-center flex-col  mb-5">
          <img
            width={32}
            src={
              sessionStorage?.business_image
                ? sessionStorage?.business_image
                : Logo
            }
            alt=""
          />
          <p
            title={sessionStorage?.business_name}
            className="font-semibold ml-1 mr-3">
            {/* <span>Hello</span> */}
            <span className="ml-1 text-center text-md">
              {sessionStorage?.business_name}
            </span>
          </p>
          {/* <ICONS.CaretDownIcon fill="#000000" /> */}
        </nav>
        <ul className="flex flex-col items-start justify-center h-[60vh] gap-y-[1.3rem]">
          {sideMenu.map(({ Icon, text, href }, id) => (
            <Link
              key={id}
              onClick={() => href === "/" && handleLogout()}
              to={href !== "/" ? `/dashboard${href}` : href}
              className="flex items-center gap-x-2.5">
              <Icon
                color={
                  location.pathname === `/dashboard${href}`
                    ? "#000000"
                    : "#999999"
                }
                className="w-[20px]"
              />{" "}
              <p
                className={`text-[14px] font-semibold ${
                  location.pathname === `/dashboard${href}`
                    ? "text-[#000000]"
                    : "text-[#999999]"
                }`}>
                {text}
              </p>
            </Link>
          ))}
        </ul>
      </div>

      <footer>
        <ICONS.BrandLogo />
      </footer>
    </div>
  );
}

export default DashboardSidebar;
