import React, { useState, useEffect, useCallback } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import { ICONS } from "../../assets/svgs/ICONS";
import TextInput from "../../components/inputs/TextInput";
import {
  fetchAccountNameRequest,
  fetchAllSupportedBanks,
} from "../../services/requests/account/AccountRequest";
import SelectInput from "../../components/inputs/SelectInput";
import toast from "react-hot-toast";
import Spinner from "../../components/spinner/Spinner";
import {
  editPayroll,
  getPayroll,
} from "../../services/requests/payroll/Payroll";
import { useParams } from "react-router-dom";

interface IBank {
  id?: string;
  label: string;
  value: string;
  nipCode?: string;
}

const EditPayroll = () => {
  const [setPageName] = useOutletContext<any>();
  const [details, setDetails] = useState<any>({
    // nickName: "",
    salary: "",
    bankName: "",
    accountNumber: "",
    accountName: "",
    title: "",
    bankNipCode: "",
    bankId: "",
  });
  const [bankList, setBankList] = useState<IBank[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState({
    accountNameLoader: false,
    editPayrollLoader: false,
  });
  const { id } = useParams<string>();

  React.useEffect(() => {
    setPageName("Edit Beneficiary");
  }, [setPageName]);

  useEffect(() => {
    getPayroll().then((res) => {
      if (res && res.paymentDetails) {
        const filteredRes = res.paymentDetails.filter(
          (payroll: any) => payroll._id === id
        );

        filteredRes.map((payroll: any) =>
          setDetails({
            nickName: payroll.nickName,
            salary: payroll.salary,
            bankName: payroll.bankName,
            accountNumber: payroll.accountNumber,
            accountName: payroll.accountName,
            title: payroll.title,
            bankNipCode: payroll.bankNipCode,
            bankId: payroll.bankId,
          })
        );
      }
      return;
    });
  }, [id]);

  useEffect(() => {
    fetchAllSupportedBanks().then((res) => {
      if (res) {
        const bankList =
          res.banks.data &&
          res.banks.data.map((bank: any) => ({
            label: bank.attributes.name,
            value: bank.attributes.name,
            id: bank.id,
            nipCode: bank.attributes.nipCode,
          }));
        setBankList(bankList);
      }
    });
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === "bankName" || name === "accountNumber") {
      if (name === "bankName") {
        const bank = bankList.find((bank) => bank.value === value);

        if (bank) {
          setDetails((prev: any) => ({
            ...prev,
            bankNipCode: bank.nipCode,
            bankId: bank.id,
            bankName: bank.value,
          }));
        }
      } else {
        setDetails((prev: any) => ({
          ...prev,
          [name]: value,
          accountName: "",
        }));
      }
    } else {
      setDetails((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  const fetchAccName = useCallback(async () => {
    if (!details.accountNumber) {
      toast.error("Please enter account number");
      return;
    } else if (!details.bankNipCode) {
      toast.error("Please select bank name");
      return;
    } else if (details.accountNumber.length < 10) {
      toast.error("Account number must be 10 digits");
      return;
    } else {
      setLoading((prev: any) => ({ ...prev, accountNameLoader: true }));
      try {
        await fetchAccountNameRequest({
          accountNumber: details.accountNumber,
          bankNipCode: details.bankNipCode,
          bankId: details.bankId,
        }).then((res) => {
          if (res.counterparty) {
            setDetails((prev: any) => ({
              ...prev,
              accountName: res.counterparty.attributes.accountName,
            }));
            setLoading((prev: any) => ({ ...prev, accountNameLoader: false }));
          }
        });
      } catch (error: any) {
        setLoading((prev: any) => ({ ...prev, accountNameLoader: false }));
        toast.error(
          error?.response?.data?.message ||
            "Check your credentials and try again"
        );
        console.error("Error fetching account name:", error);
      }
    }
  }, [details]);

  useEffect(() => {
    if (
      details.accountNumber.length === 10 &&
      details.bankNipCode &&
      details.bankId
    ) {
      fetchAccName();
    }
    // eslint-disable-next-line
  }, [details.accountNumber, details.bankNipCode, details.bankId]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading((prev: any) => ({ ...prev, editPayrollLoader: true }));
    if (id && details) {
      editPayroll(details, id).then((res) => {
        if (res?.status === "success") {
          setLoading((prev: any) => ({ ...prev, editPayrollLoader: false }));
          toast.success("Beneficiary updated successfully");
          navigate(-1);
        } else {
          setLoading((prev: any) => ({ ...prev, editPayrollLoader: false }));
          toast.error("Error updating beneficiary");
        }
      });
    }
  };

  return (
    <div>
      <div
        onClick={() => navigate(-1)}
        className="w-[70px] flex items-center mb-6 cursor-pointer">
        <ICONS.CaretArrowLeftIcon />
        <p>Back</p>
      </div>

      <h2 className="text-secondary text-2xl font-semibold">
        Beneficiary Details
      </h2>

      <form className="w-[80%] mt-5" onSubmit={handleSubmit}>
        {/* <TextInput
          label="Name"
          placeHolder="Enter beneficiary name"
          value={details.nickName}
          name="nickName"
          onChange={handleInputChange}
        /> */}

        <TextInput
          label="Account Number"
          placeHolder="Enter account number"
          value={details.accountNumber}
          name="accountNumber"
          onChange={handleInputChange}
        />

        <SelectInput
          label="Bank Name"
          options={bankList}
          name="bankName"
          value={details.bankName}
          onChange={handleInputChange}
        />

        <div className="relative">
          {loading.accountNameLoader && (
            <span className="absolute top-11 right-4 z-10 rounded-full flex w-6 h-6 border-2 border-primary border-t-transparent animate-spin duration-1000"></span>
          )}
          <TextInput
            label="Account Name"
            placeHolder="Enter account name"
            value={details.accountName}
            name="accountName"
            onChange={handleInputChange}
            readonly
          />
        </div>

        <TextInput
          label="Amount"
          placeHolder="Enter amount"
          value={details.salary}
          name="salary"
          onChange={handleInputChange}
        />

        <TextInput
          label="Title"
          placeHolder="Enter title"
          value={details.title}
          name="title"
          onChange={handleInputChange}
        />

        <div className="w-full flex items-center justify-start mt-5">
          <button className="h-14 bg-primary text-white text-md px-6 border-2 border-transparent flex items-center gap-x-2 font-semibold button-radius">
            {loading.editPayrollLoader && <Spinner color="#fff" />}
            save
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditPayroll;
