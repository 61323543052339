import React, { useEffect } from "react";
import { useOutletContext, useLocation, useNavigate } from "react-router-dom";
import { ICONS } from "../../assets/svgs/ICONS";
import FlightDetails from "./FlightDetails";
import BasicModal from "../../components/modal/BasicModal";

const AvailableFlight = () => {
  const [setPageName] = useOutletContext<any>();
  const [flightList, setFlightList] = React.useState<any[]>([]);
  const [id, setId] = React.useState<any>("");
  const [open, setOpen] = React.useState(false);
  const [others, setOthers] = React.useState<any>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setFlightList(location.state.data);
    setOthers(location.state);
  }, [location]);

  useEffect(() => {
    setPageName("Available Flight");
  }, [setPageName]);

  const handleOpen = (id: string, e: any) => {
    setId(id);
    setOpen(true);
    e.stopPropagation();
  };
  console.log(flightList);

  return (
    <div>
      {open && (
        <BasicModal
          open={open}
          setOpen={setOpen}
          content={<FlightDetails flightList={flightList} id={id} />}
        />
      )}

      <div
        onClick={() => navigate(-1)}
        className="w-[70px] flex items-center mb-6 cursor-pointer">
        <ICONS.CaretArrowLeftIcon />
        <p>Back</p>
      </div>
      <p className="my-8 text-primary font-medium">
        Please note that this price is subject to change when you decide to book
        the flight.
      </p>
      {flightList?.length ? (
        flightList.map((flight) => (
          <fieldset
            className="border p-4 mt-4 cursor-pointer"
            key={flight.id}
            onClick={(e) => {
              navigate(`/dashboard/flight-detail/${flight.id}`, {
                state: {
                  data: flight,
                  children: others.children,
                  infants: others.infants,
                  adults: others.adults,
                },
              });
              e.stopPropagation();
            }}>
            <legend
              className="button-radius w-[120px] p-1 bg-secondary2 text-white font-medium text-md flex-center gap-x-2 cursor-pointer"
              onClick={(e) => handleOpen(flight.id, e)}>
              Flight Details
            </legend>
            <div className="flex items-center gap-16">
              <div>
                <div className="flex items-center gap-4 mb-3">
                  <p className="text-secondary font-semibold text-sm">
                    {flight?.originName}
                  </p>
                  <ICONS.ArrowLeftRight />
                  <p className="text-secondary font-semibold text-sm">
                    {" "}
                    {flight?.destinationName}
                  </p>
                </div>
                <div className="flex items-center gap-3">
                  <p className="text-sm text-secondary2 font-medium">
                    {flight.outbound[0].airline_details.name}
                  </p>
                  <img
                    src={flight.outbound[0].airline_details.logo}
                    alt={flight.outbound[0].airline_details.code}
                    className="w-[30px] h-[30px] object-contain"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <p className="flex gap-3 text-secondary2">
                  Outbound Stop Over:{" "}
                  <span className="text-primary font-semibold">
                    {flight.outbound_stops}
                  </span>
                </p>
                <p className="flex gap-3 text-secondary2">
                  Inbound Stop Over:{" "}
                  <span className="text-primary font-semibold">
                    {flight.inbound_stops}
                  </span>
                </p>
              </div>
              <div>
                <button className="button-radius p-4 bg-primary text-white font-medium text-md flex-center gap-x-2 ">
                  {`${flight.outbound[0].cabin_type} ${
                    flight.currency
                  }${flight.amount.toLocaleString()}`}
                </button>
              </div>
            </div>
          </fieldset>
        ))
      ) : (
        <p className="text-center text-secondary font-semibold">
          No flight available
        </p>
      )}
    </div>
  );
};

export default AvailableFlight;
