import React, { useEffect, useState } from "react";
import TextInput from "../../components/inputs/TextInput";
import { formatDate } from "../../utils/formatDate";
import MembersAvatar from "../expenses/MembersAvatar";
import BasicModal from "../../components/modal/BasicModal";
import AddCard from "../expenses/AddCard";
import { useParams } from "react-router-dom";
import { fetchSingleBudget } from "../../services/requests/budget/BudgetRequest";
import { fetchExpenses } from "../../services/requests/expenses/ExpensesRequest";
import { IExpenseData } from "../../components/table/ExpensesTable";
import ExpensesTable from "../../components/table/ExpensesTable";

const BudgetDetails = () => {
  let sessionData = window.sessionStorage.getItem("data");
  const bussinessId = sessionData ? JSON.parse(sessionData).business_id : "";
  const response = fetchExpenses(bussinessId);

  const [budgetData, setBudgetData] = useState<any>(null);
  const { id } = useParams<{ id: string }>();
  const [open, setOpen] = useState<boolean>(false);
  const [expenses, setExpenses] = useState<IExpenseData[]>([]);

  useEffect(() => {
    const filteredExpenses: IExpenseData[] = [];
    if (id) {
      fetchSingleBudget(id).then((res) => {
        setBudgetData(res.budget);
      });
    }

    response?.payments.forEach((payment: any) => {
      if (payment.Itemtype === "Expenses") {
        filteredExpenses.push({
          date: formatDate(payment.createdAt),
          recipientName: payment.recipientName,
          recipientAccountNumber: payment.recipientAccountNumber,
          status: payment.status,
          amount: payment.amount,
          budgetId: payment.budgetId,
        });
      }
    });

    const matchedExpenses = filteredExpenses.filter(
      (expense) => expense?.budgetId?._id === id || expense?.budgetId === id
    );
    setExpenses(matchedExpenses);
  }, [id, response]);

  return (
    <div>
      {open && (
        <BasicModal setOpen={setOpen} open={open} content={<AddCard />} />
      )}
      {budgetData && (
        <div className="title-container mb-8 relative">
          <div className="flex items-center justify-between">
            <h4 className="mb-5 text-xl font-semibold">Budget Details</h4>
            <button
              onClick={() => setOpen(true)}
              className="h-14 bg-primary text-white text-md px-6 border-2 border-primary flex items-center gap-x-2 font-semibold button-radius">
              Add Card
            </button>
          </div>
          <div className="w-full">
            <TextInput
              label="Budget Name"
              name=""
              placeHolder="Budget Name"
              value={budgetData.title}
              disabled={true}
            />
          </div>
          <div className="w-full">
            <TextInput
              label="Description"
              name=""
              placeHolder="Description"
              value={budgetData.description}
              disabled={true}
            />
          </div>
          <div className="w-full flex items-center gap-x-10">
            <TextInput
              label="Start Date"
              name=""
              placeHolder="Start Date"
              value={formatDate(budgetData.startDate)}
              disabled={true}
            />
            <TextInput
              label="Expiry Date"
              name=""
              placeHolder="Expiry Date"
              value={formatDate(budgetData.expiryDate)}
              disabled={true}
            />
          </div>
          <div className="w-full">
            <TextInput
              label="Total Expenses"
              name=""
              placeHolder="Total Expenses"
              value={`₦ ${budgetData.totalSpent}`}
              disabled={true}
            />
          </div>
          <div className="w-full">
            <MembersAvatar
              label="Member"
              members={budgetData.authorizedMember}
            />
          </div>

          <div className="w-full my-5">
            <h4 className="mb-5 text-xl font-semibold">
              Expenses On {budgetData.title}
            </h4>
          </div>
        </div>
      )}

      {expenses?.length === 0 ? (
        <div className="w-full h-32 flex items-center justify-center">
          <p className="text-xl font-semibold text-center">
            No expenses found!
          </p>
        </div>
      ) : (
        <div className="w-full mt-5 border border-gray-300 card-radius overflow-hidden">
          {!response ? (
            <div className="p-10 flex items-center gap-x-2 justify-center">
              <span className="flex w-6 h-6 rounded-full border-2 border-primary border-t-transparent animate-spin duration-1000 justify-center"></span>{" "}
              <span className="text-sm animate-pulse">
                Fetching Expenses...
              </span>
            </div>
          ) : (
            <ExpensesTable expensesData={expenses} />
          )}
        </div>
      )}
    </div>
  );
};

export default BudgetDetails;
