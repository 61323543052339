import React, { useEffect } from "react";
import { ICONS } from "../../assets/svgs/ICONS";
import MobileInput from "../../components/inputs/MobileInput";
import toast from "react-hot-toast";
import PasswordInput from "../../components/inputs/PasswordInput";
import FormButton from "../../components/buttons/FormButton";
import { Link, useNavigate } from "react-router-dom";
import { loginRequest } from "../../services/requests/onboard-user/LoginRequest";
import axios from "axios";
interface Payload {
  phonenumber: string;
  password: string;
  account_type: string;
  platform: string;
  country: string;
}
function LoginPage() {
  const navigateTo = useNavigate();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [loginPayload, setLoginPayload] = React.useState<Payload>({
    phonenumber: "", //2348026136865, mine => 2349118952424, 08144862004
    password: "", //"Goodboy2017!", 123456, 123456
    account_type: "business",
    platform: "Web",
    country: "NG",
  });

  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        setLoginPayload({
          ...loginPayload,
          country: response.data.country || "NG",
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    // console.log(type);
    switch (name) {
      case "phonenumber":
        const mobilePattern = /^[\d]+$/;
        const isFirstDigitZero = value.slice(0, 1) === "0";
        const stripFirstZero = value.slice(1, value.length);
        const first3Digit = value.slice(0, 3);
        if (mobilePattern.test(value) || value === "") {
          if (isFirstDigitZero) {
            setLoginPayload({
              ...loginPayload,
              [name]: `234${stripFirstZero}`,
            });
          } else if ("234".includes(first3Digit)) {
            setLoginPayload({
              ...loginPayload,
              [name]: value,
            });
          } else {
            toast("Please enter a valid phone number starting with 234");
          }
        } else {
          toast("Input type not supported");
        }
        break;
      default:
        setLoginPayload({
          ...loginPayload,
          [name]: value,
        });
        break;
    }
  };

  const handleSubmit = async () => {
    // const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/;
    if (!loginPayload.phonenumber) {
      toast.error("Phone number is required");
    } else if (!loginPayload.password) {
      toast.error("Password is required");
    }
    // else if (!passwordPattern.test(loginPayload.password)) {
    //   toast.error(
    //     "Password must be 8 characters long, including 1 uppercase letter, 1 lowercase letter, and 1 symbol"
    //   );
    // }
    else {
      setIsLoading(true);
      if (!window.navigator.onLine) {
        toast.error("Check your network connection");
      } else {
        const responseData = await loginRequest(loginPayload);

        if (responseData?.status === "success") {
          toast.success("OTP sent to your email");

          navigateTo("/login-verification");
        } else {
          const { response } = responseData;
          console.log(response);

          // toast.error(response.data.message);
        }
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full h-screen bg-secondary flex-center">
      <div className="w-[703px] px-20 py-12 _h-[812px] login-box-radius bg-gray-100 rounded-2.5xl rounded-tl-none flex-center flex-col">
        <div className="top-section flex-center">
          <ICONS.BrandLogo />
        </div>
        <div className="title mt-6 flex-center flex-col">
          <h3 className="text-4xl text-secondary font-bold">Welcome back</h3>
          <p className="mt-1 text-md text-[#8C8C8C] font-medium">
            Enter your login details
          </p>
        </div>

        <div className="input-container w-full mt-16">
          <div className="input-wrapper">
            <MobileInput
              value={loginPayload.phonenumber}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-wrapper mt-5">
            <PasswordInput
              value={loginPayload.password}
              onChange={handleInputChange}
            />
          </div>
          <Link
            to={"/forget-password"}
            className="text-primary font-semibold text-md">
            Forgot Password
          </Link>
          <div className="button-wrapper mt-12">
            <FormButton
              text="Login"
              isLoading={isLoading}
              onClick={handleSubmit}
            />
          </div>
          <div className="label-wrapper mt-5 flex-center">
            <p className="text-md text-[#8C8C8C] font-medium">
              Don't have an account?{" "}
              <Link
                to={"/business-account"}
                className="text-primary font-semibold">
                Register
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
