import React from "react";
import { ICONS } from "../../../assets/svgs/ICONS";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-hot-toast";

const ChooseAccount = () => {
  const navigate = useNavigate();
  const [businessAccount, setBusinessAccount] = useState(false);
  const [click, setClick] = useState<number>(0);

  const handleClick = (index: number) => {
    setClick(index);
    if (index === 1) {
      setBusinessAccount(true);
    } else {
      toast.success("Personal Account Coming Soon");
    }
  };

  const handleContinue = () => {
    if (businessAccount) {
      navigate("/business-account");
    } else {
      toast.success("Personal Account Coming Soon");
    }
  };

  return (
    <div className="w-full h-screen bg-gray-100  flex-center">
      <div className="w-[703px] px-20 py-12 _h-[812px] login-box-radius bg-white rounded-2.5xl rounded-tl-none flex-center flex-col">
        <div className="top-section flex-center">
          <ICONS.BrandLogo />
        </div>

        <div className="title mt-6 flex-center flex-col">
          <h3 className="text-4xl text-secondary font-bold">Create Account</h3>
        </div>

        <div className="w-full mt-16 flex items-center justify-between gap-1">
          <div
            className={
              click === 0
                ? "w-[47%] outline-none border border-[#D6AA1B] p-5 rounded-tl-none cursor-pointer input-radius "
                : "w-[47%] outline-none border border-[#D9D9D9] p-5 rounded-tl-none cursor-pointer input-radius"
            }
            onClick={() => handleClick(0)}>
            <ICONS.ProfileIcon />
            <h3 className="text-secondary font-bold text-[18px] mt-3">
              Personal Account
            </h3>
            <p className="font-medium text-sm text-[#8C8C8C]">
              Duis cillum nisi
            </p>
          </div>

          <div
            className={
              click === 1
                ? "w-[47%] outline-none border border-[#D6AA1B] p-5 rounded-tl-none cursor-pointer input-radius "
                : "w-[47%] outline-none border border-[#D9D9D9] p-5 rounded-tl-none cursor-pointer input-radius"
            }
            onClick={() => handleClick(1)}>
            <ICONS.StoreIcon />
            <h3 className="text-secondary font-bold text-[18px] mt-3">
              Business Account
            </h3>
            <p className="font-medium text-sm text-[#8C8C8C]">
              Duis cillum nisi
            </p>
          </div>
        </div>

        <button
          className="button-radius w-full h-16 bg-primary text-white font-medium text-md flex-center gap-x-2 mt-[5rem]"
          onClick={handleContinue}>
          Continue
        </button>

        <p className="mt-3 text-[#8C8C8C]">
          Already have an account?{" "}
          <Link to="/" className="text-primary font-semibold text-md">
            Login
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ChooseAccount;
