import React from "react";

interface ITextareaInput {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  label: string;
  placeHolder?: string;
  name: string;
  disabled?: boolean;
}
function TextareaInput({
  onChange,
  value,
  name,
  label,
  placeHolder,
  disabled = false,
}: ITextareaInput) {
  return (
    <div className="w-full my-4">
      <label className="font-semibold text-sm py-2" htmlFor={name}>
        {label}
      </label>
      <div className="w-full relative">
        <textarea
          disabled={disabled}
          required
          value={value}
          placeholder={placeHolder}
          name={name}
          onChange={onChange}
          className="h-[100px] w-full input-radius pl-8 pt-2 outline-none border border-[#D9D9D9] invalid:border-[#D9D9D9] valid:border-primary focus:border-primary bg-transparent focus:bg-[#FAFAFA] disabled:bg-[#FAFAFA] duration-300"
        ></textarea>
      </div>
    </div>
  );
}

export default TextareaInput;
