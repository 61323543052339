import React from "react";
import "./style.css";

interface check {
  name: string;
  checked: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({ name, checked, onChange }: check) => {
  return (
    <div className="check_container">
      <input
        id="checkbox"
        className="hidden"
        name={name}
        checked={checked}
        onChange={onChange}
        type="checkbox"
      />
      <label className="checkbox" htmlFor="checkbox"></label>
    </div>
  );
};

export default Checkbox;
