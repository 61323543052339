import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ICONS } from "../../assets/svgs/ICONS";
import TextInput from "../../components/inputs/TextInput";
import SelectInput from "../../components/inputs/SelectInput";
import Spinner from "../../components/spinner/Spinner";
import { uploadBizVerificationDetails } from "../../services/requests/createAccount/BusinessVerificationRequest";

type Value = {
  identityNumber: string;
  identityType: string;
  nepa_bill: any;
  identity: any;
};

const AddBizVerificationDetails = () => {
  const [value, setValue] = useState<Value>({
    identityNumber: "",
    identityType: "",
    nepa_bill: null,
    identity: null,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const options = [
    { label: "National ID", value: "national_id" },
    { label: "National Passport", value: "international_passport" },
    { label: "Voter's Card", value: "voters_card" },
  ];

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;

    const updatedProperties = {
      [name]:
        type === "file" && name === "nepa_bill"
          ? (e.target as HTMLInputElement).files?.[0] || null
          : type === "file" && name === "identity"
          ? (e.target as HTMLInputElement).files?.[0] || null
          : value.trim(),
    };

    setValue((prevState) => ({
      ...prevState,
      ...updatedProperties,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await uploadBizVerificationDetails(value, setLoading);
  };

  return (
    <div className="w-[703px] px-20 py-12 _h-[812px] login-box-radius bg-white rounded-2.5xl rounded-tl-none flex-center flex-col">
      <Link to="/" className="top-section flex-center">
        <ICONS.BrandLogo />
      </Link>

      <div className="title mt-6 flex-center flex-col">
        <h3 className="text-4xl text-secondary font-bold">
          Add Business Details
        </h3>
        <p className="mt-1 text-md text-[#8C8C8C] font-medium">
          Use correct information about your business
        </p>
      </div>

      <div className="w-full">
        <form onSubmit={handleSubmit}>
          <div className="flex justify-between gap-3 items-center">
            <div className="input-wrapper w-[47%]">
              <SelectInput
                options={options}
                label="Identity Type"
                name="identityType"
                onChange={handleInputChange}
              />
            </div>

            <div className="input-wrapper w-[47%]">
              <TextInput
                value={value.identityNumber}
                label="Identity Card Number"
                name="identityNumber"
                placeHolder="Identity Card Number"
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="w-full">
            <div className="flex items-start justify-start w-full my-[15px] flex-col">
              <label
                htmlFor="nepa_bill"
                className="flex flex-col items-center justify-center w-full p-5 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800  hover:bg-gray-100 ">
                <p className="text-sm text-gray-500 dark:text-gray-400 m-0">
                  {value.nepa_bill
                    ? "Uploaded"
                    : "Click to Upload Utility Bill"}
                </p>

                <input
                  id="nepa_bill"
                  type="file"
                  name="nepa_bill"
                  className="hidden"
                  onChange={handleInputChange}
                />
              </label>
            </div>

            <div className="flex items-start justify-start w-full my-[15px] flex-col">
              <label
                htmlFor="identity"
                className="flex flex-col items-center justify-center w-full p-5 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800  hover:bg-gray-100 ">
                <p className=" text-sm text-gray-500 dark:text-gray-400 m-0">
                  {value.identity ? "Uploaded" : "Click to Upload ID"}
                </p>

                <input
                  id="identity"
                  type="file"
                  className="hidden"
                  name="identity"
                  onChange={handleInputChange}
                />
              </label>
            </div>
          </div>

          <div className="button-wrapper mt-8">
            <button
              disabled={loading === true}
              className="button-radius w-full h-16 bg-primary text-white font-me dium text-md flex-center gap-x-2 disabled:opacity-50">
              {loading && <Spinner color="#fff" />}
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddBizVerificationDetails;
