import React from "react";
import { ICONS } from "../../assets/svgs/ICONS";
import formatCurrency from "../../utils/formatCurrency";
export interface IVirtualCard {
  type?: number;
  balance: string;
  card_name: string;
  card_id: string;
  last_4: string;
  brand: string;
  expiryDate: string;
  card_currency?: string;
  hasLink?: boolean;
  onClick?: () => void;
  title: string;
}
function VirtualCard({
  type = 0,
  balance = "9000",
  card_currency = "USD",
  brand,
  card_id,
  card_name = "Jumoke Adetola",
  last_4 = "9018",
  expiryDate = "02/22",
  hasLink = true,
  onClick,
  title,
}: IVirtualCard) {
  return (
    <React.Fragment>
      <a
        href={hasLink ? `/dashboard/card/${card_id}` : "#"}
        className={`block w-[340px] h-[205px] card-radius ${
          type % 3 === 0 && "bg-secondary"
        } ${type % 3 === 1 && "bg-primary"} ${
          type % 3 === 2 && "bg-[#D1DBE3]"
        } p-5`}
        onClick={onClick}>
        <div className="top w-full flex items-center justify-between">
          <h4
            className={`text-3xl font-bold ${type % 3 === 0 && "text-white"} ${
              type % 3 === 1 && "text-white"
            } ${type % 3 === 2 && "text-black"}`}>
            {card_currency === "NGN" ? "₦" : "$"} {formatCurrency(balance)}
          </h4>
          <span>
            {type % 3 === 0 && <ICONS.FinosellCard1 />}
            {type % 3 === 1 && <ICONS.FinosellCard2 />}
            {type % 3 === 2 && <ICONS.FinosellCard3 />}
          </span>
        </div>
        <div className="mt-3">
          <h5
            className={`text-lg font-semibold ${
              type % 3 === 0 && "text-gray-400"
            } ${type % 3 === 1 && "text-gray-100"} ${
              type % 3 === 2 && "text-gray-600"
            }`}>
            {title ? `${card_name}\\${title}` : card_name}
          </h5>
        </div>
        <div className="mt-3">
          <h5
            className={`text-xl ${type % 3 === 0 && "text-white"} ${
              type % 3 === 1 && "text-white"
            } ${type % 3 === 2 && "text-black"} font-semibold`}>
            **** **** **** {last_4}
          </h5>
        </div>
        <div className="flex items-center justify-end gap-x-10 mt-3">
          <h5
            className={`text-sm ${type % 3 === 0 && "text-white"} ${
              type % 3 === 1 && "text-white"
            } ${type % 3 === 2 && "text-black"} font-semibold`}>
            <span
              className={`${type % 3 === 0 && "text-gray-400"} ${
                type % 3 === 1 && "text-gray-100"
              } ${type % 3 === 2 && "text-gray-600"} mr-1`}>
              Valid till
            </span>
            <span>{expiryDate}</span>
          </h5>
          {brand === "Visa" && (
            <span>
              <ICONS.MasterCardIcon1 />
            </span>
          )}
        </div>
      </a>
    </React.Fragment>
  );
}

export default VirtualCard;
