import React from "react";

const colorList = ["#FFA50077", "#FF000077", "#0000FF77", "#FF000077", "#00800077"];
function MembersAvatar({
  members,
  label,
}: {
  members: { firstName: string; lastName: string }[];
  label: string;
}) {
  return (
    <div>
      <p className="text-md font-semibold">{label}</p>
      <div className="w-full flex items-center gap-x-2 mt-2.5">
        {members.length > 5 && <span className="text-sm">5+</span>}
        <div className="flex">
          {members.map((member, index) => (
            <span
              style={{
                backgroundColor: colorList[index],
              }}
              className="flex-center bg-opacity capitalize font-bold text-xl w-12 h-12 rounded-full border border-gray-300 -mr-3"
            >
              {member.firstName.slice(0, 1)}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MembersAvatar;
