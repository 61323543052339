import React, { useEffect } from "react";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useLocation,
} from "react-router-dom";
import { ICONS } from "../../assets/svgs/ICONS";
import { DateTime } from "luxon";
import { issueTicket } from "../../services/requests/travel/Travel";
import toast from "react-hot-toast";
import Loader from "../../components/Loader/Loader";
import { fetchSubAccount } from "../../services/requests/account/AccountRequest";
import formatCurrency from "../../utils/formatCurrency";
import SelectInput from "../../components/inputs/SelectInput";

const FlightSummary = () => {
  const [setPageName] = useOutletContext<any>();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [flightDetail, setFlightDetail] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [subAccOption, setSubAccOption] = React.useState<any>([
    { value: "", label: "" },
  ]);
  const [selectedSubAccount, setSelectedSubAccount] = React.useState<any>("");
  const [accBalance, setAccBalance] = React.useState<any[]>([]);
  const [airportName, setAirportName] = React.useState<any>({});

  useEffect(() => {
    const fetchSubAccounts = async () => {
      const res = await fetchSubAccount();
      let subAccOption: { value: string; label: string }[] = [];
      let accBalance: any[] = [];
      if (res) {
        res?.accounts?.forEach((item: any) => {
          subAccOption.push({
            label: `${item.accountType} (${formatCurrency(
              item.anchor_Balance
            )})`,
            value: item.virtualAccountId,
          });
          accBalance.push(item?.anchor_Balance);
        });
        setSubAccOption(subAccOption);
        setAccBalance(accBalance);
      }
    };
    fetchSubAccounts();
  }, []);

  useEffect(() => {
    if (location.state) {
      setFlightDetail(location.state.data);
      setAirportName(location.state.portName);
    }
  }, [location]);

  React.useEffect(() => {
    setPageName("Flight Summary");
  }, [setPageName]);

  const handleBook = async () => {
    const balance = accBalance.some(
      (item) => item < flightDetail?.pricing?.payable
    );
    if (balance) {
      toast.error("Insufficient Balance");
      return;
    }
    if (id) {
      const payload = {};
      setLoading(true);
      await issueTicket(
        flightDetail.reference,
        flightDetail.pricing.payable,
        selectedSubAccount,
        payload
      ).then((res) => {
        setLoading(false);
        console.log(res);
        if (res.status === "success") {
          toast.success(res.message);
          navigate("/dashboard/success", {
            state: {
              title: "Booking Successful",
              message: "Your booking was successful",
            },
          });
        } else {
          toast.error(res?.data?.message || res?.response?.data?.message);
          return;
        }
      });
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <div
        onClick={() => navigate(-1)}
        className="w-[70px] flex items-center mb-6 cursor-pointer">
        <ICONS.CaretArrowLeftIcon />
        <p>Back</p>
      </div>

      <div>
        <p className="text-primary text-sm font-semibold mb-6">
          {" "}
          Booking Summary
        </p>
        <hr />
      </div>

      <div className="mt-6">
        <div className="flex items-center gap-4 mb-3">
          <p className="text-secondary font-semibold text-xl">
            {airportName?.originName}
          </p>
          <ICONS.ArrowLeftRight />
          <p className="text-secondary font-semibold text-xl">
            {" "}
            {airportName?.destinationName}
          </p>
        </div>
        <div className="flex gap-1 mb-2 items-center">
          <p className="text-sm text-secondary font-medium">
            {flightDetail.outbound?.[0].departure_time &&
              DateTime.fromISO(
                flightDetail.outbound?.[0].departure_time
              ).toLocaleString(DateTime.TIME_24_SIMPLE)}
            {flightDetail?.outbound?.[0]?.airport_from}
          </p>

          <p className="text-secondary font-medium">-</p>

          <p className="text-sm text-secondary font-medium">
            {flightDetail.outbound?.[0].arrival_time &&
              DateTime.fromISO(
                flightDetail.outbound?.[0].arrival_time
              ).toLocaleString(DateTime.TIME_24_SIMPLE)}
            {flightDetail?.outbound?.[0]?.airport_to}
          </p>
        </div>

        <div className="flex gap-1 items-center mb-4">
          <p className="text-secondary2 text-sm font-medium">
            {flightDetail?.outbound?.[0].departure_time &&
              DateTime.fromISO(
                flightDetail.outbound?.[0].departure_time
              ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
          </p>

          <p className="text-secondary font-medium">-</p>

          <p className="text-secondary2 text-sm font-medium">
            {flightDetail.outbound?.[0].arrival_time &&
              DateTime.fromISO(
                flightDetail.outbound?.[0].arrival_time
              ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
          </p>
        </div>

        <div className="mb-8">
          <p className="text-sm text-secondary font-medium mb-2">
            {flightDetail.outbound?.[0].cabin_type}
          </p>
          <p className="text-sm text-secondary font-medium">
            {" "}
            {flightDetail.outbound?.[0].baggage}
          </p>
        </div>

        <div className="mb-8">
          <p className="text-primary text-sm font-semibold mb-4">Summary</p>

          <hr />
          {flightDetail.price_summary?.map((item: any) => (
            <>
              <p className="mt-5 mb-2 text-sm text-secondary font-semibold">
                Passenger:{" "}
                <span className="text-secondary2 font-medium text-capitalize">
                  {item.passenger_type}
                </span>
              </p>
              <p className="text-secondary font-semibold text-sm">
                {" "}
                Qty:{" "}
                <span className="text-secondary2 font-medium">
                  {item.quantity}
                </span>
              </p>
              <p className="text-secondary font-semibold text-sm">
                {" "}
                Price:{" "}
                <span className="text-secondary2 font-medium">
                  {item.total_price.toLocaleString()}
                </span>
              </p>
            </>
          ))}
        </div>
        <div>
          <p className="text-primary text-sm font-semibold mb-4">Total Price</p>

          <hr />
          <p className="text-secondary font-semibold text-sm mt-3">
            {flightDetail.currency}
            {flightDetail?.pricing?.payable.toLocaleString()}
          </p>
        </div>
        <div>
          <h3 className="text-secondary my-5 font-bold text-2xl">
            Choose Payment Method
          </h3>
          <SelectInput
            options={subAccOption}
            name="paymentMethod"
            value={selectedSubAccount}
            label="Sub Account"
            onChange={(e: any) => setSelectedSubAccount(e.target.value)}
            required
          />
        </div>
        <button
          onClick={handleBook}
          className="button-radius w-[200px] mt-6 h-16 bg-primary text-white font-medium text-md flex-center gap-x-2">
          Issue Ticket
        </button>
      </div>
    </div>
  );
};

export default FlightSummary;
