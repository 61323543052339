import React from "react";
import Spinner from "../../../components/spinner/Spinner";
import {
  freezeCard,
  getCard,
  unFreezeCard,
} from "../../../services/requests/card/CardRequest";
import { useParams } from "react-router-dom";

interface FreezeCardProp {
  setOpen: (open: boolean) => void;
  dataLoading: any;
  freezed: boolean;
  setDataLoading: (dataLoading: any) => void;
  setFreezed: (freezed: boolean) => void;
}

const FreezeCard: React.FC<FreezeCardProp> = ({
  setOpen,
  dataLoading,
  freezed,
  setDataLoading,
  setFreezed,
}) => {
  const { id }: string | any = useParams();
  const handleFreeze = async () => {
    const action = freezed ? freezeCard : unFreezeCard;

    try {
      setDataLoading((prevState: any) => ({
        ...prevState,
        freezeCard: true,
      }));

      await action(id);

      const response = await getCard(id);
      setFreezed(response.card.data.is_active);
    } catch (error) {
      console.log(error);
    } finally {
      setDataLoading((prevState: any) => ({
        ...prevState,
        freezeCard: false,
      }));
    }
  };
  return (
    <div>
      <h1 className="text-secondary mb-1 font-bold  text-center">
        Are you sure you want to freeze your card?
      </h1>
      <p className="text-[#8C8C8C] text-center font-medium mb-5">
        {freezed
          ? "By freezing your card you won’t be able to use it for any transactions."
          : "By unfreezing your card you will be able to use it for any transactions."}
      </p>

      <div className="flex gap-5">
        <button
          onClick={() => setOpen(false)}
          className="button-radius w-full h-16 bg-transparent border border-primary text-primary font-medium text-md flex-center gap-x-2">
          Cancel
        </button>
        <button
          className="button-radius w-full h-16 bg-primary text-white font-medium text-md flex-center gap-x-2"
          onClick={handleFreeze}>
          {dataLoading.freezeCard ? (
            <Spinner color="white" />
          ) : freezed ? (
            `Freeze`
          ) : (
            `Unfreeze`
          )}
          {/* {freezeCards ? `Unfreeze` : `Freeze`} */}
        </button>
      </div>
    </div>
  );
};

export default FreezeCard;
