import React from "react";
import { ICONS } from "../../assets/svgs/ICONS";
import toast from "react-hot-toast";
import PasswordInput from "../../components/inputs/PasswordInput";
import FormButton from "../../components/buttons/FormButton";
import { Link, useNavigate } from "react-router-dom";
import { loginMemberRequest } from "../../services/requests/onboard-user/LoginRequest";
import TextInput from "../../components/inputs/TextInput";
interface Payload {
  username: string;
  password: string;
}
function MemberLoginPage() {
  const navigateTo = useNavigate();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [loginPayload, setLoginPayload] = React.useState<Payload>({
    username: "ladejobi4312", //2348026136865
    password: "ladejobi4312", //"Goodboy2017!",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setLoginPayload({
      ...loginPayload,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (!loginPayload.username) {
      toast.error("Username is required");
    } else if (!loginPayload.password) {
      toast.error("Password is required");
    } else {
      setIsLoading(true);
      if (!window.navigator.onLine) {
        toast.error("Check your network connection");
      } else {
        const responseData = await loginMemberRequest(loginPayload);
        if (responseData?.status === "success") {
          toast.success("Login Successfully");
          const token = responseData.data.token;
          const userData = responseData.data.teamMember;
          window.sessionStorage.setItem("data", JSON.stringify(userData));
          window.sessionStorage.setItem("token", token);
          navigateTo("/dashboard");
          window.location.href = "/dashboard";
        } else {
          const { response } = responseData;
          toast.error(response?.data?.error);
        }
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full h-screen bg-secondary flex-center">
      <div className="w-[703px] px-20 py-12 _h-[812px] login-box-radius bg-gray-100 rounded-2.5xl rounded-tl-none flex-center flex-col">
        <div className="top-section flex-center">
          <ICONS.BrandLogo />
        </div>
        <div className="title mt-6 flex-center flex-col">
          <h3 className="text-4xl text-secondary font-bold">Welcome back</h3>
          <p className="mt-1 text-md text-[#8C8C8C] font-medium">
            Enter your login details
          </p>
        </div>

        <div className="input-container w-full mt-16">
          <div className="input-wrapper">
            <TextInput
              label="Username"
              name="username"
              value={loginPayload.username}
              onChange={handleInputChange}
              placeHolder="Enter your username"
            />
          </div>
          <div className="input-wrapper mt-5">
            <PasswordInput
              value={loginPayload.password}
              onChange={handleInputChange}
            />
          </div>
          <div className="button-wrapper mt-12">
            <FormButton
              text="Login"
              isLoading={isLoading}
              onClick={handleSubmit}
            />
          </div>
          <div className="label-wrapper mt-5 flex-center">
            <Link
              to={"/member-forget-password"}
              className="text-primary font-semibold text-md"
            >
              Forget Password
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberLoginPage;
