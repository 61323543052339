import React, { useState } from "react";
import SearchInput from "../../components/inputs/SearchInput";
import { useLocation, useOutletContext } from "react-router-dom";
import { ICONS } from "../../assets/svgs/ICONS";
import ExpensesTable, {
  IExpenseData,
} from "../../components/table/ExpensesTable";
import formatCurrency from "../../utils/formatCurrency";
import { fetchExpenses } from "../../services/requests/expenses/ExpensesRequest";
import { formatDate } from "../../utils/formatDate";
import toast from "react-hot-toast";

const filters = ["date", "amount", "name"];

function ExpensesPage() {
  let sessionData = window.sessionStorage.getItem("data");
  const bussinessId = sessionData ? JSON.parse(sessionData).business_id : "";
  const response = fetchExpenses(bussinessId);
  // const navigateTo = useNavigate();
  const location = useLocation();
  const [activeFilter, setActiveFilter] = useState<string>(filters[0]);
  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState("");
  const [expenses, setExpenses] = useState<IExpenseData[]>([]);
  const [expensesBackup, setExpensesBackup] = useState<IExpenseData[]>([]);
  const [setPageName] = useOutletContext<any>();

  React.useEffect(() => {
    setPageName("Finance");
  }, [setPageName]);

  React.useEffect(() => {
    let formatedResponse: IExpenseData[] = [];
    response?.payments?.forEach(
      ({
        createdAt,
        amount,
        recipientName,
        recipientAccountNumber,
        status,
        _id,
      }: any) => {
        formatedResponse.push({
          date: formatDate(createdAt),
          recipientName: recipientName,
          recipientAccountNumber: recipientAccountNumber,
          status: status,
          amount: formatCurrency(amount),
          _id: _id,
        });
      }
    );

    formatedResponse.sort((a, b) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return dateB - dateA;
    });

    setExpenses(formatedResponse);
    setExpensesBackup(formatedResponse);
  }, [response, location.pathname, location.search]);

  const mouseOver = () => setShowFilterDropdown(true);
  const mouseOut = () => setShowFilterDropdown(false);

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchInput(value);

    if (value.length >= 3) {
      let matchedResult: IExpenseData[] = expensesBackup.filter(
        (eachExpenses) =>
          eachExpenses?.recipientName
            ?.toLowerCase()
            ?.includes(value?.toLowerCase()) ||
          eachExpenses?.status?.toLowerCase()?.includes(value.toLowerCase()) ||
          eachExpenses?.recipientAccountNumber?.includes(value)
      );
      setExpenses(matchedResult);
    } else {
      setExpenses(expensesBackup);
    }
  };
  const filterHandler = (filter: string) => {
    if (expenses) {
      if (filter === "date") {
        const filteredExpenses = expenses.sort((a, b) => {
          const dateA = new Date(a.date).getTime();
          const dateB = new Date(b.date).getTime();
          return dateB - dateA;
        });
        setExpenses(filteredExpenses);
      } else if (filter === "amount") {
        const filteredExpenses = expenses?.sort(
          (a, b) => parseFloat(b.amount) - parseFloat(a.amount)
        );
        setExpenses(filteredExpenses);
      } else if (filter === "name") {
        const filteredExpenses = expenses?.sort((a, b) =>
          b?.recipientName?.localeCompare(a?.recipientName)
        );
        setExpenses(filteredExpenses);
      }
      setActiveFilter(filter);
      mouseOut();
    } else {
      toast.error("Expeses list loading");
    }
  };
  // const clearFilterHandler = () => {
  //   navigateTo("/dashboard/budget");
  //   window.location.reload();
  // };

  return (
    <>
      <div>
        <div className="w-full flex items-center justify-between">
          <SearchInput
            searchInput={searchInput}
            handleSearchInput={handleSearchInput}
          />
          <div className="button-group flex items-center gap-x-4">
            <button
              onMouseOver={mouseOver}
              onMouseLeave={mouseOut}
              className="relative h-14 px-7 border-2 text-md border-gray-300 flex items-center gap-x-2 font-semibold button-radius">
              <span className="flex items-center gap-2">
                Filter <ICONS.CaretDownIcon />
              </span>
              <div
                className={`${
                  showFilterDropdown ? "flex-center" : "hidden"
                } filter-dropdown flex-col gap-0.5 w-full h-auto bg-white absolute top-[54px] left-0`}>
                {filters.map((filter) => (
                  <button
                    onClick={() => filterHandler(filter)}
                    className={`w-full h-12 input-radius hover:text-white ${
                      activeFilter === filter ? "bg-primary" : "bg-gray-50"
                    } hover:bg-primary text-sm font-normal`}>
                    By {filter}
                  </button>
                ))}
              </div>
            </button>
            {/* <button
              
              className="h-14 bg-primary text-white text-md px-6 border-2 border-primary flex items-center gap-x-2 font-semibold button-radius">
              
            </button> */}
          </div>
        </div>

        {!response ? (
          <div className="w-full h-32 flex items-center justify-center">
            <div className="p-10 flex items-center gap-x-2 justify-center">
              <div className="w-6 h-6 rounded-full border-2 border-primary border-t-transparent animate-spin duration-1000"></div>
              <p className="text-sm animate-pulse">Fetching all finances...</p>
            </div>
          </div>
        ) : !expenses?.length ? (
          <div className="w-full h-32 flex items-center justify-center">
            <p className="text-xl font-semibold text-center">
              No expenses found!
            </p>
          </div>
        ) : (
          <div className="w-full mt-5 border border-gray-300 card-radius overflow-hidden">
            <ExpensesTable expensesData={expenses} />
          </div>
        )}

        {/* {response && expenses?.length === 0 ? (
          <div className="w-full h-32 flex items-center justify-center">
            <p className="text-xl font-semibold text-center">
              No expenses found!
            </p>
          </div>
        ) : (
          <div className="w-full mt-5 border border-gray-300 card-radius overflow-hidden">
            {!response ? (
              <div className="p-10 flex items-center gap-x-2 justify-center">
                <span className="flex w-6 h-6 rounded-full border-2 border-primary border-t-transparent animate-spin duration-1000 justify-center"></span>{" "}
                <span className="text-sm animate-pulse">
                  Fetching all finances...
                </span>
              </div>
            ) : (
              <ExpensesTable expensesData={expenses} />
            )}
          </div>
        )} */}
      </div>
    </>
  );
}

export default ExpensesPage;
