import React from "react";

interface Input {
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  label?: string;
  name?: string;
  options: { label: string; value: string }[];
  disabled?: boolean;
  required?: any;
}
function SelectInput({
  onChange,
  value,
  name,
  label,
  options,
  disabled,
  required,
}: Input) {
  return (
    <div className="w-full my-4">
      <label className="font-semibold text-sm my-2">{label}</label>
      <div className="w-full relative">
        <select
          name={name}
          id=""
          disabled={disabled}
          value={value}
          onChange={onChange}
          required={required}
          className="w-full h-[64px] pl-4 border border-[#D9D9D9] input-radius px-4 py-2 outline-none focus:border-primary disabled:bg-[#FAFAFA] duration-300">
          <option value="" className="text-sm opacity-70">
            {label}
          </option>
          {options?.map(({ value, label }, id) => (
            <option key={id} value={value} className="text-sm opacity-70">
              {label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default SelectInput;
