import { PostHttp } from "../../https/PostHttp";
import toast from "react-hot-toast";

type RequestParams = {
  [key: string]: string;
};

export const saveSystemEmail = async (data: RequestParams) => {
  await PostHttp({
    pathname: "/email",
    payload: data,
    formType: "json",
  }).then((res) => {
    console.log(res);

    if (res.status === 'success') {
      toast.success("Email saved successfully");
      window.location.href = "/completed";
    } else {
      return toast.error(res?.response?.data?.message || "Error saving email");
    }
  });
};
