import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ICONS } from "../../assets/svgs/ICONS";
import SelectInput from "../../components/inputs/SelectInput";
import TextInput from "../../components/inputs/TextInput";
import Spinner from "../../components/spinner/Spinner";
import { bizBVNVerification } from "../../services/requests/createAccount/BusinessVerificationRequest";

const BVNVerification = () => {
  const [value, setValue] = useState({
    code: "",
    phone: "",
    email: "",
  });

  useEffect(() => {
    const userData = JSON.parse(window.sessionStorage.getItem("data")!);

    if (userData) {
      setValue((prevState) => ({
        ...prevState,
        phone: userData.phone_number.replace("234", "0"),
        email: userData.email,
      }));
    }
  }, []);
  const [method, setMethod] = useState("email");
  const [loading, setLoading] = useState(false);

  const options = [
    { label: "Email", value: "email" },
    { label: "SMS", value: "phone" },
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setValue((prevState) => ({
      ...prevState,
      [name]: method === "email" ? value : value.trim(),
    }));
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;

    setMethod(value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const filteredValue: { code: string; phone?: string; email?: string } = {
      code: value.code,
    };

    if (method === "phone") {
      filteredValue.phone = value.phone;
    } else if (method === "email") {
      filteredValue.email = value.email;
    }
    console.log(filteredValue);
    setLoading(true);
    await bizBVNVerification(filteredValue);
    setLoading(false);
  };

  return (
    <div className="w-[703px] px-20 py-12 _h-[812px] login-box-radius bg-white rounded-2.5xl rounded-tl-none flex-center flex-col">
      <Link to="/" className="top-section flex-center">
        <ICONS.BrandLogo />
      </Link>

      <div className="title mt-6 flex-center flex-col">
        <h3 className="text-4xl text-secondary font-bold">BVN Verification</h3>
        <p className="mt-1 text-md text-[#8C8C8C] font-medium">
          Use correct information about your business
        </p>
      </div>

      <form className="w-full" onSubmit={handleSubmit}>
        <div>
          <SelectInput
            options={options}
            label="Verification Method"
            name="method"
            onChange={handleSelectChange}
          />

          <TextInput
            value={method === "phone" ? value.phone : value.email}
            label={method === "phone" ? "Phone Number" : "Email"}
            name={method === "phone" ? "phone" : "email"}
            placeHolder={method === "phone" ? "Phone Number" : "Email"}
            onChange={handleInputChange}
          />

          <TextInput
            value={value.code}
            label="Code"
            name="code"
            placeHolder="Code"
            onChange={handleInputChange}
          />

          <div className="button-wrapper mt-8">
            <button
              disabled={loading === true}
              className="button-radius w-full h-16 bg-primary text-white font-me dium text-md flex-center gap-x-2 disabled:opacity-50">
              {loading && <Spinner color="#fff" />}
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BVNVerification;
