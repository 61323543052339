import React from "react";
import { Outlet } from "react-router-dom";

const BusinessVerificationOutlet = () => {
  return (
    <div className="w-full h-screen bg-gray-100  flex-center">
      <Outlet />
    </div>
  );
};

export default BusinessVerificationOutlet;
