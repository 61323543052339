import React, { useState, useEffect } from "react";
import { ICONS } from "../../../assets/svgs/ICONS";
import OTPInput from "../../../components/inputs/OtpInput";
import Loader from "../../../components/Loader/Loader";
import {
  sendSmsVerification,
  verifyOtpCode,
} from "../../../services/requests/createAccount/BusinessRequest";

const OtpVerification = () => {
  const [time, setTime] = useState(120);
  const [resend, setResend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    code: "",
    user: "",
    account_type: "business",
  });

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("formData")!);
    if (savedData) {
      const number = savedData.phone_number;
      setData({
        ...data,
        user: number,
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleOtpChange = async (e: string) => {
    if (e.length === 4) {
      const updatedData = { ...data, code: e };

      const interval = setInterval(() => {
        setTime((prevTimer) => prevTimer - 1);
      }, 1000);

      setTimeout(() => {
        setResend(true);
        clearInterval(interval);
      }, 2 * 60 * 1000);

      try {
        setLoading(true);
        await verifyOtpCode(updatedData);
        // clearInterval(interval)
        setLoading(false);
      } catch (error) {
        console.log(error);
      }

      return () => {
        clearInterval(interval);
      };
    }
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <>
      {loading && <Loader />}
      <div className="w-full h-screen bg-gray-100  flex-center">
        <div className="w-[703px] px-20 py-12 _h-[812px] login-box-radius bg-white rounded-2.5xl rounded-tl-none flex-center flex-col">
          <div className="top-section flex-center">
            <ICONS.BrandLogo />
          </div>

          <div className="title mt-6 flex-center flex-col">
            <h3 className="text-4xl text-secondary font-bold">Enter OTP</h3>
            <p className="mt-1 text-md text-[#8C8C8C] font-medium">
              Enter the OTP sent to your phone number ending with 5678
            </p>
          </div>

          <div className="my-[3rem]">
            <OTPInput length={4} handleOtpChange={handleOtpChange} />
          </div>

          {resend ? (
            <p
              onClick={() =>
                sendSmsVerification({ user: data.user }, setLoading)
              }
              className="text-primary font-semibold cursor-pointer font-sm">
              Resend SMS
            </p>
          ) : (
            <p className="text-secondary2 font-semibold font-sm">
              Resend SMS: {formatTime(time)}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default OtpVerification;
