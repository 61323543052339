import toast from "react-hot-toast";
import { GetHttp } from "../../https/GetHttp";

export const getTeamMembers = async () => {
  const response: any = await GetHttp({
    pathname: "/teams/team-members",
  });
  if (response) {
    if (response?.status < 200 || response?.status > 204) {
      toast.error(response?.response?.data?.message);
    }
    return response.data;
  }
};
