import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ICONS } from "../../assets/svgs/ICONS";
import TextInput from "../../components/inputs/TextInput";
import Spinner from "../../components/spinner/Spinner";
import { verifiyBizEmail } from "../../services/requests/createAccount/BusinessVerificationRequest";

const EmailVeriification = () => {
  const [value, setValue] = useState({
    code: "",
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setValue((prevState) => ({
      ...prevState,
      [name]: value.trim(),
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    console.log(value);

    setLoading(true);
    await verifiyBizEmail(value);
    setLoading(false);
  };

  return (
    <div className="w-[703px] px-20 py-12 _h-[812px] login-box-radius bg-white rounded-2.5xl rounded-tl-none flex-center flex-col">
      <Link to="/" className="top-section flex-center">
        <ICONS.BrandLogo />
      </Link>

      <div className="title mt-6 flex-center flex-col">
        <h3 className="text-4xl text-secondary font-bold">
          Email Verification
        </h3>
        <p className="mt-1 text-md text-[#8C8C8C] font-medium text-center">
          A 4 digit pin has been sent to the email attached to this phone
          number. Please check and provide the digit
        </p>
      </div>

      <form action="" className="w-full" onSubmit={handleSubmit}>
        <TextInput
          value={value.code}
          label="Digit Pin"
          name="code"
          type="number"
          placeHolder="Digit Pin"
          onChange={handleInputChange}
        />
        <div className="button-wrapper mt-8">
          <button className="button-radius w-full h-16 bg-primary text-white font-me dium text-md flex-center gap-x-2 disabled:opacity-50">
            {loading && <Spinner color="#fff" />}
            Complete Verification
          </button>
        </div>
      </form>
    </div>
  );
};

export default EmailVeriification;
