const sortByDate = (data: any[]) => {
  data.sort((a, b) => {
    const dateA = new Date(a.dateCreated as string);
    const dateB = new Date(b.dateCreated as string);
    return dateB.getTime() - dateA.getTime();
  });
  return data;
};

export default sortByDate;
