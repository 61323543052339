import axios, { AxiosRequestConfig } from "axios";
import { CONFIG } from "../../config/Config";
import { useQuery } from "react-query";

interface Get {
  pathname: string;
  payload?: any;
  isProtected?: boolean;
}

interface GetQuery {
  pathname: string;
  queryName: string;
  payload?: any;
  isProtected?: boolean;
}

export const GetHttp = async ({
  pathname,
  payload,
  isProtected = true,
}: Get) => {
  try {
    const token = window.sessionStorage.getItem("token");
    const headers: AxiosRequestConfig["headers"] = isProtected
      ? {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        }
      : { "Content-Type": "multipart/form-data" };

    const header = { headers, "Content-Type": "application/json" };
    // Converting the payload to queryString
    let data = "";
    if (payload) {
      Object.keys(payload).forEach((key, id) => {
        const value = payload[key];
        if (id === 0) {
          data = data + `${key}=${value}`;
        } else {
          data = data + `&${key}=${value}`;
        }
      });
    }
    const response = await axios.get(
      data
        ? `${CONFIG.ROOT_ADDRESS}${pathname}?${data}`
        : `${CONFIG.ROOT_ADDRESS}${pathname}`,
      header
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

export const GetHttpQuery = ({
  pathname,
  queryName,
  payload,
  isProtected = true,
}: GetQuery) => {
  const token = window.sessionStorage.getItem("token");
  const headers: AxiosRequestConfig["headers"] = isProtected
    ? {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      }
    : { "Content-Type": "multipart/form-data" };

  const header = { headers, "Content-Type": "application/json" };
  // Converting the payload to queryString
  let data = "";
  if (payload) {
    Object.keys(payload).forEach((key, id) => {
      const value = payload[key];
      if (id === 0) {
        data = data + `${key}=${value}`;
      } else {
        data = data + `&${key}=${value}`;
      }
    });
  }

  const { error, data: response } = useQuery(queryName, () => {
    return axios.get(
      data
        ? `${CONFIG.ROOT_ADDRESS}${pathname}?${data}`
        : `${CONFIG.ROOT_ADDRESS}${pathname}`,
      header
    );
  });
  if (error) {
    return error;
  } else {
    return response;
  }
};
