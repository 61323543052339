import React from "react";

function SwitchButton({
  status,
  handleSwitchToggle,
}: {
  status: boolean;
  handleSwitchToggle: () => void;
}) {
  return (
    <label
      onClick={handleSwitchToggle}
      className={`w-[50px] cursor-pointer h-7 rounded-full flex items-center ${
        status ? "bg-primary bg-opacity-10 border border-primary" : "bg-gray-200"
      } px-1 duration-300`}
    >
      <span
        className={`w-5 h-5 rounded-full ${
          status ? "bg-primary ml-[22px]" : "bg-white ml-0"
        } duration-300`}
      ></span>
    </label>
  );
}

export default SwitchButton;
