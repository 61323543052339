import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getRates,
  fundCard,
} from "../../../services/requests/card/CardRequest";
import Spinner from "../../../components/spinner/Spinner";
import usd from "../../../assets/images/us.png";
import ng from "../../../assets/images/ng.png";
import { fetchSubAccount } from "../../../services/requests/account/AccountRequest";

interface FundCardProps {
  setDataLoading: any;
  dataLoading: any;
  setOpen: (open: boolean) => void;
}

const FundCard: React.FC<FundCardProps> = ({
  setDataLoading,
  dataLoading,
  setOpen,
}) => {
  const { id } = useParams<{ id: string | any }>();
  const [rate, setRate] = useState<any>(null);
  const [formDetails, setFormDetails] = useState({
    amount: "",
    card_id: id,
    currency: "USD",
    accountType: "",
  });

  const [subAccount, setSubAccount] = useState<any[]>([]);

  useEffect(() => {
    const getRatesData = async () => {
      const data = await getRates();
      setRate(data?.finosellRate);
    };
    getRatesData();

    const fetchAccountType = async () => {
      const res = await fetchSubAccount();
      const subAcc: any[] = [];

      res?.accounts.forEach(({ accountType }: any) => {
        subAcc.push({ accountType });
      });

      setSubAccount(subAcc);
    };

    fetchAccountType();
  }, [id]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // setFormDetails((prevState) => ({
    //   ...prevState,
    //   [name]: name === "amount" ? parseFloat(value) * rate : value,
    // }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    console.log(formDetails);

    setDataLoading((prevState: any) => ({ ...prevState, fundCard: true }));
    await fundCard(formDetails);

    setDataLoading((prevState: any) => ({ ...prevState, fundCard: false }));
    setOpen(false);
  };

  return (
    <div>
      <h1 className="text-4xl text-secondary mb-1 font-bold  text-center">
        Fund your card
      </h1>

      <form className="mt-8 flex flex-col gap-8 " onSubmit={handleSubmit}>
        <div>
          <div className="relative input-wrapper">
            <label htmlFor="" className="font-semibold text-sm py-2">
              Amount
            </label>
            <img
              src={usd}
              alt=""
              className="absolute top-[46px] w-[18px] left-[15px]"
            />
            <input
              type="text"
              name="amount"
              placeholder="Enter amount"
              onChange={handleInputChange}
              className="h-[64px] w-full input-radius px-9 outline-none border border-[#D9D9D9] invalid:border-[#D9D9D9] valid:border-primary focus:border-primary bg-transparent focus:bg-[#FAFAFA] disabled:bg-[#FAFAFA] duration-300 mb3"
            />
          </div>

          <div className="relative input-wrapper">
            <label htmlFor="" className="font-semibold text-sm py-2 mt-4">
              Equivqlent
            </label>
            <img
              src={ng}
              alt=""
              className="absolute top-[46px] w-[18px] left-[15px]"
            />
            <input
              type="text"
              name="equivqlent"
              placeholder="Equivalent"
              readOnly
              value={(Number(formDetails.amount) * rate).toLocaleString()}
              className="h-[64px] w-full input-radius px-9 outline-none border border-[#D9D9D9] invalid:border-[#D9D9D9] valid:border-primary focus:border-primary bg-transparent focus:bg-[#FAFAFA] disabled:bg-[#FAFAFA] duration-300 mb-3"
            />
          </div>

          <div className="input-wrapper">
            <label htmlFor="accountType" className="font-semibold text-sm py-2">
              Sub Account
            </label>{" "}
            <select
              className="h-[64px] w-full input-radius pl-8 outline-none border border-[#D9D9D9] invalid:border-[#D9D9D9] valid:border-primary focus:border-primary bg-transparent focus:bg-[#FAFAFA] disabled:bg-[#FAFAFA] duration-300"
              id="accountType"
              name="accountType"
              required
              onChange={handleInputChange}>
              <option value="">Sub account</option>
              {subAccount.map(({ accountType }: any, id) => (
                <option key={id} value={accountType}>
                  {accountType}
                </option>
              ))}
            </select>
          </div>

          <p className="text-primary font-medium">
            1 USD = {Number(rate).toLocaleString()}NGN
          </p>
          {/* <p className="text-secondary font-medium text-sm">
            +{Number(rate * 2).toLocaleString()}$ transaction charge
          </p> */}
        </div>
        <button className="button-radius w-full h-16 bg-primary text-white font-medium text-md flex-center gap-x-2">
          {dataLoading.fundCard && <Spinner color="#fff" />}
          Fund Card
        </button>
      </form>
    </div>
  );
};

export default FundCard;
