import React from "react";
import { Modal, ModalProps } from "@mui/material";
import { ICONS } from "../../assets/svgs/ICONS";

interface IAddMoneyModal extends ModalProps {
  title?: string;
  children: React.ReactElement;
  bg?: string;
  onDismiss: () => void;
}
const AddMoneyModal: React.FC<IAddMoneyModal> = ({
  title,
  children,
  onDismiss,
  bg = "white",
  ...props
}) => {
  return (
    <Modal {...props}>
      <div
        style={{
          backgroundColor: bg,
        }}
        className="bg-white min-w-[36vw] relative card-radius p-12"
      >
        <div className="flex justify-end">
          <button
            className="bg-gray-100 card-radius absolute top-6 right-6"
            onClick={onDismiss}
          >
            <ICONS.CloseIcon />
          </button>
        </div>
        <p className="font-semibold text-center">{title}</p>
        {children}
      </div>
    </Modal>
  );
};

export default AddMoneyModal;
