import React, { useState, useEffect } from "react";
import VirtualCard, { IVirtualCard } from "./VirtualCard";
import {
  cardHolderEligibility,
  cardResponse,
  getCard,
  getCardDetails,
} from "../../services/requests/card/CardRequest";
import { useLocation } from "react-router-dom";
import Spinner from "../../components/spinner/Spinner";
import { ICONS } from "../../assets/svgs/ICONS";
import BasicModal from "../../components/modal/BasicModal";
import CardDetails from "./CardContent/CardDetails";
import FreezeCard from "./CardContent/FreezeCard";
import FundCard from "./CardContent/FundCard";
import CreateCard from "./CardContent/CreateCard";
import { fetchExpenses } from "../../services/requests/expenses/ExpensesRequest";
import { formatDate } from "../../utils/formatDate";
import CardHolder from "./CardContent/CardHolder";
import Loader from "../../components/Loader/Loader";

type CardDetailsPageProps = {
  [key: string]: any;
};

interface ITransactions {
  description: string;
  amount: string;
  status: string;
  createdAt: string;
}

function CardDetailsPage() {
  const location = useLocation();
  const id = location.pathname.split("/dashboard/card/")[1];
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [cardDetails, setCardDetails] = React.useState<IVirtualCard | any>();
  const [open, setOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [cardDetails_id, setCardDetails_id] = useState<CardDetailsPageProps>(
    {}
  );
  const [dataLoading, setDataLoading] = useState({
    cardDetails: false,
    freezeCard: false,
    fundCard: false,
    createCard: false,
  });

  const [freezed, setFreezed] = useState<any>(null);
  const sessionData = window.sessionStorage.getItem("data");
  const bussinessId = sessionData ? JSON.parse(sessionData).business_id : "";
  const cardTransRes = fetchExpenses(bussinessId);
  const [transactions, setTransactions] = useState<ITransactions[]>([]);
  const [createCardHolder, setCreateCardHolder] = useState(false);
  const [elLoading, setElLoading] = useState(false);

  const actioinButtons = [
    {
      Icon: ICONS.EyeBigIcon,
      title: "Show card details",
      description: "Reveal sensitive card information",
    },
    {
      Icon: ICONS.PauseBigIcon,
      title: `${freezed ? "Freeze card" : "Unfreeze card"}`,
      description: `${
        freezed ? "Pause all transactions on card" : "Unfreeze card"
      }`,
    },
    {
      Icon: ICONS.DeactiveateIcon,
      title: "Fund card",
      description: "Fund this card",
    },
    {
      Icon: ICONS.CreateBigIcon,
      title: "Create a new card",
      description: "Create new card",
    },
  ];

  const handleClick = async (title: string) => {
    if (title === "Show card details") {
      setOpen(true);
      setIndex(0);
    } else if (title === "Freeze card" || title === "Unfreeze card") {
      setOpen(true);
      setIndex(1);
    } else if (title === "Fund card") {
      setOpen(true);
      setIndex(2);
    } else if (title === "Create a new card") {
      setElLoading(true);
      await cardHolderEligibility();
      setElLoading(false);

      if (cardResponse === "success") {
        setOpen(true);
      } else if (cardResponse === "fail") {
        setCreateCardHolder(true);
      }
      setIndex(3);
    }
  };

  //<----------------------fetch single card details modal------------------------>
  useEffect(() => {
    const fetchCardDetails = async () => {
      if (open && index === 0) {
        setDataLoading((prevState) => ({ ...prevState, cardDetails: true }));
        const response = await getCardDetails(id);
        if (response?.card?.status === "success") {
          setCardDetails_id(response.card.data);
        }
        setDataLoading((prevState) => ({ ...prevState, cardDetails: false }));
      }
    };
    fetchCardDetails();
  }, [open, index, id]);
  //<----------------------fetch single card details modal------------------------>

  //<----------------------fetch single card details page------------------------>
  React.useEffect(() => {
    const fetchCardDetails = async () => {
      if (id) {
        setIsLoading(true);
        const response = await getCard(id);

        if (response) {
          setCardDetails(response.card);
          setFreezed(response.card.is_active);
        }
        setIsLoading(false);
      }
    };

    fetchCardDetails();
  }, [id]);
  //<----------------------fetch single card details page------------------------>

  // <----------------------fetch transactions------------------------>
  useEffect(() => {
    const fetchTransaction = async () => {
      if (cardTransRes) {
        const cardTransactions = cardTransRes.payments.filter(
          (payment: any) =>
            payment.card_id === cardDetails?.card_id &&
            payment.type === "CREDIT"
        );

        if (cardTransactions.length > 0) {
          setTransactions(cardTransactions);
          console.log(cardTransactions);
        }
      }
    };
    fetchTransaction();
  }, [cardDetails, cardTransRes]);
  // <----------------------fetch transactions------------------------>

  return (
    <>
      {elLoading && <Loader />}

      {createCardHolder && (
        <BasicModal
          open={createCardHolder}
          setOpen={setCreateCardHolder}
          content={<CardHolder />}
        />
      )}
      {open && (
        <BasicModal
          open={open}
          setOpen={setOpen}
          content={
            index === 0 ? (
              <CardDetails
                cardDetails_id={cardDetails_id}
                dataLoading={dataLoading}
              />
            ) : index === 1 ? (
              <FreezeCard
                setOpen={setOpen}
                dataLoading={dataLoading}
                setDataLoading={setDataLoading}
                freezed={freezed}
                setFreezed={setFreezed}
              />
            ) : index === 2 ? (
              <FundCard
                dataLoading={dataLoading}
                setDataLoading={setDataLoading}
                setOpen={setOpen}
              />
            ) : (
              <CreateCard />
            )
          }
        />
      )}
      <div>
        <h5 className="text-md font-semibold mb-5">
          {cardDetails?.title ? cardDetails?.title : "Virtual"} Card Details
        </h5>
        <div className="w-full grid grid-cols-2">
          <div className="left-content pr-32">
            {isLoading ? (
              <Spinner color="black" />
            ) : (
              cardDetails && (
                <VirtualCard hasLink={false} type={0} {...cardDetails} />
              )
            )}

            <div className="button-group mt-10">
              {actioinButtons.map(
                ({
                  Icon,
                  title,
                  description,
                }: {
                  Icon: React.FunctionComponent;
                  title: string;
                  description: string;
                }) => (
                  <button
                    className="w-full my-3 flex items-center border border-gray-300 card-radius h-24 p-5"
                    onClick={() => handleClick(title)}>
                    <span className="w-16">
                      <Icon />
                    </span>
                    <div className="">
                      <h5 className="text-left text-lg font-semibold">
                        {title}
                      </h5>
                      <p className="text-left text-sm text-gray-500 mt-1">
                        {description}
                      </p>
                    </div>
                  </button>
                )
              )}
            </div>
          </div>
          <div className="right-content">
            <p className="text-secondary2 font-medium mb-3">
              Transaction History
            </p>
            <div className="border">
              {isLoading ? (
                <Spinner color="#D6AA1B" />
              ) : !transactions.length ? (
                <p className="text-center font-semibold text-md mt-3 text-secondary">
                  No transactions
                </p>
              ) : (
                transactions.map((transaction, index) => (
                  <>
                    <div
                      key={index}
                      className="flex items-center justify-between px-8 py-4">
                      <div className="flex items-center gap-4">
                        <div
                          className={`${
                            transaction.status === "processing"
                              ? "bg-blue-100"
                              : transaction.status === "successful"
                              ? "bg-[#72AE1E1A]"
                              : "bg-[#B6546C1A]"
                          } w-8 h-8 input-radius flex justify-center items-center`}>
                          <div
                            className={`${
                              transaction.status === "processing"
                                ? "bg-blue-300"
                                : transaction.status === "successful"
                                ? "bg-[#72AE1E]"
                                : "bg-[#B6546C]"
                            } w-2 h-2 rounded`}></div>
                        </div>
                        <div>
                          <p className="font-semibold text-sm text-secondary">
                            {transaction.description}
                          </p>
                          <p className="text-secondary2 font-medium text-sm">
                            {transaction.status}
                          </p>
                        </div>
                      </div>
                      <div>
                        <p
                          className={`${
                            transaction.status === "processing"
                              ? "text-blue-300"
                              : transaction.status === "successful"
                              ? "text-[#72AE1E]"
                              : "text-[#B6546C]"
                          } font-semibold text-right`}>
                          ${Number(transaction.amount).toLocaleString()}
                        </p>
                        <p className="text-secondary2 font-medium text-sm">
                          {formatDate(transaction.createdAt)}
                        </p>
                      </div>
                    </div>
                    <hr />
                  </>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardDetailsPage;
