import toast from "react-hot-toast";
import { CONFIG } from "../../../config/Config";
import axios from "axios";
import { PostHttp } from "../../https/PostHttp";

const formDataReq = async (url: string, data: any) => {
  try {
    const token = window.sessionStorage.getItem("token");
    if (data.formType === "json") {
      const response = await axios.post(
        `${CONFIG.ROOT_ADDRESS}${url}`,
        data.payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } else {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        const value = data[key];
        formData.append(key, value);
      });

      const response = await axios.post(
        `${CONFIG.ROOT_ADDRESS}${url}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    }
  } catch (err: any) {
    console.log(err.response.data.message);
    handleResponse(null, err.response.data.message);
  }
};

let hasDisplayedToast = false;
const handleResponse = (response: any, error: string) => {
  if (response?.data?.status === "success") {
    return true;
  }

  if (!hasDisplayedToast) {
    let errorMessage = response?.data?.message || error || "An error occurred";

    if (error === "Invalid token. Please log in again") {
      errorMessage = "Session expired, please log in again";
      window.location.href = "/login";
    } else if (error === "An error occurred") {
      errorMessage = "An error occurred";
    }

    toast.error(errorMessage);
    hasDisplayedToast = true;
  }
  return false;
};

const sendBusinessVerificationDetails = async (
  data: any,
  setLoading: (loading: boolean) => void
) => {
  setLoading(true);
  const responses = await Promise.all(
    data.map(async (task: any) => {
      const { url, value, error } = task;

      const response = await formDataReq(url, value);
      return handleResponse(response, error);
    })
  );
  setLoading(false);

  if (responses.every((response) => response === true)) {
    toast.success("Business Details Verified Successfully");
    window.location.href = "/business/add-biz-verification-details";
    return;
  }
};

export const uploadBusinessVerificationDetails = async (
  data: any,
  setLoading: (loading: boolean) => void
) => {
  const verificationType =
    window.localStorage.getItem("verificationType") || "";
  const tasks = [
    {
      url: "/compliance/upload-cac",
      value: { cac: data.cac },
      error: "An error occured",
      success: "CAC uploaded successfully",
    },
    {
      url: "/compliance/upload-form_cac",
      value: { form_cac: data.form_cac },
      error: "An error occured",
      success: "Form CAC uploaded successfully",
    },
    {
      url: `/compliance/advanced-business-search/?registrationCode=${verificationType}`,
      value: {
        payload: { registrationNumber: data.registrationNumber },
        formType: "json",
      },
      error: "An error occured",
      success: "Business Details uploaded successfully",
    },
  ];

  sendBusinessVerificationDetails(tasks, setLoading);
};

// <!--------------------------           --------------------------!>

const bizVerificationDetails = async (url: string, data: any) => {
  try {
    const token = window.sessionStorage.getItem("token");
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];
      formData.append(key, value);
    });

    const response = await axios.post(
      `${CONFIG.ROOT_ADDRESS}${url}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
    // console.log(response);
  } catch (err) {
    console.log(err);
  }
};

const sendBizVerificationDetails = async (data: any, setLoading: any) => {
  setLoading(true);

  const responses = await Promise.all(
    data.map(async (task: any) => {
      const { url, value, error } = task;

      const response = await bizVerificationDetails(url, value);
      return handleResponse(response, error);
    })
  );
  setLoading(false);

  if (responses.every((response) => response === true)) {
    toast.success("Business Details Verified Successfully");
    window.location.href = "/business/add-bvn-details";
    return;
  }
};

export const uploadBizVerificationDetails = async (
  data: any,
  setLoading: any
) => {
  const tasks = [
    {
      url: "/compliance/upload-identity",
      value: {
        identity: data.identity,
        identityType: data.identityType,
        identityNumber: data.identityNumber,
      },
      error: "An error occured",
      success: "Identity uploaded successfully",
    },

    {
      url: "/compliance/upload-nepa_bill",
      value: { nepa_bill: data.nepa_bill },
      error: "An error occured",
      success: "Utility bill uploaded successfully",
    },
  ];

  sendBizVerificationDetails(tasks, setLoading);
};

export const bizBVNVerification = async (data: any) => {
  // if (method === "phone") {
  await PostHttp({
    pathname: "/compliance/verify-bvn-code",
    payload: data,
    formType: "json",
  }).then((res) => {
    console.log(res);
    if (res?.status === "success") {
      toast.success("BVN Verified Successfully");
      window.location.href = "/business/send-email-verification";
    } else {
      return toast.error(
        res?.response?.data?.message || "Error verifiying BVN"
      );
    }
  });
  // } else {
  //   await PostHttp({
  //     pathname: "/compliance/verify-bvn-code-email",
  //     payload: data,
  //     formType: "json",
  //   }).then((res) => {
  //     console.log(res);

  //     if (res?.status === "success") {
  //       toast.success("BVN Verified Successfully");
  //       window.location.href = "/business/send-email-verification";
  //     } else {
  //       return toast.error(
  //         res?.response?.data?.message || "Error verifiying BVN"
  //       );
  //     }
  //     console.log(res);
  //   });
  // }
};

export const verifyBVN = async (data: any, business_id: string) => {
  await PostHttp({
    pathname: `/compliance/bvn?businessid=${business_id}`,
    payload: data,
    formType: "json",
  }).then((res: any) => {
    console.log(res);
    if (res?.status === "Data Valid") {
      toast.success("BVN Verification code sent");
      window.location.href = "/business/bvn-verification";
    } else {
      return toast.error(
        res?.response?.data?.message || "Error sending verification code"
      );
    }
  });
};

export const sendBizEmailVerification = async () => {
  await PostHttp({
    pathname: "/cc/send",
    payload: {},
    formType: "json",
  }).then((res) => {
    console.log(res);

    if (res?.status === "success") {
      toast.success("Email Verification code sent");
      window.location.href = "/business/email-verification";
    } else {
      return toast.error(
        res?.response?.data?.message || "Error sending verification code"
      );
    }
  });
};

export const verifiyBizEmail = async (data: any) => {
  await PostHttp({
    pathname: "/cc/verifymail",
    payload: data,
    formType: "json",
  }).then((res) => {
    console.log(res);

    if (res?.status === "success") {
      toast.success("Email Verified Successfully");
      window.location.href = "/completed";
    } else {
      return toast.error(
        res?.response?.data?.message || "Error verifying email"
      );
    }
  });
};
