import React, { useEffect, useState } from "react";
import {
  createCardHolder,
  uploadIdentity,
} from "../../../services/requests/card/CardRequest";
import { ICONS } from "../../../assets/svgs/ICONS";
import TextInput from "../../../components/inputs/TextInput";
import Spinner from "../../../components/spinner/Spinner";

interface Cardholder {
  nin: string;
  bvn: string;
  cardType: string;
  cardNumber: string;
}

const CardHolder = () => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [showInputs, setShowInputs] = useState(false);
  const [cardholder, setCardholder] = useState<Cardholder>({
    nin: "",
    bvn: "",
    cardType: "NIGERIAN_INTERNATIONAL_PASSPORT",
    cardNumber: "",
  });
  const [loading, setLoading] = useState(false);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!!e.target && !!e.target["files"]) {
      const file = e?.target?.files[0];
      setSelectedImage(file);
    }
  };

  useEffect(() => {
    if (selectedImage) {
      uploadIdentity(selectedImage, setShowInputs);
    }
  }, [selectedImage]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;

    setCardholder((prevState) => ({
      ...prevState,
      [name]: type === "select-one" ? value : value.trim(),
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);
    await createCardHolder(cardholder);
    setLoading(false);
  };

  return (
    <div>
      <div className="title flex-center flex-col">
        <h3 className="text-3xl text-secondary font-bold">
          Card Re-Verification
        </h3>
      </div>

      <>
        <form
          className="input-container w-full mt-[10px]"
          onSubmit={handleSubmit}>
          <div className="flex justify-between gap-3 items-center">
            <div className="input-wrapper w-[47%]">
              <TextInput
                value={cardholder.nin}
                label="NIN"
                name="nin"
                placeHolder="NIN"
                onChange={handleInputChange}
              />
            </div>

            <div className="input-wrapper w-[47%]">
              <TextInput
                value={cardholder.bvn}
                label="BVN"
                name="bvn"
                placeHolder="BVN"
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="flex justify-between gap-3 items-center">
            <div className="input-wrapper w-[47%]">
              <TextInput
                value={cardholder.cardNumber}
                label="Card ID"
                name="cardNumber"
                placeHolder="Card Number"
                onChange={handleInputChange}
              />
            </div>

            <div className="input-wrapper w-[47%]">
              <label htmlFor="" className="font-semibold text-sm py-2">
                Card Type
              </label>
              <select
                onChange={handleInputChange}
                name="cardType"
                value={cardholder.cardType}
                className="h-[64px] w-full input-radius pl-8 outline-none border border-[#D9D9D9] invalid:border-[#D9D9D9] valid:border-primary focus:border-primary bg-transparent focus:bg-[#FAFAFA] disabled:bg-[#FAFAFA] duration-300">
                <option value="NIGERIAN_INTERNATIONAL_PASSPORT">
                  National Passport
                </option>
                <option value="NIGERIAN_PVC">National Voter's Card</option>
                <option value="NIGERIAN_DRIVERS_LICENSE">
                  Drivers License
                </option>
              </select>
            </div>
          </div>

          <div className="flex items-center justify-center w-full my-[15px]">
            <label
              htmlFor="dropzone-file"
              className="flex flex-col items-center justify-center w-full h-30 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800  hover:bg-gray-100 ">
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <ICONS.UploadIcon />
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold text-primary">
                    Click to upload
                  </span>{" "}
                  or drag and drop
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  SVG, PNG, JPG or GIF (MAX. 800x400px)
                </p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                className="hidden"
                onChange={handleImageChange}
              />
            </label>
          </div>

          <div className="button-wrapper mt-8">
            <button
              className="button-radius w-full h-16 bg-primary text-white font-me dium text-md flex-center gap-x-2 disabled:opacity-50"
              disabled={showInputs === false}>
              {loading && <Spinner color="#fff" />}
              Verify
            </button>
          </div>
        </form>
      </>
    </div>
  );
};

export default CardHolder;
