import toast from "react-hot-toast";
import { PostHttp } from "../../https/PostHttp";

export const loginRequest = async (payload: any) => {
  const response = await PostHttp({
    pathname: "/auths/login",
    payload: payload,
    isProtected: false,
  });
  console.log(response);
  if (
    response?.response?.status < 200 ||
    response?.response?.status > 204 ||
    response?.status !== "success"
  ) {
    toast.error(response?.response?.data?.message);
  }
  return response;
};

export const verifyLoginRequest = async (payload: any) => {
  const response = await PostHttp({
    pathname: "/auths/finish-login",
    payload: payload,
    isProtected: false,
  });
  console.log(response);
  if (response?.response?.status < 200 || response?.response?.status > 204) {
    toast.error(response?.response?.data?.message);
  }
  return response;
};

export const loginMemberRequest = async (payload: any) => {
  const response = await PostHttp({
    pathname: "/teams/team-member-login",
    payload: payload,
    isProtected: false,
    formType: "json",
  });
  console.log(response);
  if (response?.response?.status < 200 || response?.response?.status > 204) {
    toast.error(response?.response?.data?.message);
  }
  return response;
};
