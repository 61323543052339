import React, { useEffect } from "react";
import { ICONS } from "../../assets/svgs/ICONS";
import toast from "react-hot-toast";
import { verifyLoginRequest } from "../../services/requests/onboard-user/LoginRequest";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { MuiOtpInput } from "mui-one-time-password-input";
import { styled } from "@mui/material/styles";

const LoginVerification = () => {
  const [newUser, setNewUser] = React.useState<boolean>(
    false || window.sessionStorage.getItem("newUser") === "true"
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [otp, setOtp] = React.useState<string>("");

  const OTPInput = styled(MuiOtpInput)({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#7D8FB2",
        borderTopLeftRadius: "12px",
        borderBottomRightRadius: "12px",
        borderBottomLeftRadius: "12px",
      },
      "&:hover fieldset": {
        borderColor: "#D6AA1B",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D6AA1B",
      },
    },
  });

  useEffect(() => {
    const newUser = window.sessionStorage.getItem("newUser") || "";

    if (newUser === "true") {
      setNewUser(true);
    }
  }, []);

  const handleChange = async (e: string) => {
    setOtp(e);
    if (e.length === 6) {
      setIsLoading(true);
      try {
        await verifyLoginRequest({ otp: e }).then((res) => {
          setIsLoading(false);
          if (res?.success) {
            toast.success("Login Successful");
            const token = res.token;
            const userData = res.message;

            window.sessionStorage.setItem("token", token);
            window.sessionStorage.setItem("data", JSON.stringify(userData));

            if (newUser) {
              window.location.href = "/business/business-verification";

              setTimeout(() => {
                window.sessionStorage.removeItem("newUser");
                window.localStorage.removeItem("fromData");
              }, 2000);
            } else {
              window.location.href = "/dashboard";
            }
          }
          console.log(res);
        });

        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="w-full h-screen bg-secondary flex-center">
        <div className="w-[703px] px-20 py-12 _h-[812px] login-box-radius bg-gray-100 rounded-2.5xl rounded-tl-none flex-center flex-col">
          <div className="top-section flex-center">
            <ICONS.BrandLogo />
          </div>

          <div className="title mt-6 flex-center flex-col">
            <h3 className="text-2xl text-secondary font-bold">
              2 Factor Authentication
            </h3>
            <p className="mt-1 text-md text-[#8C8C8C] font-medium">
              Enter the 2FA sent to email address
            </p>
          </div>

          <div className="input-container w-full mt-5">
            <div className="input-wrapper">
              <OTPInput onChange={handleChange} length={6} value={otp} />
              {/* <OTPInput length={6} handleOtpChange={handleChange} /> */}
            </div>
          </div>

          <div className="label-wrapper mt-5 flex-center">
            <p className="text-md text-[#8C8C8C] font-medium">
              Having issues?{" "}
              <Link to={"/"} className="text-primary font-semibold">
                Login Again
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginVerification;
