import axios, { AxiosRequestConfig } from "axios";
import { CONFIG } from "../../config/Config";

interface Post {
  pathname: string;
  payload: any;
  isProtected?: boolean;
  formType?: string;
}

export const PostHttp = async ({
  pathname,
  payload,
  isProtected = true,
  formType,
}: Post) => {
  const formData = new FormData();
  try {
    const token = window.sessionStorage.getItem("token");
    const headers: AxiosRequestConfig["headers"] = isProtected
      ? {
          Authorization: `Bearer ${token}`,
          "Content-Type":
            formType === "json" ? "application/json" : "multipart/form-data",
        }
      : {
          "Content-Type":
            formType === "json" ? "application/json" : "multipart/form-data",
        };

    const header = { headers, "Content-Type": "application/json" };
    // Convert payload to formdata
    Object.keys(payload).forEach((key) => {
      const value = payload[key];
      formData.append(key, value);
    });
    // *************
    if (formType?.toLocaleLowerCase() === "json") {
      const response = await axios.post(
        `${CONFIG.ROOT_ADDRESS}${pathname}`,
        payload,
        header
      );
      return response.data;
    } else {
      const response = await axios.post(
        `${CONFIG.ROOT_ADDRESS}${pathname}`,
        formData,
        header
      );
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
