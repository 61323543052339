import React from "react";
import { ICONS } from "../../assets/svgs/ICONS";
import TabOne from "./TabOne";
import toast from "react-hot-toast";
import { resetMemberPassword } from "../../services/requests/onboard-user/ForgetPwdRequest";
interface Payload {
  username: string;
}
function MemberForgetPwdPage() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [forgetPwdPayload, setForgetPwdPayload] = React.useState<Payload>({
    username: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForgetPwdPayload({
      ...forgetPwdPayload,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!forgetPwdPayload.username) {
      toast.error("Username is required");
    } else {
      const response = await resetMemberPassword(forgetPwdPayload);
      if (response.status === "success") {
        toast.success(response.message);
        setForgetPwdPayload({
          username: "",
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <div className="w-full h-screen bg-[#FAFAFA] flex-center">
      <div className="w-[703px] px-20 py-12 _h-[812px] login-box-radius bg-white border border-gray-100 rounded-2.5xl rounded-tl-none flex-center flex-col">
        <div className="top-section flex-center">
          <ICONS.BrandLogo />
        </div>
        <TabOne
          isMember={true}
          forgetPwdPayload={forgetPwdPayload}
          handleInputChange={handleInputChange}
          handleNextTab={handleSubmit}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default MemberForgetPwdPage;
