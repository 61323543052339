import React, { useEffect } from "react";
import { Link, useLocation, useOutletContext } from "react-router-dom";
import { ICONS } from "../../assets/svgs/ICONS";
import TabOne from "./add-budget-tab/TabOne";
import TabThree from "./add-budget-tab/TabThree";
import TabTwo from "./add-budget-tab/TabTwo";
import toast from "react-hot-toast";
import {
  editBudget,
  fetchSingleBudget,
} from "../../services/requests/budget/BudgetRequest";
import { convertToInputDateFormat } from "../../utils/formatDate";
import TabSuccess from "./add-budget-tab/TabSuccess";

function EditBudgetPage() {
  const location = useLocation();
  const id = location.pathname.split("/dashboard/budget/")[1];
  const [setPageName] = useOutletContext<any>();
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  const [editBudgetPayload, setEditBudgetPayload] = React.useState({
    id: "",
    title: "",
    limit: "",
    type: "",
    startDate: "",
    expiryDate: "",
    authorizedMember: [""],
    description: "",
  });

  useEffect(() => {
    const fetchSingleBudgetDetails = async () => {
      const budgetResponse = await fetchSingleBudget(id);
      const budgetData = budgetResponse?.budget;

      let _authorizedMember: any = [];
      budgetData.authorizedMember.forEach(
        ({
          _id,
          firstName,
          lastName,
        }: {
          _id: string;
          firstName: string;
          lastName: string;
        }) => {
          _authorizedMember.push({
            value: _id,
            label: `${firstName} ${lastName}`,
          });
        }
      );

      setEditBudgetPayload({
        id: budgetData?._id,
        title: budgetData?.title,
        limit: budgetData?.limit,
        type: budgetData?.type,
        startDate: convertToInputDateFormat(budgetData?.createdAt),
        expiryDate: convertToInputDateFormat(budgetData?.expiryDate),
        authorizedMember: [_authorizedMember[0].value],
        description: budgetData?.description,
      });
    };
    fetchSingleBudgetDetails();
  }, [id]);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setEditBudgetPayload({
      ...editBudgetPayload,
      [name]: value,
    });
  };

  const continueToPreviewTab = () => {
    if (!editBudgetPayload.type) {
      toast.error("Budget type is required");
    } else if (!editBudgetPayload.title) {
      toast.error("Budget title is required");
    } else if (!editBudgetPayload.limit) {
      toast.error("Budget limit is required");
    } else if (!editBudgetPayload.startDate) {
      toast.error("Budget start date is required");
    } else if (!editBudgetPayload.expiryDate) {
      toast.error("Budget expiry date is required");
    } else {
      setActiveTabIndex(2);
    }
  };

  const editBudgetHandler = async () => {
    if (!editBudgetPayload.type) {
      toast.error("Budget type is required");
    } else if (!editBudgetPayload.title) {
      toast.error("Budget title is required");
    } else if (!editBudgetPayload.limit) {
      toast.error("Budget limit is required");
    } else if (!editBudgetPayload.startDate) {
      toast.error("Budget start date is required");
    } else if (!editBudgetPayload.expiryDate) {
      toast.error("Budget expiry date is required");
    } else {
      const responseData = await editBudget(editBudgetPayload);
      if (
        (responseData?.code >= 200 && responseData?.code <= 204) ||
        responseData?.status === "success"
      ) {
        toast.success("Budget Successfully Edited");
        setActiveTabIndex(3);
        // navigateTo("/dashboard/budget");
      } else if (responseData.response.data.status === "fail") {
        const { response } = responseData;
        toast.error(response.data.message);
      } else {
        const { response } = responseData;
        toast.error(response.data.error);
      }
    }
  };

  useEffect(() => {
    setPageName("Edit Budget");
  }, [setPageName]);

  const tabMenu = [
    { id: 0, onClick: setActiveTabIndex, text: "Select type of Spend" },
    { id: 1, onClick: setActiveTabIndex, text: "Budget Details" },
    { id: 2, onClick: setActiveTabIndex, text: "Review" },
  ];

  return (
    <div className="">
      <div className="top w-full flex flex-col">
        <Link to={"/dashboard/budget"} className="flex items-center gap-x-2">
          <ICONS.CaretArrowLeftIcon />
          <p className="font-semibold text-md text-gray-600">Back</p>
        </Link>
        <h5 className="font-bold text-gray-800 text-md my-4">
          What type of Budget Do you Need.
        </h5>
        {activeTabIndex < tabMenu.length && (
          <div className="tab-container border-b-2 border-gray-200 flex items-center gap-x-2 mt-4">
            {tabMenu.map(({ id, onClick, text }) => (
              <button
                key={id}
                onClick={() => onClick(id)}
                className={`h-9 text-sm px-2 font-semibold flex items-start border-b-2 -mb-0.5 ${
                  activeTabIndex === id
                    ? "border-primary text-primary"
                    : "text-gray-600 border-transparent"
                } `}
              >
                {text}
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="tab-container py-5">
        {activeTabIndex === 0 && (
          <TabOne
            value={editBudgetPayload.type}
            handleInputChange={handleInputChange}
          />
        )}
        {activeTabIndex === 1 && (
          <TabTwo
            payload={editBudgetPayload}
            handleInputChange={handleInputChange}
            onSubmit={continueToPreviewTab}
          />
        )}
        {activeTabIndex === 2 && (
          <TabThree payload={editBudgetPayload} onSubmit={editBudgetHandler} />
        )}

        {activeTabIndex === 3 && (
          <TabSuccess
            data={{
              title: "Completed",
              description: "Your Budget has been edited successfully",
            }}
          />
        )}
      </div>
    </div>
  );
}

export default EditBudgetPage;
