import React from "react";
import { Modal } from "@mui/material";

interface ModalProp {
  open: boolean;
  handleVerificationType: (index: number) => any;
}

const BusinessType: React.FC<ModalProp> = ({
  open,
  handleVerificationType,
}) => {

  return (
    <Modal open={open}>
      <div
        style={{
          backgroundColor: "#fff",
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          borderRadius: "0px 21px 21px 21px",
        }}
        className="bg-white card-radius p-12">
        <div className="flex justify-end">
          {/* <button
            type="button"
            onClick={handleClose}
            className="bg-gray-100 card-radius absolute top-6 right-6 flex justify-end">
            <ICONS.CloseIcon />
          </button> */}
        </div>

        <p className="font-semibold text-center text-secondary">
          What type of CAC Document do you have
        </p>

        <div className="flex flex-col justify-center items-center gap-6 mt-6">
          <button
            className="button-radius w-full h-16 bg-transparent text-primary border-primary border font-medium text-md flex-center gap-x-2 hover:bg-primary hover:text-white"
            onClick={() => handleVerificationType(0)}>
            Business name registration
          </button>

          <button
            className="button-radius w-full h-16 bg-transparent text-primary border-primary border font-medium text-md flex-center gap-x-2 hover:bg-primary hover:text-white"
            onClick={() => handleVerificationType(1)}>
            Company registration
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default BusinessType;
