import React, { useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useOutletContext,
} from "react-router-dom";
import { ICONS } from "../../assets/svgs/ICONS";
import { confirmPrice } from "../../services/requests/travel/Travel";
import toast from "react-hot-toast";
import Loader from "../../components/Loader/Loader";

const FlightDetailPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [flight, setFlight] = React.useState<any>({});
  const [others, setOthers] = React.useState<any>(null);
  const [setPageName] = useOutletContext<any>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [name, setName] = React.useState<any>({
    originName: "",
    destinationName: "",
  });

  useEffect(() => {
    if (id) {
      setLoading(true);
      confirmPrice(id).then((res) => {
        if (res.status === "success") {
          setFlight(res.data);
          setLoading(false);
        } else {
          toast.error(res.response.data.message || "An error occured");
          setLoading(false);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (location.state) {
      setFlight(location.state.data);
      setName({
        originName: location.state.data.originName,
        destinationName: location.state.data.destinationName,
      });
      setOthers(location.state);
    }
  }, [location]);

  React.useEffect(() => {
    setPageName("Flight Detail");
  }, [setPageName]);

  return (
    <div>
      {loading && <Loader />}
      <div
        onClick={() => navigate(-1)}
        className="w-[70px] flex items-center mb-6 cursor-pointer">
        <ICONS.CaretArrowLeftIcon />
        <p>Back</p>
      </div>

      <div>
        <p className="text-primary text-sm font-semibold mb-6">
          {" "}
          {flight.outbound?.[0].airline_details.name}
        </p>
        <hr />
      </div>

      <div className="mt-4">
        <div className="flex items-center gap-4 mb-3">
          <p className="text-secondary font-semibold text-xl">
            {name?.originName}
          </p>
          <ICONS.ArrowLeftRight />
          <p className="text-secondary font-semibold text-xl">
            {" "}
            {name?.destinationName}
          </p>
        </div>
        <div className="border mt-8 max-w-[450px]">
          <div className="bg-primary h-20 flex items-center justify-start pl-6 text-white">{`${
            flight.outbound?.[0]?.cabin_type
          }  ${flight?.currency}${flight?.amount?.toLocaleString()}`}</div>

          <div className="py-10 pl-6 pr-3">
            <p className="mt-3 text-secondary font-semibold">
              Flight Number:{" "}
              <span className="text-secondary2 font-medium">
                {flight.outbound?.[0].flight_number}
              </span>
            </p>
            <p className="mt-3 text-secondary font-semibold">
              Price:{" "}
              <span className="text-secondary2 font-medium">
                {flight?.amount?.toLocaleString()}
              </span>
            </p>
            <p className="mt-3 text-secondary font-semibold">
              Baggage:{" "}
              <span className="text-secondary2 font-medium">
                {flight.outbound?.[0].baggage}
              </span>
            </p>
            <p className="mt-3 text-secondary font-semibold">
              Meal:{" "}
              <span className="text-secondary2 font-medium">
                {flight.outbound?.[0].airline_details.name}
              </span>
            </p>
            <p className="mt-3 text-secondary font-semibold">
              TV:{" "}
              <span className="text-secondary2 font-medium">
                {flight.outbound?.[0].airline_details.name}
              </span>
            </p>
            <button
              onClick={() =>
                navigate(`/dashboard/book-flight/${id}`, {
                  state: {
                    data: flight,
                    children: others.children,
                    infants: others.infants,
                    adults: others.adults,
                    portName: name,
                  },
                })
              }
              className="button-radius p-4 bg-primary text-white font-medium text-md flex-center gap-x-2 mt-5 justify-center w-[200px]">
              Select
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightDetailPage;
