import { GetHttp } from "../../https/GetHttp";
import { PostHttp } from "../../https/PostHttp";

type PayloadData = {
  [key: string]: any;
};

export const searchFlights = async (payload: PayloadData) => {
  let query = `cabin=${payload.cabin}&departure_date=${payload.departure_date}&return_date=${payload.return_date}&destination=${payload.destination}&origin=${payload.origin}&`;

  if (payload.children) {
    query += `children=${payload.children}&`;
  }
  if (payload.infants) {
    query += `infants=${payload.infants}&`;
  }
  if (payload.adults) {
    query += `adults=${payload.adults}&`;
  }

  const res = await GetHttp({
    pathname: `/flight/search-flights/?${query}`,
  });
  return res;
};

export const confirmPrice = async (payload: string) => {
  const res = await GetHttp({
    pathname: `/flight/confirm-price/${payload}`,
  });
  return res;
};

export const bookFlight = async (id: any, payload: any) => {
  const res = await PostHttp({
    pathname: `/flight/book-flight/${id}`,
    payload,
    formType: "json",
  });
  return res;
};

export const issueTicket = async (
  reference: string,
  price: string,
  accId: string,
  payload: PayloadData
) => {
  const res = await PostHttp({
    pathname: `/flight/issue-ticket/${reference}?virtualAccountId=${accId}&flight_price=${price}`,
    payload,
  });

  return res;
};

export const getAllAirrport = async () => {
  const res = await GetHttp({
    pathname: `/flight/airports`,
  });

  return res;
};
