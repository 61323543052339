import toast from "react-hot-toast";
import { GetHttpQuery } from "../../https/GetHttp";
// import { PostHttp } from "../../https/PostHttp";
// import { PatchHttp } from "../../https/PatchHttp";

export const fetchExpenses = (bussinessId: string) => {
  const response: any = GetHttpQuery({
    pathname: `/payment/getpayments?business_id=${bussinessId}`,
    queryName: "paymentData",
  });

  if (response) {
    if (response?.status < 200 || response?.status > 204) {
      toast.error(response?.response?.data?.message);
    }
    // console.log(response, "response.data");
    return response.data;
  }
};

// export const fetchSingleBudget = async(budgetId: string) => {
//   const response: any = await GetHttp({
//     pathname: `/budget/${budgetId}`,
//   });
//   if (response) {
//     if (response?.status < 200 || response?.status > 204) {
//       toast.error(response?.response?.data?.message);
//     }
//     return response.data;
//   }
// };

// export const createBudget = async (payload: any) => {
//   const response = await PostHttp({
//     pathname: "/budget/create",
//     payload: payload,
//   });

//   if (response?.response?.status < 200 || response?.response?.status > 204) {
//     toast.error(response?.response?.data?.message);
//   }
//   return response;
// };

// export const editBudget = async (payload: any) => {
//   const response = await PatchHttp({
//     pathname: `/budget/${payload.id}`,
//     payload: payload,
//   });

//   if (response?.response?.status < 200 || response?.response?.status > 204) {
//     toast.error(response?.response?.data?.message);
//   }
//   return response;
// };
