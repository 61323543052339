import toast from "react-hot-toast";
import { GetHttp } from "../../https/GetHttp";
import { PatchHttp } from "../../https/PatchHttp";
import { PostHttp } from "../../https/PostHttp";

type value = {
  [key: string]: any;
};

export let cardResponse: any = "";
export let loadingElgibility: boolean = false;

export const getCards = async () => {
  const response: any = await GetHttp({
    pathname: "/cards/cards",
  });
  if (response) {
    if (response?.status < 200 || response?.status > 204) {
      toast.error(response?.response?.data?.message);
    }
    return response.data;
  }
};

export const getCard = async (id: string) => {
  const response: any = await GetHttp({
    pathname: `/cards/card-full/${id}`,
  });
  if (response) {
    if (response?.status < 200 || response?.status > 204) {
      toast.error(response?.response?.data?.message);
    }
    return response.data;
  }
};

export const getRates = async () => {
  const response: any = await GetHttp({
    pathname: "/cards/fetch-exchange",
  });
  if (response) {
    if (response?.status < 200 || response?.status > 204) {
      toast.error(response?.response?.data?.message);
    }
    return response.data;
  }
};

export const getCardDetails = async (id: string) => {
  const response: any = await GetHttp({
    pathname: `/cards/card-details-full?cardId=${id}`,
  });
  if (response) {
    if (response?.status < 200 || response?.status > 204) {
      toast.error(response?.response?.data?.message);
    }
    return response.data;
  }
};

export const freezeCard = async (id: string) => {
  await GetHttp({
    pathname: `/cards/freeze-card?cardId=${id}`,
  }).then((res) => {
    if (
      res?.response?.data?.status === "fail" ||
      res?.response?.data?.status === "error"
    ) {
      toast.error(res?.response?.data?.message);
    } else if (res?.status === "success") {
      getCard(id);
      toast.success(res?.message);
      return res.data;
    }
    return res.data;
  });
};

export const unFreezeCard = async (id: string) => {
  await GetHttp({
    pathname: `/cards/unfreeze-card?cardId=${id}`,
  }).then((res) => {
    if (
      res?.response?.data?.status === "fail" ||
      res?.response?.data?.status === "error"
    ) {
      toast.error(res?.response?.data?.message);
    } else if (res?.status === "success") {
      getCard(id);
      toast.success(res?.message || "Card Unfreezed Successfully");
      return res.data;
    }
    return res.data;
  });
};

export const fundCard = async (data: value) => {
  await PatchHttp({
    pathname: `/cards/fund-card`,
    payload: data,
    formType: "json",
  }).then((res) => {
    console.log(res);
    if (
      res?.response?.data?.status === "error" ||
      res?.response?.data?.status === "fail"
    ) {
      toast.error(res?.response?.data?.message);
    } else if (
      res?.response?.data?.status === "success" ||
      res?.status === "success"
    ) {
      toast.success(
        res?.response?.data?.message ||
          "Card Funded Successfully" ||
          res?.message
      );
      getCard(data.card_id);
      window.location.reload();
    }
  });
};

export const CreateNewCard = async (data: value) => {
  await PostHttp({
    pathname: `/cards/create-card`,
    payload: data,
    formType: "json",
  }).then((res) => {
    console.log(res);
    if (
      res?.response?.data?.status === "fail" ||
      res?.response?.data?.status === "error"
    ) {
      toast.error(res?.response?.data?.message);
    } else if (
      res?.response?.data?.status === "success" ||
      res?.status === "success"
    ) {
      getCards();
      toast.success(
        res?.response?.data?.message ||
          "Card Created Successfully" ||
          res?.message
      );
      window.location.href = "/dashboard/cards";
    }
  });
};

export const assignCardToBudget = async (
  card_id: value | string,
  budget_id: value | string | any
) => {
  const response = await GetHttp({
    pathname: `/cards/assign-card-to-budget?cardId=${card_id}&budgetId=${budget_id}`,
  });

  return response;
};

export const cardHolderEligibility = async () => {
  try {
    setLoading(true);

    await GetHttp({
      pathname: `/cards/eligibility`,
    }).then((res) => {
      setLoading(false);
      if (res.status === "success") {
        setResponse("success");
      } else if (
        res?.response?.data?.status === "fail" ||
        res?.response?.data?.status === "error"
      ) {
        if (res?.response?.data?.message === "No cardholder found") {
          setResponse("fail");
        }
        toast.error(res?.response?.data?.message || "An error occured");
      }
    });
  } finally {
    setLoading(false);
  }
};

export const uploadIdentity = async (
  data: File,
  setShowInputs: (value: boolean) => void
) => {
  try {
    await PostHttp({
      pathname: `/compliance/upload-identity`,
      payload: {
        identity: data,
      },
    }).then((res) => {
      console.log(res);
      if (res.status === "success") {
        setShowInputs(true);
      } else {
        toast.error(res?.response?.data?.message || "An error occured");
        setShowInputs(false);
      }
    });
  } finally {
    setLoading(false);
  }
};

export const createCardHolder = async (data: value) => {
  try {
    await PostHttp({
      pathname: `/cards/create-cardholder-old-users`,
      payload: data,
      formType: "json",
    }).then((res) => {
      console.log(res);
      if (res.status === "success") {
        toast.success(res?.message);
        window.location.reload();
      } else if (
        res?.response?.data?.status === "fail" ||
        res?.response?.data?.status === "error"
      ) {
        toast.error(res?.response?.data?.message || "An error occured");
      }
    });
  } catch (error) {}
};

const setResponse = (res: any) => {
  cardResponse = res;
};

const setLoading = (res: boolean) => {
  loadingElgibility = res;
};
