import React, { useEffect, useState } from "react";
import { ICONS } from "../../assets/svgs/ICONS";
import { Link } from "react-router-dom";
import TextInput from "../../components/inputs/TextInput";
import Spinner from "../../components/spinner/Spinner";
import { sendBizEmailVerification } from "../../services/requests/createAccount/BusinessVerificationRequest";

const SendEmailVerification = () => {
  const [value, setValue] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userData = JSON.parse(window.sessionStorage.getItem("data")!);

    setValue((prevState) => ({
      ...prevState,
      email: userData.email,
    }));
  }, []);

  const handleVerify = async () => {
    setLoading(true);
    await sendBizEmailVerification();
    setLoading(false);
  };

  return (
    <div className="w-[703px] px-20 py-12 _h-[812px] login-box-radius bg-white rounded-2.5xl rounded-tl-none flex-center flex-col">
      <Link to="/" className="top-section flex-center">
        <ICONS.BrandLogo />
      </Link>

      <div className="title mt-6 flex-center flex-col">
        <h3 className="text-4xl text-secondary font-bold">
          Email Verification
        </h3>
        <p className="mt-1 text-md text-[#8C8C8C] font-medium">
          You need to verify your email address to continue
        </p>
      </div>

      <TextInput
        label="Email"
        name="email"
        value={value.email}
        // disabled
        placeHolder="Email"
      />

      <div className="button-wrapper w-full mt-8">
        <button
          onClick={handleVerify}
          disabled={loading === true}
          className="button-radius w-full h-16 bg-primary text-white font-me dium text-md flex-center gap-x-2 disabled:opacity-50">
          {loading && <Spinner color="#fff" />}
          Continue
        </button>
      </div>
    </div>
  );
};

export default SendEmailVerification;
