import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import React from "react";
import { ICONS } from "../../assets/svgs/ICONS";

interface BasicModalProp {
  content: React.ReactNode | null;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const BasicModal: React.FC<BasicModalProp> = ({ content, open, setOpen }) => {
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: "0px 21px 21px 21px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <>
          <button onClick={handleClose} className="bg-gray-100 card-radius absolute top-6 right-6 flex justify-end">
            <ICONS.CloseIcon />
          </button>
          <Box sx={style}>{content}</Box>
        </>
      </Modal>
    </div>
  );
};

export default BasicModal;
