import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ICONS } from "../../assets/svgs/ICONS";
import { formatDate } from "../../utils/formatDate";
import { Link } from "react-router-dom";

export interface PayrollData {
  bankName: string;
  accountNumber: string;
  salary: string;
  accountName: string;
  createdAt: string;
  recipientAccountNumber: string;
  recipientName: string;
  amount: number;
  _id: string;
}

interface Props {
  data: PayrollData[];
  tableType: string;
  removeBeneficiary?: (id: string) => void;
  deleteBeneficiary?: (id: string) => void;
}

const PayrollTable: React.FC<Props> = ({
  data,
  tableType,
  removeBeneficiary,
  deleteBeneficiary,
}) => {
  const [dataValue, setDataValue] = React.useState(data);

  React.useEffect(() => {
    setDataValue(data);
  }, [data]);

  return (
    <TableContainer className="h-[62vh]">
      {" "}
      <Table aria-label="collapsible table" className=" rounded-xl">
        <TableHead>
          <TableRow>
            {tableType === "payrollHistory" && (
              <TableCell align="left">
                <span className="text-gray-500 font-semibold">Date</span>
              </TableCell>
            )}
            <TableCell align="left">
              <span className="text-gray-500 font-semibold">Name</span>
            </TableCell>
            <TableCell align="left">
              <span className="text-gray-500 font-semibold">Bank Name</span>
            </TableCell>
            <TableCell align="left">
              <span className="text-gray-500 font-semibold">
                Account Number
              </span>
            </TableCell>
            <TableCell align="left">
              <span className="text-gray-500 font-semibold">Amount</span>
            </TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataValue.map((data: PayrollData, index: number) => (
            <TableRow key={index}>
              {tableType === "payrollHistory" && (
                <TableCell>{formatDate(data.createdAt)}</TableCell>
              )}
              <TableCell>
                {tableType === "payrollHistory"
                  ? data.recipientName
                  : data.accountName}
              </TableCell>
              <TableCell>{data.bankName}</TableCell>
              <TableCell>
                {tableType === "payrollHistory"
                  ? data.recipientAccountNumber
                  : data.accountNumber}
              </TableCell>
              <TableCell>
                {tableType === "payrollHistory"
                  ? Number(data.amount).toLocaleString()
                  : Number(data.salary).toLocaleString()}
              </TableCell>
              <TableCell>
                <span className="flex gap-8 justify-between items-center">
                  {tableType === "payrollHistory" ? (
                    <ICONS.CheckMarkIcon />
                  ) : tableType === "payrollPayment" ? (
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        removeBeneficiary && removeBeneficiary(data._id)
                      }>
                      <ICONS.CloseIcon />
                    </div>
                  ) : (
                    <>
                      <Link to={`/dashboard/edit-payroll/${data._id}`}>
                        <ICONS.EditIcon />
                      </Link>
                      <div
                        className="cursor-pointer"
                        onClick={() =>
                          deleteBeneficiary && deleteBeneficiary(data._id)
                        }>
                        <ICONS.DeleteIcon />
                      </div>
                      {/* <div className="cursor-pointer">
                        <ICONS.CaretRightIcon />
                      </div> */}
                    </>
                  )}
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PayrollTable;
