import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ICONS } from "../../assets/svgs/ICONS";
import BusinessType from "./BusinessType";
import Checkbox from "../../components/inputs/checkbox/Checkbox";
import { useNavigate } from "react-router-dom";

const BusinessVerification = () => {
  const navigate = useNavigate();

  const verificationStep = [
    {
      id: 1,
      step: "Step 1",
      title: "Business Details",
    },
    {
      id: 2,
      step: "Step 2",
      title: "Business Owner Details",
    },
    {
      id: 3,
      step: "Step 3",
      title: "BVN Verification",
    },
    {
      id: 4,
      step: "Step 4",
      title: "Email Verification",
    },
  ];

  const [open, setOpen] = useState(true);
  // eslint-disable-next-line
  const [verificationType, setVerificationType] = useState("");
  const [selectStep] = useState(verificationStep);
  const [checked, setChecked] = useState<any>({});

  const handleVerificationType = (index: number) => {
    if (index === 0) {
      setVerificationType("business_name_registration");
      window.localStorage.setItem("verificationType", "BN");
    } else {
      setVerificationType("company_registration");
      window.localStorage.setItem("verificationType", "RN");
    }
    setOpen(false);
  };

  const handleSelectStep = (index: number) => {
    setChecked((prevState: any) => ({
      ...prevState,
      [index]: !prevState[index],
    }));

    switch (index) {
      case 1:
        navigate("/business/add-biz-verification");
        break;
      case 2:
        navigate("/business/add-biz-verification-details");
        break;
      case 3:
        navigate("/business/bvn-verification");
        break;
      case 4:
        navigate("/business/email-verification");
        break;
      default:
        navigate("/business/business-verification");
        break;
    }
  };

  return (
    <>
      <BusinessType
        open={open}
        handleVerificationType={handleVerificationType}
      />
      <div className="w-[703px] px-20 py-12 _h-[812px] login-box-radius bg-white rounded-2.5xl rounded-tl-none flex-center flex-col">
        <Link to="/" className="top-section flex-center">
          <ICONS.BrandLogo />
        </Link>

        <div className="title mt-6 flex-center flex-col">
          <h3 className="text-4xl text-secondary font-bold">
            Account Verification
          </h3>
          <p className="mt-1 text-md text-[#8C8C8C] font-medium">
            Each verification stage is required for different tiers and
            different verification
          </p>
        </div>

        {selectStep.map((verify, index) => (
          <div
            key={index}
            onClick={() => handleSelectStep(verify.id)}
            className="flex items-center justify-between gap-5 w-[100%] max-w-[300px] border border-gray-50 input-radius p-4 mt-4 drop-shadow-sm cursor-pointer">
            <Checkbox name={verify.step} checked={checked[verify.id]} />

            <div className="w-[100%]">
              <p className="text-sm text-secondary2">{verify.step}</p>
              <p className="text-sm font-semibold text-secondary">
                {verify.title}
              </p>
            </div>
            <ICONS.CaretRightIcon />
          </div>
        ))}
      </div>
    </>
  );
};

export default BusinessVerification;
