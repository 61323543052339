import React, { useEffect, useState } from "react";
import PhysicalCard, { IPhysical } from "../cards/PhysicalCard";
import VirtualCard, { IVirtualCard } from "../cards/VirtualCard";
import {
  assignCardToBudget,
  getCards,
} from "../../services/requests/card/CardRequest";
import Spinner from "../../components/spinner/Spinner";
import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { fetchSingleBudget } from "../../services/requests/budget/BudgetRequest";

const AddCard = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cards, setCards] = useState<IVirtualCard[]>([]);
  const [physicalCards, setPhysicalCards] = useState<IPhysical[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const budgetId = queryParams.get("id");

  const handleAssign = (card_id: string) => {
    try {
      if (budgetId) {
        setLoading(true);
        const response = assignCardToBudget(card_id, budgetId);
        response.then((res) => {
          if (res.status === 200) {
            fetchSingleBudget(budgetId);
            toast.success("Card assigned successfully");
            setLoading(false);
          } else if (
            res?.response?.data?.status === "fail" ||
            res?.response?.data?.status === "error" ||
            res?.status === "error"
          ) {
            toast.error(res.response.data.message);
            setLoading(false);
          }
          console.log(res);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchAllCards = async () => {
      setIsLoading(true);
      const response = await getCards();
      const responseVirutal: IVirtualCard[] = response.cards.filter(
        ({ card_type }: { card_type: string }) => card_type === "virtual"
      );
      const responsePhysical: IPhysical[] = response.cards.filter(
        ({ card_type }: { card_type: string }) => card_type !== "virtual"
      );
      setCards(responseVirutal);
      setPhysicalCards(responsePhysical);
      setIsLoading(false);
    };
    fetchAllCards();
  }, []);
  return (
    <div className="h-[80vh] overflow-scroll .hide-scrollbar">
      <h2 className="text-secondary text-center font-bold text-xl mb-7">
        Select To add Card
      </h2>

      <div>
        {isLoading || loading ? (
          loading ? (
            <div className="flex items-center justify-center gap-3">
              <Spinner color="#D6AA1B" />
              <p>Assigning card</p>
            </div>
          ) : isLoading ? (
            <div className="flex items-center justify-center gap-3">
              <Spinner color="#D6AA1B" />
              <p>Fetching cards</p>
            </div>
          ) : (
            <Spinner color="#D6AA1B" />
          )
        ) : (
          <>
            <div className="flex flex-wrap gap-8 items-center justify-center">
              <h5 className="text-md font-semibold">Virtual Cards</h5>
              {cards.map((card: any, id) => (
                <VirtualCard
                  key={id}
                  type={id}
                  {...card}
                  hasLink={false}
                  onClick={() => handleAssign(card.card_id)}
                />
              ))}
            </div>
            <div className="mt-[3rem]">
              <h5 className="text-md font-semibold mb-5">Physical Card</h5>
              {physicalCards.map((card, id) => (
                <PhysicalCard
                  key={id}
                  {...card}
                  paymentBalance="10230"
                  savingBalance="267923"
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AddCard;
