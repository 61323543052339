function Spinner({ color }: { color: string }) {
  return (
    <span
      style={{
        borderColor: color,
        borderTopColor: "transparent",
      }}
      className="block w-6 h-6 rounded-full border-2 border-t-transparent border-white animate-spin duration-1000"
    ></span>
  );
}

export default Spinner;
