import React, { useState } from "react";
import ActionButton from "../../components/buttons/ActionButton";
import AddMoneyModal from "../../components/modal/AddMoneyModal";
import { ICONS } from "../../assets/svgs/ICONS";
import FormButton from "../../components/buttons/FormButton";
import SelectInput from "../../components/inputs/SelectInput";
import { useOutletContext } from "react-router-dom";
import formatCurrency from "../../utils/formatCurrency";
import TextInput from "../../components/inputs/TextInput";
import {
  fetchAccountNameRequest,
  fetchAllSupportedBanks,
  fetchSubAccount,
  transferRequest,
} from "../../services/requests/account/AccountRequest";
import TextareaInput from "../../components/inputs/TextareaInput";
import SwitchButton from "../../components/buttons/SwitchButton";
import toast from "react-hot-toast";
import SubAccountSwiperContainer from "../../components/swiper/SubAccountSwiperContainer";
import { getBudget } from "../../services/requests/budget/BudgetRequest";

interface IBank {
  id?: string;
  label: string;
  value: string;
}
export interface ISubAccount {
  accountName: string;
  accountNumber: string;
  accountType: string;
  bankNipCode: string;
  bankName?: string;
  balance: number;
}
function AccountPage() {
  const [loading, setLoading] = useState({
    accountNameLoader: false,
    subAccountLoader: false,
    continueBtnLoading: false,
  });
  const [viewBalance, setViewBalance] = useState(true);
  const [counterParty, setCounterParty] = useState<any>(null);
  const [accountPayload, setAccountPayload] = useState({
    accountNumber: "",
    bankName: "",
    bankNipCode: "",
    bankId: "",
    accountName: "",
    amount: "",
    description: "",
    addToBeneficiary: false,
    pin: "",
    subAccountId: "",
    budgetId: "",
  });
  const [accountNameFetchCount, setAccountNameFetchCount] = useState<number>(0);
  const [subAccounts, setSubAccounts] = useState<ISubAccount[]>([]);
  const [totalBalance, setTotalBalance] = useState<number>(0);
  const [subAccountOptions, setSubAccountOptions] = useState<any>(null);
  const [budgetOptions, setBudgetOptions] = useState<any>(null);
  const [bankList, setBankList] = useState<IBank[]>([]);
  const [setPageName] = useOutletContext<any>();
  const [balanceToShow, setBalanceToShow] = useState<"total" | "terminal">(
    "total"
  );
  const [modal, setModal] = useState<{
    show: boolean;
    type?: "transfer" | "send1" | "send2" | "confirm" | "password" | "success";
  }>({
    show: false,
  });

  const fetchAccountNameHandler = React.useCallback(
    async ({
      accountNumber,
      bankNipCode,
      bankId,
    }: {
      accountNumber: string;
      bankNipCode: string;
      bankId: string;
    }) => {
      console.log(accountNumber, bankNipCode, bankId);

      if (!accountNumber) {
        toast.error("Account Number is required");
      } else if (!bankNipCode) {
        toast.error("Bank Name is required");
      } else if (accountNumber.length !== 10) {
        toast.error("Account Number must be 10 digits");
      } else if (!bankId) {
        toast.error("Bank ID is required");
      } else {
        const response = await fetchAccountNameRequest({
          accountNumber: accountNumber,
          bankNipCode: bankNipCode,
          bankId: bankId,
        });
        if (response) {
          const _counterParty = response?.counterparty;
          if (_counterParty) {
            setCounterParty(_counterParty);
            setAccountPayload({
              ...accountPayload,
              accountName: _counterParty.attributes.accountName,
            });
          } else {
            setCounterParty(null);
            setAccountPayload({
              ...accountPayload,
              accountName: "",
            });
          }
        } else {
          let toastInstance;
          if (accountNameFetchCount === 0) {
            toastInstance = toast.loading("Error occured, Retrying first time");
          } else if (accountNameFetchCount === 1) {
            toast.dismiss(toastInstance);
            toastInstance = toast.loading(
              "Error occured, Retrying second time"
            );
          } else if (accountNameFetchCount === 2) {
            toast.dismiss(toastInstance);
            toastInstance = toast.loading("Error occured, Retrying last time");
          } else {
            toast.dismiss(toastInstance);
          }
        }
      }
      setAccountNameFetchCount((previousState) => previousState + 1);
    },
    [accountPayload, accountNameFetchCount]
  );

  React.useEffect(() => {
    const getAccountName = async () => {
      if (
        accountPayload.accountNumber.length === 10 &&
        accountPayload.bankNipCode &&
        accountPayload.accountName === "" &&
        accountNameFetchCount <= 3
      ) {
        setLoading((prevLoading) => ({
          ...prevLoading,
          accountNameLoader: true,
        }));
        await fetchAccountNameHandler({
          accountNumber: accountPayload.accountNumber,
          bankNipCode: accountPayload.bankNipCode,
          bankId: accountPayload.bankId,
        });
      }
      setLoading((prevLoading) => ({
        ...prevLoading,
        accountNameLoader: false,
      }));
    };
    getAccountName();
  }, [
    accountNameFetchCount,
    accountPayload.accountName,
    fetchAccountNameHandler,
    accountPayload.accountNumber,
    accountPayload.bankNipCode,
    accountPayload.bankId,
  ]);

  React.useEffect(() => {
    const fetchSubAccountList = async () => {
      setLoading((prevLoading) => ({
        ...prevLoading,
        subAccountLoader: true,
      }));
      let TOTAL_BALANCE = 0;
      const response = await fetchSubAccount();
      console.log(response);

      let formatedResponse: ISubAccount[] = [];
      let _subAccountOptions: { value: string; label: string }[] = [];
      if (response)
        response &&
          response?.accounts?.forEach(
            ({
              accountName,
              bankAccountNumber,
              bankCode,
              bankName,
              accountType,
              anchor_Balance,
            }: any) => {
              formatedResponse.push({
                accountName,
                bankName,
                accountNumber: bankAccountNumber,
                accountType,
                bankNipCode: bankCode,
                balance: anchor_Balance,
              });
            }
          );
      response &&
        response?.accounts?.forEach((account: any) => {
          _subAccountOptions.push({
            label: `${account.bankAccountNumber} (${formatCurrency(
              account.anchor_Balance
            )})`,
            value: account.virtualAccountId,
          });
        });
      setSubAccountOptions(_subAccountOptions);
      setSubAccounts(formatedResponse);
      response?.accounts?.forEach(
        ({ anchor_Balance }: { anchor_Balance: number }) => {
          TOTAL_BALANCE = TOTAL_BALANCE + anchor_Balance;
        }
      );

      setTotalBalance(TOTAL_BALANCE);

      setLoading((prevLoading) => ({
        ...prevLoading,
        subAccountLoader: false,
      }));
    };
    const fetchBudgetList = async () => {
      let _budgetOptions: { value: string; label: string }[] = [];
      const response = await getBudget();
      response?.budgets?.forEach(
        ({ _id, title }: { _id: string; title: string }) => {
          _budgetOptions.push({
            value: _id,
            label: title,
          });
        }
      );
      setBudgetOptions(_budgetOptions);
    };
    fetchBudgetList();
    fetchSubAccountList();
  }, []);

  React.useEffect(() => {
    setPageName("Account");
  }, [setPageName]);

  React.useEffect(() => {
    const fetchBanksHandler = async () => {
      const response = await fetchAllSupportedBanks();
      let banklist: IBank[] = [];
      console.log(response);
      if (response)
        response?.banks?.data &&
          response?.banks?.data.forEach(
            ({
              id,
              attributes,
            }: {
              id: string;
              attributes: { nipCode: string; name: string };
            }) => {
              banklist.push({
                id: id,
                label: attributes.name,
                value: attributes.nipCode,
              });
            }
          );
      setBankList(banklist);
    };
    fetchBanksHandler();
  }, []);

  const handleInputChange = async (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { value, name } = e.target;
    if (name === "accountNumber" || name === "bankNipCode") {
      if (name === "bankNipCode") {
        const bank = bankList.find((bank: IBank) => bank.value === value);
        if (bank?.id) {
          setAccountPayload({
            ...accountPayload,
            [name]: value,
            bankId: bank.id,
            bankName: bank.label,
            accountName: "",
          });
        }
      } else {
        setAccountPayload((prevState) => ({
          ...prevState,
          [name]: value,
          accountName: "",
        }));
      }
      // This reset the count to 0 so that whenever the input values changes new 3 trials will be made to get account name. If it's not included the user will need to manually reload the page to be able to make getaccount name request when the 3 trial ellapses
      setAccountNameFetchCount(0);
    } else {
      setAccountPayload({
        ...accountPayload,
        [name]: value,
      });
    }
  };

  const handleSwitchToggle = () =>
    setAccountPayload({
      ...accountPayload,
      addToBeneficiary: !accountPayload.addToBeneficiary,
    });

  // const gotoModal1 = () => setModal({ show: true, type: "send1" });
  const gotoModal2 = () => {
    if (!accountPayload.accountName) {
      toast.error("Account name is required");
    } else if (!accountPayload.accountNumber) {
      toast.error("Account number is required");
    } else if (!accountPayload.bankNipCode) {
      toast.error("Bank is required");
    } else {
      setModal({ show: true, type: "send2" });
    }
  };

  const gotoReviewModal = () => {
    // else if (!accountPayload.budgetId) {
    //   toast.error("Budget is required");
    // }
    if (!accountPayload.amount) {
      toast.error("Amount is required");
    } else if (!accountPayload.subAccountId) {
      toast.error("Sub account is required");
    } else if (!accountPayload.description) {
      toast.error("Description is required");
    } else if (parseInt(accountPayload.amount) < 100) {
      toast.error("₦100 is the minimum you can transfer");
    } else {
      const selectedSubAccount = subAccounts.find(
        ({ accountNumber }: any) =>
          accountNumber === accountPayload.subAccountId
      );
      if (
        selectedSubAccount &&
        selectedSubAccount.balance < parseFloat(accountPayload.amount)
      ) {
        toast.error("Your amount has exceeded the sub account limit");
      } else {
        setModal({ show: true, type: "confirm" });
      }
    }
  };

  // const gotoPinModal = () => {
  //   setModal({ show: true, type: "password" });
  // };

  const makeTransfer = async () => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      continueBtnLoading: true,
    }));
    const response = await transferRequest({
      counterPartyId: counterParty.id,
      virtualAccountId: accountPayload.subAccountId,
      payload: {
        amount: accountPayload.amount,
        reason: accountPayload.description,
        toSave: accountPayload.addToBeneficiary,
        budgetId: accountPayload.budgetId,
      },
    });
    setLoading((prevLoading) => ({
      ...prevLoading,
      continueBtnLoading: false,
    }));
    if (response.status === "fail") {
      toast.error(response.message);
    } else {
      toast.success("Transfer made successfully");
      setModal({ show: true, type: "success" });
    }
  };

  const doNothing = () => {};

  const handleBalanceSwitchToggle = () =>
    setViewBalance((prevState) => !prevState);

  return (
    <div className="grid grid-cols-1">
      <div className="w-full pb-4">
        <div className="flex gap-2 ">
          <ActionButton
            text="Total Balance"
            onClick={() => setBalanceToShow("total")}
            className={`font-semibold px-5 py-2 ${
              balanceToShow === "total" &&
              "bg-primary text-white card-radius text-md"
            }`}
          />
          {/* <ActionButton
            text="Terminal Balance"
            onClick={() => setBalanceToShow("terminal")}
            className={`font-semibold px-5 py-2 ${
              balanceToShow === "terminal" &&
              "bg-primary text-white card-radius text-md"
            }`}
          /> */}
        </div>
        <div className="flex items-center justify-between">
          <p className="font-bold text-secondary text-5xl font-secondary my-8 flex items-center">
            <span className="text-4xl font-medium text-gray-500">₦</span>
            <React.Fragment>
              {loading.subAccountLoader ? (
                <span className="block rounded-full w-6 h-6 border-2 border-primary border-t-transparent animate-spin duration-1000"></span>
              ) : viewBalance ? (
                formatCurrency(totalBalance)
              ) : (
                "*****"
              )}
            </React.Fragment>
          </p>

          {subAccounts.length > 0 && (
            <button
              onClick={() => setModal({ show: true, type: "send1" })}
              className="px-4 flex gap-4 py-4 border-gray-500 border-opacity-40 border w-80 card-radius mt-4 cursor-pointer">
              <ICONS.SendMoneyIcon />

              <div>
                <p className="font-semibold text-secondary">Send Money</p>
                <p className="text-sm pb-1 opacity-70">Transfer to any bank</p>
              </div>
            </button>
          )}
        </div>

        <p className="text-md text-primary font-medium flex items-center gap-x-2">
          Toggle the view{" "}
          <SwitchButton
            status={viewBalance}
            handleSwitchToggle={handleBalanceSwitchToggle}
          />
        </p>

        <div className="header my-5">
          <h5 className="text-xl font-bold">All Accounts</h5>
          <p className="text-gray-500 text-sm">
            Here are the list of your sub accounts details.
          </p>
        </div>
        {loading.subAccountLoader ? (
          <span className="block rounded-full w-6 h-6 border-2 border-primary border-t-transparent animate-spin duration-1000"></span>
        ) : (
          <SubAccountSwiperContainer
            viewBalance={viewBalance}
            subAccounts={subAccounts}
          />
        )}
      </div>

      <AddMoneyModal
        title="Send Money"
        open={modal.show && modal.type === "send1"}
        onDismiss={() => setModal({ show: false })}
        className="flex items-center justify-center border-none outline-none">
        <div>
          <TextInput
            label="Account Number"
            name="accountNumber"
            onChange={handleInputChange}
            placeHolder="0123456789"
            value={accountPayload.accountNumber}
            type="number"
          />

          <SelectInput
            options={bankList}
            name="bankNipCode"
            onChange={handleInputChange}
            value={accountPayload.bankNipCode}
            label="Select Bankname"
          />
          <div className="relative">
            {loading.accountNameLoader && (
              <span className="absolute top-11 right-4 z-10 rounded-full flex w-6 h-6 border-2 border-primary border-t-transparent animate-spin duration-1000"></span>
            )}
            <TextInput
              label="Account Name"
              name="accountName"
              onChange={doNothing}
              placeHolder=""
              value={accountPayload.accountName}
              type="text"
              disabled={true}
            />
          </div>
          {/* <SelectInput
            options={[]}
            name="beneficiary"
            onChange={handleInputChange}
            value={accountPayload.accountName}
            label="Select Beneficiary"
          /> */}

          <div className="mt-16">
            <FormButton
              isLoading={loading.continueBtnLoading}
              onClick={gotoModal2}
              text="Continue"
            />
          </div>
        </div>
      </AddMoneyModal>

      <AddMoneyModal
        title="Send Money"
        open={modal.show && modal.type === "send2"}
        onDismiss={() => setModal({ show: false })}
        className="flex items-center justify-center border-none outline-none">
        <div className="mt-8">
          <TextInput
            label="Amount"
            name="amount"
            onChange={handleInputChange}
            placeHolder="₦0.00"
            value={accountPayload.amount}
            type="number"
          />
          <SelectInput
            options={subAccountOptions}
            name="subAccountId"
            onChange={handleInputChange}
            value={accountPayload.subAccountId}
            label="Sub Account"
          />
          <SelectInput
            options={budgetOptions}
            name="budgetId"
            onChange={handleInputChange}
            value={accountPayload.budgetId}
            label="Select Budget"
          />
          <TextareaInput
            label="Description"
            name="description"
            onChange={handleInputChange}
            value={accountPayload.description}
          />
          <p className="font-semibold text-sm mb-8">+ ₦25 transaction charge</p>

          <FormButton
            isLoading={loading.continueBtnLoading}
            onClick={gotoReviewModal}
            text="Continue"
          />
        </div>
      </AddMoneyModal>

      <AddMoneyModal
        title="Confirm"
        open={modal.show && modal.type === "confirm"}
        onDismiss={() => setModal({ show: false })}
        className="flex items-center justify-center border-none outline-none">
        <div className="mt-8">
          <div className="h-auto card-radius my-5 py-8 w-full  border border-[#D9D9D9] bg-gray-50 flex-center flex-col px-8 text-sm">
            <p className="text-[13px] font-semibold text-gray-500">Amount</p>
            <h4 className="text-xl font-semibold">
              ₦{formatCurrency(accountPayload.amount)}
            </h4>

            <p className="mt-5 text-[13px] font-semibold text-gray-500">
              Beneficiary
            </p>
            <h4 className="text-xl font-semibold">
              {accountPayload.accountName}
            </h4>

            <p className="mt-5 text-[13px] font-semibold text-gray-500">
              Beneficiary bank
            </p>
            <h4 className="text-xl font-semibold">{accountPayload.bankName}</h4>
          </div>

          <div className="w-full flex items-center justify-between mb-5">
            <p className="font-normal">Save as a beneficiary</p>
            <SwitchButton
              status={accountPayload.addToBeneficiary}
              handleSwitchToggle={handleSwitchToggle}
            />
          </div>

          <FormButton
            isLoading={loading.continueBtnLoading}
            onClick={makeTransfer}
            text="send"
          />
        </div>
      </AddMoneyModal>

      {/* <AddMoneyModal
        title="Enter Pin"
        open={modal.show && modal.type === "password"}
        onDismiss={() => setModal({ show: false })}
        className="flex items-center justify-center border-none outline-none"
      >
        <div>
          <TextInput
            label="Pin"
            name="pin"
            onChange={handleInputChange}
            placeHolder="Enter your pin"
            value={accountPayload.pin}
            type="password"
          />

          <FormButton
            isLoading={loading.continueBtnLoading}
            onClick={makeTransfer}
            text="send"
          />
        </div>
      </AddMoneyModal> */}

      <AddMoneyModal
        title=""
        bg="#273B4A"
        open={modal.show && modal.type === "success"}
        onDismiss={() => setModal({ show: false })}
        className="flex items-center justify-center border-none outline-none">
        <div className="w-full h-[54vh] flex-center flex-col">
          <svg
            width="112"
            height="147"
            viewBox="0 0 147 147"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 0H73.5C114.093 0 147 32.9071 147 73.5C147 114.093 114.093 147 73.5 147C32.9071 147 0 114.093 0 73.5V0Z"
              fill="#D6AA1B"
            />
            <path
              d="M47 70.4694L63.8893 87.2091C65.4485 88.7545 67.9618 88.7545 69.5209 87.2091L100 57"
              stroke="white"
              stroke-width="5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <h4 className="title text-3xl font-semibold text-white">Completed</h4>
          <p className="text-lg text-white">Transfer successful</p>
        </div>
      </AddMoneyModal>
    </div>
  );
}

export default AccountPage;
