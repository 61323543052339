import toast from "react-hot-toast";
import { GetHttp } from "../../https/GetHttp";
import { PostHttp } from "../../https/PostHttp";
// import axios from "axios";
// import { PostHttp } from "../../https/PostHttp";
// import { PatchHttp } from "../../https/PatchHttp";

export const transferRequest = async ({
  counterPartyId,
  virtualAccountId,
  payload,
}: {
  counterPartyId: string;
  virtualAccountId: string;
  payload: any;
}) => {
  const response: any = await PostHttp({
    pathname: `/anchor/transfer?counterPartyId=${counterPartyId}&virtualAccountId=${virtualAccountId}`,
    payload: payload,
    formType: "json",
  });
  if (response) {
    if (response?.status < 200 || response?.status > 204) {
      toast.error(response?.response?.data?.message);
    }

    if (response?.response?.data) {
      // Error ocrrued
      return response.response.data; //response.data;
    } else {
      return response.data;
    }
  }
};

export const fetchAccountNameRequest = async ({
  accountNumber,
  bankNipCode,
  bankId,
}: {
  accountNumber: string;
  bankNipCode: string;
  bankId: string;
}) => {
  // anchor/create-counterparty?bankNipCode=000014&accountNumber=2082268606&bankId=1656585494809267-anc_bk
  const response: any = await PostHttp({
    pathname: `/anchor/create-counterparty?bankNipCode=${bankNipCode}&accountNumber=${accountNumber}&bankId=${bankId}`,
    payload: {
      data: {
        type: "CounterParty",
      },
    },
    formType: "json",
  });
  if (response) {
    if (response?.status < 200 || response?.status > 204) {
      toast.error(response?.response?.data?.message);
    }
    return response.data;
  }
};

export const fetchSubAccount = async () => {
  const response: any = await GetHttp({
    pathname: `/fincra/subaccount`,
  });
  if (response) {
    if (response?.status < 200 || response?.status > 204) {
      toast.error(response?.response?.data?.message);
    }
    return response.data;
  }
};

export const fetchAllSupportedBanks = async () => {
  const response: any = await GetHttp({
    pathname: `/anchor/bank-list`, // `/fincra/listbankspaystack`,
  });
  // let response: any = await axios.get(
  //   "https://finosell.link/api/v2/anchor/bank-list"
  // );
  // response = response.data;
  if (response) {
    if (response?.status < 200 || response?.status > 204) {
      toast.error(response?.response?.data?.message);
    }
    return response.data;
  }
};

export const getVirtualAccId = async () => {
  const res = await GetHttp({
    pathname: `/anchor/get-anchor-account`,
  });

  return res.data;
};

// export const createBudget = async (payload: any) => {
//   const response = await PostHttp({
//     pathname: "/budget/create",
//     payload: payload,
//   });

//   if (response?.response?.status < 200 || response?.response?.status > 204) {
//     toast.error(response?.response?.data?.message);
//   }
//   return response;
// };

// export const editBudget = async (payload: any) => {
//   const response = await PatchHttp({
//     pathname: `/budget/${payload.id}`,
//     payload: payload,
//   });

//   if (response?.response?.status < 200 || response?.response?.status > 204) {
//     toast.error(response?.response?.data?.message);
//   }
//   return response;
// };
