import React from "react";
import ActionButton from "../../../components/buttons/ActionButton";
import { ICONS } from "../../../assets/svgs/ICONS";
import toast from "react-hot-toast";
import formatCurrency from "../../../utils/formatCurrency";
type Info = {
  accountType: string;
  accountNumber: string;
  accountName: string;
  bankName?: string;
  balance: string | number;
  viewBalance: any;
};

function AccountInfo({
  accountName,
  accountNumber,
  bankName,
  accountType,
  balance,
  viewBalance
}: Info) {
  const copyToClipboard = (accountNumber: string) => {
    window.navigator.clipboard.writeText(accountNumber);
    toast.success("Account number copied");
  };
  return (
    <div className="w-[100%]">
      <p className="mb-1 text-sm opacity-70 capitalize">{accountType}</p>

      <div className="border border-b-0 border-opacity-50 border-primary rounded-tr-lg">
        <div className="grid grid-cols-2 px-3 gap-y-4  py-3 text-sm">
          <p className="opacity-70 text-sm">Account Number</p>
          <p className="font-semibold text-secondary text-right">
            {accountNumber}
          </p>
        </div>
        <div className="grid grid-cols-1 px-3 gap-y-1  py-3 text-sm">
          <p className="opacity-70 text-sm">Account Balance</p>
          <p className="font-semibold text-secondary">
            ₦{viewBalance ? formatCurrency(balance) : "*****"}
          </p>
        </div>
        <div className="grid grid-cols-1 px-3 gap-y-1  py-3 text-sm">
          <p className="opacity-70 text-sm">Account Name</p>
          <p className="font-semibold text-secondary">{accountName}</p>
        </div>
        <div className="grid grid-cols-2 px-3 gap-y-4  py-3 text-sm">
          <p className="opacity-70 text-sm">Bank</p>
          <p className="font-semibold text-secondary text-right">{bankName}</p>
        </div>
        {/* <div className="bg-black py-2 px-3">
          <ICONS.CopyIcon />
        </div> */}
      </div>
      <ActionButton
        onClick={() => copyToClipboard(accountNumber)}
        className="bg-secondary w-full text-sm py-4 text-white flex items-center justify-center gap-3  rounded-br-lg rounded-bl-lg"
        text="Copy Account Number"
        Icon={<ICONS.CopyIcon />}
      />
    </div>
  );
}

export default AccountInfo;
