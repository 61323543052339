import React from "react";
import { useLocation } from "react-router-dom";
import { ISubBudget, IcreateData } from "../../components/table/BudgetTable";
import AddBudgetPage, { BUDGET_TYPES } from "./AddBudgetPage";
import ViewBudgetPage from "./ViewBudgetPage";
import { fetchBudget } from "../../services/requests/budget/BudgetRequest";
import { formatDate } from "../../utils/formatDate";
import EditBudgetPage from "./EditBudgetPage";
import sortByDate from "../../utils/sortByDate";

function BudgetPage() {
  const fetchBudgetResponse = fetchBudget();
  const [budgetData, setBudgetData] = React.useState<IcreateData[]>([]);
  const [budgetDataBackup, setBudgetDataBackup] = React.useState<IcreateData[]>(
    []
  );
  const [searchInput, setSearchInput] = React.useState<string>("");
  const location = useLocation();

  React.useEffect(() => {
    const fetchBudgetHandler = async () => {
      let budgetCategories: any = {};
      // extracting types of budgets into budgetCategories
      BUDGET_TYPES.forEach(({ name }) => {
        budgetCategories[name] = [];
      });

      fetchBudgetResponse?.data?.budgets?.forEach((budget: any) => {
        budgetCategories[budget?.type]?.push({
          id: budget._id,
          budgetName: budget.title,
          dateCreated: formatDate(budget.createdAt),
          dateExpired: formatDate(budget.expiryDate),
          spent: budget.totalSpent,
          status: budget.expired,
          limit: budget.limit,
        });
      });

      const data: {
        budgetName: string;
        dateCreated: string;
        spent: string;
        status: string;
        limit: string;
        subBudget: any;
      }[] = [];

      BUDGET_TYPES.forEach(({ name, title }) => {
        data.push({
          budgetName: title,
          dateCreated: "",
          spent: "",
          status: "",
          limit: "",
          subBudget: sortByDate(budgetCategories[name]),
        });
      });

      setBudgetData(data);
      setBudgetDataBackup([...data]);
    };
    fetchBudgetHandler();
  }, [fetchBudgetResponse]);

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    console.log(budgetDataBackup);
    setSearchInput(value);
    if (value.length >= 3) {
      let matchedResult: IcreateData[] = [];
      [...budgetDataBackup].forEach((eachBudgetGroup) => {
        let matchedItemInEachGroup: ISubBudget[] = [];
        eachBudgetGroup.subBudget.forEach((eachBudget) => {
          if (
            eachBudget.budgetName.toLowerCase().includes(value.toLowerCase()) ||
            eachBudget.dateCreated.toLowerCase().includes(value.toLowerCase())
          ) {
            matchedItemInEachGroup.push(eachBudget);
          }
        });
        matchedResult.push({
          ...eachBudgetGroup,
          subBudget: matchedItemInEachGroup,
        });
      });
      setBudgetData(matchedResult);
    } else {
      setBudgetData(budgetDataBackup);
    }
  };
  return (
    <React.Fragment>
      {location.hash.includes("#edit-budget") && <EditBudgetPage />}
      {location.hash === "#add-budget" && <AddBudgetPage />}
      {location.hash === "" && (
        <ViewBudgetPage
          budgetData={budgetData}
          handleSearchInput={handleSearchInput}
          searchInput={searchInput}
        />
      )}
    </React.Fragment>
  );
}

export default BudgetPage;
