import React from "react";

function BudgetTypeCard({
  value,
  title,
  name,
  description,
  handleInputChange,
}: {
  value: string;
  title: string;
  name: string;
  description: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <label className="flex items-center gap-x-5 px-5 w-[501px] h-[92px] border border-gray-200 card-radius cursor-pointer">
      <input
        onChange={handleInputChange}
        type="radio"
        name="type"
        value={name}
        className="hidden"
      />
      <span
        className={`indicator w-10 h-10 input-radius bg-gray-300 duration-300 ${
          value === name && "bg-primary"
        }`}
      ></span>
      <div>
        <h5 className="text-sm text-gray-500 font-medium">{title}</h5>
        <p className="text-sm text-gray-500 font-medium">{description}</p>
      </div>
    </label>
  );
}

export default BudgetTypeCard;
