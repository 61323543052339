import React from "react";
import toast from "react-hot-toast";
import Spinner from "../../../components/spinner/Spinner";

interface CardDetailsProps {
  dataLoading: any;
  cardDetails_id: any;
}

const CardDetails: React.FC<CardDetailsProps> = ({
  cardDetails_id,
  dataLoading,
}) => {
  //write a function to copy the card number to clipboard
  const copyToClipboard = (value: string) => {
    window.navigator.clipboard.writeText(value);
    toast.success("Copied to clipboard");
  };

  return (
    <div>
      <h3
        className="text-secondary font-bold mb-3 text-center text-lg
      ">
        Virtal Card Details
      </h3>

      <div className="bg-gray-200 p-4 flex flex-col gap-8 card-radius">
        <div>
          <p className="text-[#8C8C8C] text-center font-medium">Card Name</p>
          <p
            className="text-secondary text-center font-medium text-2xl"
            onClick={() => copyToClipboard(cardDetails_id.card_name)}>
            {dataLoading.cardDetails ? (
              <div className="flex items-center justify-center">
                <Spinner color="#D6AA1B" />
              </div>
            ) : (
              cardDetails_id.card_name
            )}
          </p>
        </div>
        <div>
          <p className="text-[#8C8C8C] text-center font-medium">Card Number</p>
          <p
            className="text-secondary text-center font-medium text-2xl"
            onClick={() => copyToClipboard(cardDetails_id.card_number)}>
            {dataLoading.cardDetails ? (
              <div className="flex items-center justify-center">
                <Spinner color="#D6AA1B" />
              </div>
            ) : (
              cardDetails_id.card_number
            )}
          </p>
        </div>

        <div className="flex items-center gap-[3rem] justify-center">
          <div>
            <p className="text-[#8C8C8C] text-center font-medium">
              Expiry Date
            </p>
            <p
              className="text-secondary text-center font-medium text-2xl"
              onClick={() =>
                copyToClipboard(
                  `${cardDetails_id.expiry_month} / ${cardDetails_id.expiry_year}`
                )
              }>
              {dataLoading.cardDetails ? (
                <div className="flex items-center justify-center">
                  <Spinner color="#D6AA1B" />
                </div>
              ) : (
                `${cardDetails_id.expiry_month} / ${cardDetails_id.expiry_year}`
              )}
            </p>
          </div>
          <div>
            <p className="text-[#8C8C8C] text-center font-medium">CVV</p>
            <p
              className="text-secondary text-center font-medium text-2xl"
              onClick={() => copyToClipboard(cardDetails_id.cvv)}>
              {dataLoading.cardDetails ? (
                <div className="flex items-center justify-center">
                  <Spinner color="#D6AA1B" />
                </div>
              ) : (
                cardDetails_id.cvv
              )}
            </p>
          </div>
        </div>
      </div>
      <p className="text-primary mt-3 text-center text-sm">
        Click any of the information to copy to clipboard
      </p>
    </div>
  );
};

export default CardDetails;
