import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Link } from "react-router-dom";
import PayrollTable, { PayrollData } from "../../components/table/PayrollTable";
import {
  deletePayrollBeneficiary,
  getPayroll,
} from "../../services/requests/payroll/Payroll";
import Loader from "../../components/Loader/Loader";
import toast from "react-hot-toast";

const Payroll = () => {
  const [setPageName] = useOutletContext<any>();
  const [payrollData, setPayrollData] = useState<PayrollData[]>([]);
  const [loading, setLoading] = useState({
    payrollLoading: false,
    deletePayroll: false,
  });
  const [tableType] = useState("payrollList");

  React.useEffect(() => {
    setPageName("Flex");
  }, [setPageName]);

  const getPayrollData = async () => {
    await getPayroll().then((res) => {
      if (res && res.paymentDetails) {
        const sorted = res.paymentDetails.sort((a: any, b: any) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        });
        setPayrollData(sorted);
        setLoading((prevLoading) => ({
          ...prevLoading,
          payrollLoading: false,
        }));
      } else {
        setLoading((prevLoading) => ({
          ...prevLoading,
          payrollLoading: false,
        }));
        setPayrollData([]);
        return toast.error("No payroll");
      }
      return;
    });
  };

  useEffect(() => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      payrollLoading: true,
    }));

    getPayrollData();
  }, []);

  const deleteBeneficiary = (id: string) => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      deletePayroll: true,
    }));
    deletePayrollBeneficiary(id).then((res) => {
      console.log(res);
      if (res.status === "Successfully deleted") {
        toast.success("Successfully deleted");

        getPayrollData();
      } else {
        toast.error("Failed to delete" || res.response.data.message);
      }
      setLoading((prevLoading) => ({
        ...prevLoading,
        deletePayroll: false,
      }));
    });
  };

  return (
    <div>
      {loading.deletePayroll && <Loader />}
      <div className="w-full flex items-center justify-between mb-6">
        <p className="text-primary text-sm font-semibold">Beneficiary</p>
        <div className="flex items-center gap-5">
          <Link
            to="/dashboard/payroll-history"
            className="h-14 bg-secondary text-[#F5F7FA] text-md px-6 border-2 border-transparent flex items-center gap-x-2 font-semibold button-radius">
            Transaction History
          </Link>
          <Link
            to="/dashboard/payroll-payment"
            className="h-14 bg-secondary text-white text-md px-6 border-2 border-transparent flex items-center gap-x-2 font-semibold button-radius">
            Initiate Payment
          </Link>
          <Link
            to="/dashboard/create-payroll"
            className="h-14 bg-secondary text-white text-md px-6 border-2 border-transparent flex items-center gap-x-2 font-semibold button-radius">
            Add Beneficiary
          </Link>
        </div>
      </div>
      <hr />
      {loading.payrollLoading ? (
        <div className="w-full h-32 flex items-center justify-center">
          <div className="p-10 flex items-center gap-x-2 justify-center">
            <div className="w-6 h-6 rounded-full border-2 border-primary border-t-transparent animate-spin duration-1000"></div>
            <p className="text-sm animate-pulse">Fetching all payroll...</p>
          </div>
        </div>
      ) : !payrollData.length ? (
        <div className="w-full h-32 flex items-center justify-center">
          <p className="text-xl font-semibold text-center">No payroll found!</p>
        </div>
      ) : (
        <div className="w-full mt-5 border border-gray-300 card-radius overflow-hidden">
          <PayrollTable
            data={payrollData}
            tableType={tableType}
            deleteBeneficiary={deleteBeneficiary}
          />
        </div>
      )}
    </div>
  );
};

export default Payroll;
