import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ICONS } from "../../assets/svgs/ICONS";
import TextInput from "../../components/inputs/TextInput";
import Spinner from "../../components/spinner/Spinner";
import { uploadBusinessVerificationDetails } from "../../services/requests/createAccount/BusinessVerificationRequest";
import toast from "react-hot-toast";

const AddBizVerification = () => {
  const [value, setValue] = useState({
    registrationNumber: "",
    cac: null,
    form_cac: null,
  });
  const [loading, setLoading] = useState(false);
  const [verificationType, setVerificationType] = useState(
    window.localStorage.getItem("verificationType") || ""
  );

  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value, type } = e.target;
  //   if (name === "registrationNumber") {
  //     const regNumber = value.replace(/(RC-|BN-)/, "");
  //     setValue((prevState) => ({
  //       ...prevState,
  //       registrationNumber: regNumber,
  //     }));
  //     return;
  //   }
  //   const updatedProperties = {
  //     [name]:
  //       type === "file" && name === "cac"
  //         ? (e.target as HTMLInputElement).files?.[0] || null
  //         : type === "file" && name === "form_cac"
  //         ? (e.target as HTMLInputElement).files?.[0] || null
  //         : value.trim(),
  //   };

  //   setValue((prevState) => ({
  //     ...prevState,
  //     ...updatedProperties,
  //   }));
  // };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, files } = e.target;

    setValue((prevState) => ({
      ...prevState,
      [name]:
        (name === "registrationNumber" && value.replace(/(RC-|BN-)/, "")) ||
        (type === "file" && (name === "cac" || name === "form_cac")
          ? files?.[0] || null
          : value.trim()),
    }));
  };

  useEffect(() => {
    const verificationType =
      window.localStorage.getItem("verificationType") || "";

    if (verificationType) {
      setVerificationType(verificationType);
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (value.cac && value.form_cac && value.registrationNumber) {
      await uploadBusinessVerificationDetails(value, setLoading);
    } else {
      toast.error("Please fill all fields");
    }
    return;
  };

  return (
    <div className="w-[703px] px-20 py-12 _h-[812px] login-box-radius bg-white rounded-2.5xl rounded-tl-none flex-center flex-col">
      <Link to="/" className="top-section flex-center">
        <ICONS.BrandLogo />
      </Link>

      <div className="title mt-6 flex-center flex-col">
        <h3 className="text-4xl text-secondary font-bold">
          Add Business Details
        </h3>
        <p className="mt-1 text-md text-[#8C8C8C] font-medium">
          Use correct information about your business
        </p>
      </div>

      <form className="w-full" onSubmit={handleSubmit}>
        <div className="flex items-start justify-start w-full my-[15px] flex-col">
          <label htmlFor="cac" className="font-semibold text-sm py-2">
            Business Document
          </label>
          <label
            htmlFor="cac"
            className="flex flex-col items-center justify-center w-full p-5 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800  hover:bg-gray-100 ">
            <p className="text-sm text-gray-500 dark:text-gray-400 m-0">
              {value.cac
                ? "Uploaded"
                : "Click to Upload business name certificate"}
            </p>

            <input
              id="cac"
              type="file"
              className="hidden"
              name="cac"
              onChange={handleInputChange}
            />
          </label>
        </div>

        <div className="flex items-start justify-start w-full my-[15px] flex-col">
          <label
            htmlFor="form_cac"
            className="flex flex-col items-center justify-center w-full p-5 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800  hover:bg-gray-100 ">
            <p className=" text-sm text-gray-500 dark:text-gray-400 m-0">
              {value.form_cac
                ? "Uploaded"
                : "Click to Upload Business name application form"}
            </p>

            <input
              id="form_cac"
              type="file"
              className="hidden"
              onChange={handleInputChange}
              name="form_cac"
            />
          </label>
        </div>

        <TextInput
          name="registrationNumber"
          label={
            verificationType === "BN"
              ? "Business Registration Number"
              : "Company Registration Number"
          }
          type="text"
          placeHolder={
            verificationType === "BN"
              ? "Business Registration Number"
              : "Company Registration Number"
          }
          value={value.registrationNumber}
          onChange={handleInputChange}
        />

        <div className="button-wrapper mt-8">
          <button
            disabled={loading === true}
            className="button-radius w-full h-16 bg-primary text-white font-me dium text-md flex-center gap-x-2 disabled:opacity-50">
            {loading && <Spinner color="#fff" />}
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddBizVerification;
